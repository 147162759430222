import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { PaginatedTable } from "../../../../../components/common";
import {
  addBulkCoBuyers,
  fetchDuplicateRecords,
  mergedDupLeads,
} from "../../../../../utils/api/lead.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import Popup from "../../../../Charts/Popup";

type DupCheckProps = {
  clickedRow: any;
};

const DupCheck = ({ clickedRow }: DupCheckProps) => {
  const { id: contactId } = clickedRow || {};
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [isMerge, setIsMerge] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toast = useToast();

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  const tableHeader = [
    { id: 1, value: "Name", label: "name" },
    { id: 2, value: "Email", label: "email" },
    { id: 3, value: "Phone No", label: "phoneNo" },
    { id: 4, value: "Similarity (%)", label: "match_percentage" },
  ];

  const {
    isLoading,
    data: resData,
    refetch,
  } = useQuery({
    queryKey: ["fetchDuplicateCheck", contactId, page, perPage],
    queryFn: () =>
      fetchDuplicateRecords({
        contactId: contactId,
        dealershipId: dealershipId,
        page,
        perPage,
      }),
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (isMerge ? mergedDupLeads : addBulkCoBuyers)({
          payload: { contact_ids: selectedRecords },
          contactId,
          dealershipId,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: isMerge
          ? "Leads Merged Successfully"
          : "Contacts added as co-buyers successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setSelectedRecords([]);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error ${
          isMerge ? "Merging Leads" : "Adding contacts as co-buyers"
        }: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleContactsMerge = async () => {
    await mutation.mutateAsync({});
    onClosePopupOpen();
  };

  const { data } = resData || {};
  const { contacts, contacts_count } = data || {};

  const tableData = useMemo(() => {
    if (!contacts?.length) {
      return [];
    }

    return contacts.map((lead: any) => {
      return {
        id: lead.id,
        name: `${lead.first_name} ${lead.last_name}`,
        email: lead.email,
        phoneNo: lead.phone_number,
        match_percentage: `${lead.match_percentage}%`,
      };
    });
  }, [contacts]);

  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = (isMerge = false) => {
    setIsMerge(isMerge);
    setIsPopupOpen(true);
  };

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="1rem"
        h="calc(100vh - 140px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Duplicate Customer Check
          </Text>
          {/* <Button variant="outline" padding="0.75rem" minW="auto">
            <DeSelectIcon />
          </Button> */}
        </HStack>
        {/* <Input hasIcon={true} icon={<SearchIcon />} /> */}
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          isGreen={true}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          hasCheckbox={true}
          hasPadding={false}
          isPaginated={true}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={contacts_count}
          parentRecords={selectedRecords}
          setParentRecords={setSelectedRecords}
          isLoadingData={isLoading}
        />
        <Box marginTop="auto" gap={5} display="flex" width="100%">
          <Button
            w="100%"
            mt="auto"
            onClick={() => onOpenPopupOpen(true)}
            isDisabled={!selectedRecords.length}
          >
            Merge The Duplicated Contacts
          </Button>

          <Button
            w="100%"
            mt="auto"
            onClick={() => onOpenPopupOpen()}
            isDisabled={!selectedRecords.length}
            background="var(--secondary-600)"
          >
            Add as Co-Buyer
          </Button>
        </Box>
      </VStack>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={handleContactsMerge}
          title={isMerge ? "Merge Contacts" : "Add as Co-buyers"}
          message={
            isMerge
              ? "Are you sure you want to merge these contacts?"
              : "Are you sure you want to add these contacts as co-buyers?"
          }
          loading={mutation?.isLoading}
        />
      )}
    </VStack>
  );
};

export default DupCheck;
