import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import {
  filterLinks,
  getFileNameFromURL,
  titleMapping,
} from "../../../../../../utils";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";
import { InboundEmailIcon, OutboundEmailIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";

type SingleEmailCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleEmailCard({ item, setClickedRecord }: SingleEmailCardProps) {
  return (
    <Activity
      gap="0rem"
      key={item.id}
      id={item.id}
      handleClick={() => setClickedRecord(item)}
      title={
        item?.details?.internal_action?.includes("outbound")
          ? `Outbound`
          : "Inbound"
      }
      dateAndTime={moment(item?.created_at?.replace("Z", "")).format(
        "dddd MMM DD YY • hh:mm a"
      )}
      icon={
        item?.details?.internal_action?.includes("outbound") ? (
          <OutboundEmailIcon />
        ) : (
          <InboundEmailIcon />
        )
      }
      content={
        <>
          <VStack alignItems="flex-start" gap="1rem">
            {item?.details?.internal_action?.includes("outbound") && (
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="400">
                {(titleMapping as any)[item?.details?.internal_action]}
              </Text>
            )}
            <VStack alignItems="flex-start" width="100%" gap="0rem">
              <HStack gap="0.25rem" alignItems="flex-star">
                <Text textStyle="h6" fontWeight="600">
                  {item?.details?.internal_action?.includes("outbound")
                    ? "To:"
                    : "From:"}
                </Text>

                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.internal_action?.includes("outbound")
                    ? item?.details?.to_address
                    : item?.details?.from_address}
                </Text>
              </HStack>
              <HStack gap="0.25rem" alignItems="flex-star">
                <Text textStyle="h6" fontWeight="600">
                  Subject:
                </Text>

                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.subject}
                </Text>
              </HStack>
            </VStack>

            <Box mt="0.5rem" display="flex" flexWrap="wrap" width="100%">
              {filterLinks(item.details.attachment_urls || [])?.map(
                (media: any) => {
                  return (
                    <img src={media} alt={media} width={100} height={100} />
                  );
                }
              )}

              {filterLinks(item.details.attachment_urls || [], false)?.map(
                (media: any) => {
                  const filename = getFileNameFromURL(media);
                  return (
                    <a
                      href={media}
                      download
                      onClick={(event) => event.stopPropagation()}
                    >
                      <TooltipIfOverflow char={70}>
                        {filename}
                      </TooltipIfOverflow>
                    </a>
                  );
                }
              )}
            </Box>
          </VStack>
        </>
      }
      shouldShowIsOpen={item?.details?.internal_action?.includes("outbound")}
      isOpen={item?.details?.is_open}
    />
  );
}

export default SingleEmailCard;
