import * as yup from "yup";
import { EMAIL_REGEX } from "../../../config";

export type SendEmailForm = {
  from: string;
  to: string;
  subject: string;
  cc: string[];
  bcc: string[];
  messageBody: string;
};

export const defaultValues = {
  from: "",
  to: "",
  subject: "",
  cc: [],
  bcc: [],
  messageBody: "",
};

export const sendEmailFormValidation = yup.object().shape({
  to: yup
    .string()
    .nullable()
    .matches(EMAIL_REGEX, "Invalid Email Address")
    .required("Recipient is required"),
  messageBody: yup
    .string()
    .required("Email Content is required")
    .when('$showRawHtml', {
      is: true, 
      then: (schema) => schema, 
      otherwise: (schema) => schema.test(
        "max-text-length",
        "Email content cannot exceed 2499 characters in plain text",
        function (value) {
          if (!value) return true; // if no content, validation passes

          // Create a temporary element to convert HTML to plain text
          const tempElement = document.createElement("div");
          tempElement.innerHTML = value;
          const textContent =
            tempElement.innerText || tempElement.textContent || "";

          // Check if the plain text length exceeds 2499 characters
          return textContent.length <= 2499;
        }
      )
    }),
});
