import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { markAllReminderAsRead } from "../../../utils/api/header-notifications.api";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { ReminderCard } from "./ReminderCard";

interface RemindersProps {
  onOpen: (val: string, count: number) => void;
  count: number;
  unreadCount: number;
}

export const Reminders = ({ onOpen, count, unreadCount }: RemindersProps) => {
  const { refetchNotifications } = useNotificationContext();
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const mutation = useMutation(markAllReminderAsRead);
  const toast = useToast();
  const [refetch, setRefetch] = useState(false);
  const handleMarkAllAsRead = () => {
    mutation.mutate(
      { dId: dealerId?.id },
      {
        onSuccess: (data: any) => {
          refetchNotifications(); // This will trigger the refetch of the parent's query
          setRefetch(true);
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <VStack w="100%" alignItems="flex-start" gap="0">
        <HStack w="100%" justifyContent="space-between" padding="1rem">
          <Text textStyle="h5" fontWeight="500">
            All Reminders ({unreadCount})
          </Text>
          <HStack>
            {isImpersonating && (
              <Text
                textStyle="h6"
                color="var(--primary-600)"
                fontWeight="500"
                cursor="pointer"
                onClick={() => handleMarkAllAsRead()}
              >
                Mark all as read
              </Text>
            )}
            <Button
              variant="none"
              onClick={() => onOpen("Reminder", count)}
              display="flex"
            >
              <FullscreenIcon />
            </Button>
          </HStack>
        </HStack>

        <ReminderCard page={1} perPage={5} refetch={refetch} />
      </VStack>
    </>
  );
};
