// util.ts

import { getDealershipUsers } from "../../utils/api/line-rings.api";
import {
  CalendarIcon,
  ContactReminderIcon,
  ServiceIcon,
  StartMeetingIcon,
  InfoIcon,
  ProcessSaleIcon,
} from "../icons/svg";
import * as yup from "yup";

export const AppointmentOptions: SelectIconOptionProps[] = [
  {
    label: "Reminder",
    value: "reminder",
    icon: <ContactReminderIcon />,
  },
  { label: "Meeting", value: "appointment", icon: <StartMeetingIcon /> },
  {
    label: "Service",
    value: "service_appointment",
    icon: <ServiceIcon />,
  },
];

export const ReminderOptions: SelectIconOptionProps[] = [
  {
    label: "Reminder",
    value: "reminder",
    icon: <ContactReminderIcon />,
  },
];
export const LeadAppointmentOptions: SelectIconOptionProps[] = [
  { label: "Meeting", value: "appointment", icon: <StartMeetingIcon /> },
  {
    label: "Service",
    value: "service_appointment",
    icon: <ServiceIcon />,
  },
];
export const MeetingOptions: SelectIconOptionProps[] = [
  { label: "Sales Meeting", value: "true", icon: <ProcessSaleIcon /> },
  {
    label: "Service Meeting",
    value: "false",
    icon: <ServiceIcon />,
  },
];
export type CreateAppointmentForm = {
  title?: string;
  userid?: Array<{
    value: number;
    label: string;
  }>;
  date?: string;
  start_time?: string;
  type?: SelectOptionProps | SelectIconOptionProps;
  // end_time?: string;
};
export type EditMeetingForm = {
  userid?: Array<{
    value: number;
    label: string;
  }>;
  dealership_id?: number;
  contact_id?: number;
  type?: SelectIconOptionProps;
  start_time?: string;
  date?: string | null;
  notes?: string;
};
export const defaultValues = {
  title: "",
  userid: [],
  date: "",
  start_time: "",
  // end_time:'',
  type: AppointmentOptions[0],
};
export const meetingDefaultValues = {
  userid: [],
  dealership_id: 0,
  contact_id: 0,
  is_sale_meeting: {
    id: 0,
    label: "",
    value: "",
    icon: "",
  },
  start_time: "",
  date: "",
  notes: "",
};
export const createAppointmentValidation = yup.object().shape({
  // title: yup.string().nullable(),
  userid: yup
    .array()
    .min(1, "At least one user is required.")
    .nullable()
    .required("User is required."),
  date: yup.string().nullable().required("Date is required."),
  start_time: yup.string().nullable().required("Time is required."),
  type: yup.object().nullable().required("required."),
});

export const editMeetingValidation = yup.object().shape({
  start_time: yup
    .string()
    .nullable()
    .required("Time is required.")
    .matches(/^(?:2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/, "Invalid time format, use HH:mm."),
});

export const createReminderValidation = yup.object().shape({
  // title: yup.string().nullable().required("Title is required."),
  date: yup.string().nullable().required("required."),
  start_time: yup.string().nullable().required("required."),
  type: yup.object().nullable().required("required."),
});
export const createLeadReminderValidation = yup.object().shape({
  title: yup.string().nullable().required("Title is required."),
  date: yup.string().nullable().required("required."),
  start_time: yup.string().nullable().required("required."),
});
export const searchDealershipUsers = async (searchStr: string, dealershipId: string) => {
  const { data } = await getDealershipUsers({ queryKey: ["", dealershipId, searchStr] });
  return data?.users?.map((user: any) => ({
    label: user?.full_name,
    value: user?.id,
  })) ?? [];
}