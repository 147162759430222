import {
  Box,
  Button,
  DrawerFooter,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Loader } from "@giphy/react-components";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContactListing from "../../../pages/(dashboard-routes)/leads/lead-details/ContactListing";
import { filterByUniqueKey, getTransformedFilters } from "../../../utils";
import { fetchContacts } from "../../../utils/api/contact-details";
import {
  createPowerDialer,
  getPowerDialerCampaign,
  updatePowerDialer,
} from "../../../utils/api/power-dialer.api";
import { useTwilioState } from "../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { CustomRadio, Drawer, Input, Select } from "../../common";
import ContactsSelectedButton from "../../common/ContactsSelectedButton";
import SearchAndFilterDrawer from "../../Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import FilterIcon from "../../icons/Filter";
import { SearchIcon } from "../../icons/svg";
import {
  CreatePowerDialerForm,
  createPowerDialerValidation,
  defaultValues,
} from "./utils";

const warmUpOptions = [
  { value: "after_hang_up", label: "After Completed Call" },
  { value: "fifteen_seconds", label: "Fifteen Seconds" },
  { value: "thirty_seconds", label: "Thirty Seconds" },
];

type CreatePowerDialerProps = {
  isOpen: boolean;
  onClose: () => void;
  powerDialerId?: string;
};

const sendOptions = [
  { label: "Bottom", value: "bottom" },
  { label: "Top", value: "top" },
];

function CreatePowerDialer({
  isOpen,
  onClose,
  powerDialerId,
}: CreatePowerDialerProps) {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [searchStr, setSearchStr] = useState("");

  const isEdit = !!powerDialerId;

  const { devices } = useTwilioState();
  const lineDevices = filterByUniqueKey(
    devices.filter((device) => device.phoneNumber),
    "phoneNumber"
  );

  const { handleSubmit, control, setValue, reset } =
    useForm<CreatePowerDialerForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(createPowerDialerValidation) as any,
    });

  useEffect(() => {
    const defaultLine =
      lineDevices?.find((line) => line.is_default)?.phoneNumber ||
      lineDevices?.[0].phoneNumber;
    setValue("line", defaultLine);
  }, [lineDevices, setValue]);

  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const user = useUserInfo("user");
  const toast = useToast();
  const navigate = useNavigate();

  const { dealership } = user || {};

  const filters = useWatch({ control, name: "filters_and_sort" });
  const filterApplied = useWatch({ control, name: "filterApplied" });

  useQuery({
    queryKey: ["fetchPowerDialerCampaign", powerDialerId],
    queryFn: () =>
      getPowerDialerCampaign({
        dealership_id: user?.dealership?.id,
        id: powerDialerId,
      }),
    retry: false,
    onSuccess(data) {
      setValue("name", data?.data?.name);
      setValue(
        "pushType",
        data?.data?.contacts_push_order === "asc" ? "top" : "bottom"
      );
      setValue("warmup_duration", data?.data?.warmup_duration);
      setValue("line", data?.data?.line);
      setValue("filters_and_sort", JSON.parse(data?.data?.filter_string));
      setValue("filterApplied", true);
    },
  });

  const { data, isFetching } = useQuery({
    queryKey: ["fetchContacts", page, filters, searchStr],
    queryFn: () => {
      const params: any = {};

      if (!searchStr) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchStr;
      }

      if (filters) {
        const tempFilters = getTransformedFilters(filters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        params.filter_and_sort = tempFilters;
      }
      return fetchContacts(dealership?.id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: filterApplied,
    onSuccess(data) {
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
    },
  });

  const updateMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updatePowerDialer({
          dealershipId: dealership?.id,
          id: powerDialerId,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    },
    onError: (error: any) => {
      toast({
        description: `Error updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createPowerDialer({
          payload,
          dealershipId: dealership?.id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Power Dialer Campaign created successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    },
    onError: (error: any) => {
      toast({
        description: `Error creating power dialer campaign: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleBulkSendMessage = async (
    values: CreatePowerDialerForm,
    shouldStart = false
  ) => {
    if (!filterApplied || !data?.data?.contacts_count) {
      return toast({
        description: "Please apply filters to fetch contacts",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    const response = await (isEdit ? updateMutation : mutation).mutateAsync({
      power_dialer_campaign: {
        campaign_name: values.name,
        contacts_push_order: values.pushType === "top" ? 1 : 0,
        dealership_id: user?.dealership?.id,
        warmup_duration: values.warmup_duration,
        filter_string: JSON.stringify(values.filters_and_sort),
      },
      line: values.line,
      filter_and_sort: getTransformedFilters(values.filters_and_sort),
      removed_contacts: removedContacts,
    });

    if (shouldStart) {
      navigate(`/power-dialer/${response.data.id}?shouldStart=true`);
    }
  };

  const openFilterDrawer = () => setOpenFilter(true);
  const closeFilterDrawer = () => setOpenFilter(false);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={
        isEdit ? "Edit Power Dialer Campaign" : "Create Power Dialer Campaign"
      }
    >
      <VStack padding="1rem" w="100%" alignItems="flex-start" gap="1rem">
        {mutation.isLoading && <Loader />}
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Input
                label="Add Campaign Name"
                type="text"
                placeholder="New Leads Calling"
                hasIcon={false}
                maxW="lg"
                isRequired
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            );
          }}
          name="name"
          control={control}
          rules={{
            required: true,
          }}
        />
        <Button
          variant="outlineBlue"
          leftIcon={<FilterIcon width="5" height="5" />}
          rightIcon={<ChevronRightIcon />}
          justifyContent="space-between"
          color="var(--grey-900)"
          width="100%"
          size="xl"
          onClick={openFilterDrawer}
        >
          <Box as="span" mr="auto">
            Filter & Sort
          </Box>
        </Button>

        {filterApplied && (
          <ContactsSelectedButton
            isLoading={isFetching}
            contactsCount={
              (data?.data?.contacts_count || 0) - removedContacts.length
            }
            onClick={onContactsOpen}
          />
        )}
        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                label="Select Line"
                onChange={(value: any) => setValue("line", value.value)}
                placeholder="Select Line"
                options={
                  lineDevices?.map((lineDevice) => ({
                    value: lineDevice.phoneNumber,
                    label: lineDevice.phoneNumber,
                  })) || []
                }
                variant="default"
                w="100%"
                value={value}
              />
            );
          }}
          name="line"
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                label="Warm Up Duration"
                options={warmUpOptions}
                placeholder="Select"
                variant="default"
                w="100%"
                onChange={(value: any) =>
                  setValue("warmup_duration", value.value)
                }
                value={value}
              />
            );
          }}
          name="warmup_duration"
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <CustomRadio
                options={sendOptions}
                value={value}
                label="Push Contacts To"
                setValue={(value: any) => setValue("pushType", value)}
              />
            );
          }}
          name="pushType"
          control={control}
          rules={{
            required: true,
          }}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button
          w="100%"
          variant="outline"
          size="xl"
          onClick={() => {
            handleSubmit((values) => handleBulkSendMessage(values))();
          }}
          isDisabled={isFetching}
        >
          Save
        </Button>
        <Button
          w="100%"
          size="xl"
          onClick={() =>
            handleSubmit((values) => handleBulkSendMessage(values, true))()
          }
          isDisabled={isFetching}
        >
          Save & Start Now
        </Button>
      </DrawerFooter>

      {openFilter && (
        <SearchAndFilterDrawer
          isOpen={openFilter}
          onClose={closeFilterDrawer}
          setFilters={(searchAndFilters) => {
            setValue("filters_and_sort", searchAndFilters);
            setValue("filterApplied", true);
            setRemovedContacts([]);
            setContacts([]);
          }}
          filters={filters}
          pageReset={() => {
            setValue(
              "filters_and_sort",
              defaultFilterValues["filter_and_sort"]
            );
            setValue("filterApplied", false);
          }}
        />
      )}

      <Drawer
        isOpen={isContactsOpen}
        onClose={onContactsClose}
        title="Contacts"
      >
        <Box padding="0 20px" marginTop="10px">
          <Input
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Contacts..."
            onChange={({ target: { value } }) => {
              setSearchStr(value);
              setPage(1);
              setContacts([]);
            }}
            width="100%"
            value={searchStr}
          />
        </Box>
        <div
          id="bulkMessageDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={data?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="bulkMessageDiv"
          />
        </div>
      </Drawer>
    </Drawer>
  );
}

export default CreatePowerDialer;
