import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { roleChecker, simpAdminChecker } from "../../../../utils";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import {
  tabConfigurationAdmin,
  tabConfigurationRestrictedDealershipUser,
  tabConfigurationSimpAdminUsers,
  tabConfigurationSimpUsers,
  tabConfigurationUser,
  tabConfigurationUserAdmin,
} from "../../../../utils/settings-tabs";

export default function UsersSettingPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };
  const role = useUserInfo("role");
  const userid = useUserInfo("id");
  const { id } = useParams();
  const modifySettings = useUserInfo("modify_settings");
  const getTabs = () => {
    console.log(role);
    if (roleChecker(role)) {
      // Simpsocial_Roles ->  'SimpSocial Owner', 'SimpSocial Admin', 'Customer Service Agent'
      if (simpAdminChecker(role)) {
        //'SimpSocial Owner', 'SimpSocial Admin'
        if (!id || id == userid) {
          return tabConfigurationAdmin; // ".. "IP Settings (false)"
        } else {
          return tabConfigurationSimpAdminUsers; // all true
        }
      } else {
        // 'Customer Service Agent'
        return tabConfigurationSimpUsers; // .. "Notification Settings  (false)","IP Settings  (false)"
      }
    } else {
      // Dealership_Roles -> 'Owner/GM', 'Manager/Admin', 'Sales Person', 'BDC Agent'
      if (!id || id == userid) {
        return modifySettings
          ? tabConfigurationUser // .. "Manage permissions (false)", "IP Settings (false)"
          : tabConfigurationRestrictedDealershipUser; //.. "Notification Settings (false)", "SMS Templates (false)", "Manage permissions (false)", "IP Settings (false)"
      } else {
        return modifySettings
          ? tabConfigurationUserAdmin // all true
          : tabConfigurationRestrictedDealershipUser; // .."Notification Settings (false)", "SMS Templates (false)", "Manage permissions (false)", "IP Settings (false)"
      }
    }
  };
  return (
    <>
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          Back
        </Button>
        <HStack gap="0.62rem">
          {/* <Button leftIcon={<PhoneIcon />} variant="outline" width="8rem">
            Test a Call
          </Button> */}
          {/* <Button leftIcon={<PauseIcon />} variant="outline">
            Pause
          </Button> */}
        </HStack>
      </Box>
      <Box
        background="white"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
        height="calc(100vh - 200px)"
      >
        <Tabs
          variant="vertical"
          display="flex"
          height="100%"
          index={activeTab}
          onChange={handleTabChange}
        >
          <TabList>
            <Text textStyle="h4" fontWeight="500" padding="1rem">
              User Settings
            </Text>
            {getTabs()?.map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {getTabs()?.map((tab, index) => (
              <TabPanel key={index} padding="1rem" height="100%">
                {activeTab === index && tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
