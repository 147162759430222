import * as yup from "yup";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../Drawers/SearchAndFilterDrawer/utils";

export type CreatePowerDialerForm = {
  name: string;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
  filterApplied: boolean;
  pushType: string;
  line: string;
  warmup_duration: string;
};

export const defaultValues = {
  name: "",
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  filterApplied: false,
  pushType: "top",
  line: "",
  warmup_duration: "after_hang_up",
};

export const createPowerDialerValidation = yup.object().shape({
  name: yup.string().required("Power Dialer Name is required."),
});
