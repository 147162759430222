import * as yup from "yup";
import { EMAIL_REGEX } from "../../../../config";

export type CreateTemplateForm = {
  subject: string;
  messageBody: string;
};

export const defaultValues = {
  subject: "",
  messageBody: "",
};

export const createTemplateValidation = yup.object().shape({
  subject: yup.string().required("Subject is required"),
  messageBody: yup
    .string()
    .required("Body is required")
    .when('$showRawHtml', {
      is: true, 
      then: (schema) => schema, 
      otherwise: (schema) => schema.test(
        "max-text-length",
        "Email content cannot exceed 2499 characters in plain text",
        function (value) {
          if (!value) return true; // if no content, validation passes

          // Create a temporary element to convert HTML to plain text
          const tempElement = document.createElement("div");
          tempElement.innerHTML = value;
          const textContent =
            tempElement.innerText || tempElement.textContent || "";

          // Check if the plain text length exceeds 2499 characters
          return textContent.length <= 2499;
        }
    )}),
});
