import { Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { fetchContact, fetchHome } from "../../../utils/api/guest-mode";
import MessageThread from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/Messages/MessageThread";
import { useMessageThread } from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/Messages/useMessageThread";
import { useMemo } from "react";

export default function GuestMessageThread() {
  const { id, dId } = useParams();

  const { data: contactDetails, isLoading } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
  });

  const updatedContactDetails = useMemo(() => (
    {...contactDetails?.data?.contacts, dealership_uuid: dId}
    ), [id, dId, contactDetails?.data?.contacts?.id]);

  const messageThreadProps = useMessageThread({
    clickedRecord: updatedContactDetails,
    clickedRow: updatedContactDetails,
    type:"guest-message",
    activeIndex: 2,
  });

  const contactInfo = contactDetails?.data?.contacts;
  const fullName = (contactInfo?.first_name || "") + " " + (contactInfo?.last_name || "");

  return (
    <VStack w="60%" mt="2.5rem" px={{ base: "1rem", md: "0" }} height="calc(100vh - 64px)" overflow="auto" mx="auto">
      <Text fontSize="2xl" fontWeight="bold">Message Thread</Text>
      {fullName.trim() && <Text fontSize="lg" fontWeight="medium" color="gray.600">Contact Name: {fullName}</Text>}
      <MessageThread
          clickedRecord={updatedContactDetails}
          setClickedRecord={() => {}}
          clickedRow={updatedContactDetails}
          type="guest-message"
          messageThreadProps={messageThreadProps}
      />
    </VStack>
  );
}
