import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PaginatedTable } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import {
  fetchCommunicationBDCCampaign,
  fetchCommunicationBroadcast,
  fetchCommunicationPowderDialer,
  fetchCommunicationRvm,
  fetchCommunicationUser,
} from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";
import {
  BroadcastHeader,
  PowerDialerHeader,
  RVMHeader,
  UserHeader,
  reportNames,
  tableHeader,
} from "./utils";

const CommunicationReport = () => {
  const dId = useUserInfo("dealership")?.id;
  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [broadcastIndex, setBroadcastIndex] = useState(0);

  const fetchFunctions = [
    fetchCommunicationBDCCampaign,
    fetchCommunicationPowderDialer, // keeping an empty index as broadcast tab is 3rd
    ,
    fetchCommunicationUser,
  ];

  const { data: dataCom, isLoading } = useQuery({
    queryKey: [
      "fetchCommunication",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      activeIndex === 3 ? 100 : perPage,
      activeIndex,
    ],
    queryFn: fetchFunctions[activeIndex],
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: dataBroadcast, isLoading: rvm } = useQuery({
    queryKey: [
      "fetchCommunicationBroadcast",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
      broadcastIndex,
    ],
    queryFn:
      broadcastIndex === 0
        ? fetchCommunicationBroadcast
        : fetchCommunicationRvm,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: activeIndex === 2,
  });

  const tableData = dataCom?.data?.data?.map((item: any) => ({
    campaignCategory: (
      <Text color="var(--primary-600)">{item?.campaign_category}</Text>
    ),
    totalCalls: item.total_calls,
    emailSent: item.emails_sent,
    completedCalls: item.completed_calls,
    emailReplies: item.email_replies,
    textSent: item.texts_sent,
    textReplies: item.text_replies,
  }));

  const powerDialerData = dataCom?.data?.data?.map((item: any) => ({
    users: item.user_name,
    outboundCalls: item.outbound_calls,
    valueCalls: item.value_calls,
    apptSet: item.appt_set,
    avgTimeSpent: item.avg_time_spent,
  }));

  const broadcastData = dataBroadcast?.data?.data?.map((item: any) => ({
    users: item.user,
    broadcastName: item.broadcast_name,
    totalMessages: item.total_messages,
    totalReplies: item.total_replies,
    percentageEngaged: item.percentage_engaged
      ? parseFloat(item.percentage_engaged.toFixed(2))
      : 0,
    optOuts: item.opt_outs,
    totalRVMs: item.total_rvms,
  }));
  const rvmData = dataBroadcast?.data?.data?.map((item: any) => ({
    campaign_name: item.campaign_name,
    campaign_time: item.campaign_time,
    total_voicemails: item.total_voicemails,
    failed_voicemails: item.failed_voicemails,
  }));

  const userData = dataCom?.data?.data?.map((item: any) => ({
    name: item.full_name,
    outboundCalls: item.outbound_calls,
    outboundCompletedCalls: item.outbound_completed_calls,
    outboundCallEngagement: item.outbound_call_engagement,
    inboundCompletedCalls: item.inbound_completed_calls,
    outboundMessages: item.outbound_messages,
    inboundMessages: item.inbound_messages,
    messagesEngagementRate: `${item.messages_engagement_rate}%`,
    outboundEmails: item.outbound_emails,
    appointmentsSet: item.appointments_set,
    appointmentsShown: item.appointments_shown,
    leadResponseTime: item.lead_response_time,
  }));

  const tableComponent = (header: any, tableData: any) => (
    <>
      <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
        Found{" "}
        {activeIndex === 2
          ? dataBroadcast?.data?.total_count
          : dataCom?.data?.total_count}
      </Text>
      <PaginatedTable
        header={header}
        data={tableData}
        isPaginated={activeIndex !== 3}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={
          activeIndex === 2
            ? dataBroadcast?.data?.total_count
            : dataCom?.data?.total_count
        }
        isLoadingData={isLoading || (activeIndex === 2 && rvm)}
      />
    </>
  );

  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <Box>
          {" "}
          <DatePicker
            value={selectionRange}
            onChange={(value) => {setSelectionRange(value); setPage(1);}}
            w="100%"
            maxDate={new Date()}
          />
        </Box>
        {/* <ExportReport
          reportType={
            activeIndex === 2
              ? broadcastIndex === 0
                ? "broadcast"
                : "rvm"
              : reportNames[activeIndex]
          }
          selectionRange={selectionRange}
        /> */}
      </HStack>
      <Tabs
        variant="table"
        defaultIndex={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        index={activeIndex}
      >
        <TabList
          background="var(--grey-50)"
          borderTop="1px solid var(--grey-300)"
        >
          <Tab borderRadius="0px !important" background="white">
            BDC Campaign
          </Tab>
          <Tab background="white">Power Dialer</Tab>
          <Tab background="white">Broadcast</Tab>
          <Tab background="white">User</Tab>
        </TabList>
        <TabPanels>
          <TabPanel mt="1rem">
            {activeIndex === 0 && tableComponent(tableHeader, tableData)}
          </TabPanel>
          <TabPanel mt="1rem">
            {activeIndex === 1 &&
              tableComponent(PowerDialerHeader, powerDialerData)}
          </TabPanel>
          <TabPanel mt="1rem">
            {activeIndex === 2 && (
              <Tabs
                variant="table"
                defaultIndex={broadcastIndex}
                onChange={(index) => setBroadcastIndex(index)}
                index={broadcastIndex}
              >
                <TabList
                  background="var(--grey-50)"
                  borderTop="1px solid var(--grey-300)"
                >
                  <Tab borderRadius="0px !important" background="white">
                    Message
                  </Tab>
                  {/* <Tab background="white">RVM</Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel mt="1rem">
                    {broadcastIndex === 0 &&
                      tableComponent(BroadcastHeader, broadcastData)}
                  </TabPanel>
                  {/* <TabPanel mt="1rem">
                    {broadcastIndex === 1 && tableComponent(RVMHeader, rvmData)}
                  </TabPanel> */}
                </TabPanels>
              </Tabs>
            )}
          </TabPanel>

          <TabPanel mt="1rem">
            {activeIndex === 3 && tableComponent(UserHeader, userData)}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default CommunicationReport;
