import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Drawer, Input } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { createCategories } from "../../../utils/api/bdc-campaign.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

type categoryProps = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  apiSet: any;
  dealership_id: any;
};
export const CreateCategory = ({
  isOpen,
  onClose,
  refetch,
  apiSet,
  dealership_id,
}: categoryProps) => {
  const toast = useToast();
  const [name, setName] = useState<string>("");
  const mutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.createCategories : createCategories
  );
  const id = useUserInfo("dealership")?.id ?? dealership_id;
  const handleSubmit = () => {
    mutation?.mutate(
      {
        id: id,
        payload: {
          category: {
            name: name,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          toast({
            description: id?"Category created successfully.":"The Category is being updated in all dealerships in 15 minutes.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ===
              "must be unique within the same dealership"
                ? "The category name must be unique."
                :error?.response?.data?.error?.toString()?? "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Create Category">
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Input
            placeholder="Type Name"
            label="Name"
            hasIcon={false}
            type="text"
            maxW="lg"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            isDisabled={name === ""}
            onClick={handleSubmit}
          >
            Add
          </Button>
        </DrawerFooter>
      </Box>
      {mutation?.isLoading && <Loader />}
    </Drawer>
  );
};
