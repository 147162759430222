import { VStack } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";

interface InfiniteScrollComponentProps<T> {
  data: T[];
  fetchMore: () => void;
  hasMore: boolean;
  renderItem: (item: T, index: number) => JSX.Element;
  scrollableTarget: string;
  styles?: React.CSSProperties;
}

const InfiniteScrollComponent = <T,>({
  data,
  fetchMore,
  hasMore,
  renderItem,
  scrollableTarget,
  styles,
}: InfiniteScrollComponentProps<T>) => {
  return (
    <div
      id={scrollableTarget}
      style={{
        height: "calc(100vh - 207px)",
        overflow: "auto",
        width: "100%",
        ...styles,
      }}
    >
      <InfiniteScroll
        dataLength={data.length || 0}
        next={fetchMore}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget={scrollableTarget}
      >
        <VStack w="100%" spacing="1rem" p="1rem">
          {data?.map((item, index) => renderItem(item, index))}
        </VStack>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollComponent;
