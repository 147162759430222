import * as yup from "yup";
import { LINK_REGEX } from "../../../../config";
interface BusinessHourDay {
  day_status: boolean;
  start_time: string | null; // Using string for time, or null if no time is set
  end_time: string | null;
}
interface BusinessHours {
  monday: BusinessHourDay;
  tuesday: BusinessHourDay;
  wednesday: BusinessHourDay;
  thursday: BusinessHourDay;
  friday: BusinessHourDay;
  saturday: BusinessHourDay;
  sunday: BusinessHourDay;
  initial_sms_opt_in: boolean;
  initial_sms_opt_message: string;
  thirty_days_opt_in: boolean;
  thirty_days_opt_message: string;
  tcpa_disposition: string;
}
export type GeneralForm = {
  name: string;
  time_zone: string;
  website: string;
  credit_app_link: string;
  address: string;
  business_hours: BusinessHours;
  mms: boolean;
  virtual_dealership: boolean;
  bhph: boolean;
};

export const defaultValues = {
  name: "",
  time_zone: "",
  website: "",
  address: "",
  credit_app_link: "",
  business_hours: {
    monday: { day_status: false, start_time: null, end_time: null },
    tuesday: { day_status: false, start_time: null, end_time: null },
    wednesday: { day_status: false, start_time: null, end_time: null },
    thursday: { day_status: false, start_time: null, end_time: null },
    friday: { day_status: false, start_time: null, end_time: null },
    saturday: { day_status: false, start_time: null, end_time: null },
    sunday: { day_status: false, start_time: null, end_time: null },
    initial_sms_opt_in: false,
    initial_sms_opt_message: "",
    thirty_days_opt_in: false,
    thirty_days_opt_message: "",
    tcpa_disposition: "",
  },
  virtual_dealership: false,
  bhph: false,
  mms: false,
};

export const generalFormValidation: any = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(50, "Name should have less than 50 characters.")
    .required("Name is required."),
  time_zone: yup.string().nullable().required("Timezone is required."),
  website: yup.string().nullable().matches(LINK_REGEX, "Invalid Website Link!"),
  // credit_app_link: yup
  //   .string()
  //   .nullable()
  //   .transform((value) => (value === "" ? null : value))
  //   .matches(LINK_REGEX, "Invalid Link!"),
  address: yup.string().nullable().max(50),
  business_hours: yup.object().shape(
    [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ].reduce((acc: any, day) => {
      acc[day] = yup.object().shape({
        day_status: yup.boolean().required(`${day} status is required`),
        start_time: yup
          .string()
          .nullable()
          .when("day_status", {
            is: true,
            then: yup
              .string()
              .required(`${day} start time is required`)
              .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                "Invalid time format (HH:mm)"
              ),
          }),
        end_time: yup
          .string()
          .nullable()
          .when("day_status", {
            is: true,
            then: yup
              .string()
              .required(`${day} end time is required`)
              .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                "Invalid time format (HH:mm)"
              ),
          })
          .test(
            "is-after-start",
            "End time must be after start time",
            function (value) {
              const { start_time } = this.parent;
              return value && start_time ? value > start_time : true;
            }
          ),
      });
      return acc;
    }, {})
  ),
});
