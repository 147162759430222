import {
  Box,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  calculateEndDate,
  calculateStartDate,
  formatTimeTo12Hour,
  humanize,
} from "../../../utils";
import {
  getDealershipAppointments,
  getMyAppointments,
} from "../../../utils/api/meeting.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const Calendar = () => {
  const [view, setView] = useState("day");
  const [storeCalendar, setStoreCalendar] = useState(true);
  const [events, setEvents] = useState([]);
  const dealership = useUserInfo("dealership");
  const navigate = useNavigate();
  const { isLoading } = useQuery({
    queryKey: [
      "Appointments",
      dealership?.id,
      "appointment_set,service_appointment_set",
      calculateStartDate(new Date(), view),
      calculateEndDate(new Date(), view),
      "",
      storeCalendar,
    ],
    queryFn: storeCalendar ? getDealershipAppointments : getMyAppointments,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      const sortedEvents = data?.data?.events.sort((a: any, b: any) => {
        return (
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
        );
      });
      setEvents(sortedEvents);
    },
  });
  const groupEventsByDay = (events: any[]) => {
    const groupedEvents: { [key: string]: any[] } = {};
    events.forEach((event) => {
      const date = new Date(event?.start_time).toLocaleDateString();
      if (!groupedEvents[date]) {
        groupedEvents[date] = [];
      }
      groupedEvents[date].push(event);
    });
    return groupedEvents;
  };
  const groupEventsByStartTime = (events: any[]) => {
    const groupedEvents: { [key: string]: any[] } = {};
    events.forEach((event) => {
      // Use moment.js for date formatting
      const date = moment(event?.start_time).format("hh:mm A");
      if (!groupedEvents[date]) {
        groupedEvents[date] = [];
      }
      groupedEvents[date].push(event);
    });
    return groupedEvents;
  };

  const renderContent = () => {
    const groupedEvents =
      view === "month" || view === "week"
        ? groupEventsByDay(events)
        : groupEventsByStartTime(events);
    return (
      <>
        {events?.length > 0 && (
          <HStack justifyContent="space-between" my="0.62rem" w="100%">
            <Text fontWeight="600">Scheduled Meetings</Text>
            <Text color="var(--grey-700)" fontWeight="500">
              {view === "week"
                ? moment(calculateStartDate(new Date(), "week")).format(
                    "MM/DD/YYYY"
                  ) +
                  "-" +
                  moment(calculateEndDate(new Date(), "week")).format(
                    "MM/DD/YYYY"
                  )
                : view === "month"
                ? moment(calculateStartDate(new Date(), "month")).format(
                    "MM/DD/YYYY"
                  ) +
                  "-" +
                  moment(calculateEndDate(new Date(), "month")).format(
                    "MM/DD/YYYY"
                  )
                : moment(new Date()).format("MM/DD/YYYY")}
            </Text>
          </HStack>
        )}
        <VStack
          gap="0"
          alignItems="center"
          height="calc(100vh - 395px)"
          overflow="auto"
          maxWidth="100%"
          width="100%"
        >
          {Object?.entries(groupedEvents).map(([date, eventsOfDay]) => (
            <HStack position="relative" w="100%">
              <VStack key={date} alignItems="flex-start" w="100%" gap="0">
                {eventsOfDay.map((event: any, index: number) => (
                  <HStack position="relative" w="100%" key={index}>
                    <VStack w="30%" gap="0">
                      {index === 0 && (
                        <Text fontWeight="600">
                          {" "}
                          {view === "month" || view === "week"
                            ? moment(date).format("MM/DD/YYYY")
                            : date}
                        </Text>
                      )}
                    </VStack>
                    {index > 0 && (
                      <Box
                        w="1px"
                        h="100%"
                        left="15%"
                        position="absolute"
                        background="var(--grey-300)"
                      ></Box>
                    )}
                    {index === 0 && (
                      <Box
                        w="1px"
                        h="calc(100% - 20px)"
                        left="15%"
                        bottom="-50%"
                        transform="translateY(-25%)"
                        position="absolute"
                        background="var(--grey-300)"
                      ></Box>
                    )}
                    <VStack
                      alignItems="flex-start"
                      padding="0.62rem 1rem"
                      background="var(--grey-50)"
                      borderRadius="0.5rem"
                      w="70%"
                      mb="0.25rem"
                      marginBottom="0.5rem"
                    >
                      <Text
                        textStyle="h6"
                        fontWeight="500"
                        color="var(--primary-800)"
                        w="100%"
                        cursor="pointer"
                        onClick={() => {
                          navigate(
                            `/leads/lead-details?search=${event?.contact?.name}&contact_id=${event?.contact?.id}`
                          );
                        }}
                      >
                        {humanize(event?.contact?.name) + " - "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {formatTimeTo12Hour(event?.start_time)}
                        </span>
                      </Text>
                      {event?.participants?.length > 0 && (
                        <Text
                          textStyle="captionSmall"
                          fontWeight="500"
                          w="100%"
                        >
                          Users:{" "}
                          {event?.participants
                            ?.map((participant: any) => participant.full_name)
                            .join(", ")}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            </HStack>
          ))}
        </VStack>
      </>
    );
  };

  return (
    <Box w="24rem" background="white">
      <Tabs variant="table" index={storeCalendar ? 1 : 0}>
        <TabList w="100%">
          <Tab w="50%" onClick={() => setStoreCalendar(false)}>
            My Calendar
          </Tab>
          <Tab w="50%" onClick={() => setStoreCalendar(true)}>
            Store Calendar
          </Tab>
        </TabList>
        <Box p="1rem">
          <VStack alignItems="flex-start" gap="0.62rem" w="100%">
            <Text textStyle="h3" fontWeight="600">
              Appointment
            </Text>
            <Tabs variant="primary" w="100%">
              <TabList>
                <Tab onClick={() => setView("day")}>Today</Tab>
                <Tab onClick={() => setView("week")}>Week</Tab>
                <Tab onClick={() => setView("month")}>Month</Tab>
              </TabList>

              {isLoading ? (
                <Box
                  w="100%"
                  h="500px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner size="lg" />
                </Box>
              ) : (
                <VStack>{renderContent()}</VStack>
              )}
            </Tabs>
          </VStack>
        </Box>
      </Tabs>
    </Box>
  );
};

export default Calendar;
