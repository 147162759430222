import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, PaginatedTable } from "../../../components/common";
import { ExcelIcon, PDFIcon } from "../../../components/icons/svg";
import { SERVICES } from "../../../config";
import { simpAdminChecker } from "../../../utils";
import { getBroadcastCommunication } from "../../../utils/api/broadcast.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

export default function CommunicationLogPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";

  const showFound = impSSRole && simpAdminChecker(impSSRole);

  const communicationTableHeader = [
    { id: 1, value: "First Name", label: "first_name" },
    { id: 1, value: "Last Name", label: "last_name" },
    { id: 1, value: "Phone Number", label: "phone_number" },
    { id: 1, value: "Status", label: "status" },
    { id: 1, value: "Reason", label: "failure_reason" },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["communicationLog", id, page, perPage],
    queryFn: () =>
      getBroadcastCommunication({ id, dealershipId, page, perPage }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableData = useMemo(() => {
    if (!data?.data?.communication_logs?.length) {
      return [];
    }

    return data?.data?.communication_logs.map((communication: any) => ({
      ...communication,
      status: communication?.is_pending
        ? "Pending"
        : !communication.status
        ? "Failed"
        : "Sent",
    }));
  }, [data?.data?.communication_logs]);

  const totalCount = data?.data?.communication_logs_count;

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${dealership.id}/contact_message_campaigns.${value}?id=${id}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `broadcast_communication_logs.${value}`);
      })
      .catch((response) => {});
  };

  return (
    <Box>
      <HStack padding="1rem" w="100%" background="white">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--grey-900)",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <Box background="white" borderRadius="0.5rem" m="1.5rem">
        <HStack
          background="white"
          padding="1rem"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start">
            <Text textStyle="h2" pl="1rem" pt="1.5rem">
              Communication Logs
            </Text>
            {showFound && (
              <Text
                textStyle="caption"
                color="var(--grey-600)"
                mb="1rem"
                pl="1rem"
                pt="1.5rem"
              >
                Found ({totalCount || 0})
              </Text>
            )}
          </VStack>
          <Box m="1.5rem">
            <Dropdown
              options={ExportOptions}
              hasText={true}
              text="Export"
              onSelect={(value: any) => handleExport(value.value)}
            />
          </Box>
        </HStack>

        <PaginatedTable
          header={communicationTableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          isPaginated={true}
          hasMultiBody={false}
          lastCenter={false}
          perPageChange={(value) => {
            setPage(1);
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={totalCount || 0}
          isLoadingData={isLoading}
        />
      </Box>
    </Box>
  );
}
