import * as yup from "yup";
import {
  EMAIL_REGEX,
  FIRST_NAME_REGEX,
  LINK_REGEX,
  NAME_REGEX,
} from "../../../config";
import { isValidPhoneNumber } from "react-phone-number-input";

export type CreateBrandForm = {
  id?: string;
  business_name: string;
  brand_name: string;
  iso_country: string;
  street: string;
  city: string;
  region: string;
  postal_code: string;
  business_identity: string;
  company_type: string;
  business_type: string;
  business_industry: string;
  business_registration_identifier:string;
  business_registration_number: string;
  business_regions_of_operation: string;
  website_url: string;
  representative_1_last_name: string;
  representative_1_first_name: string;
  representative_1_email: string;
  representative_1_phone_number: string;
  representative_1_business_title: string;
  representative_1_job_position: string;
  representative_2_last_name?: string;
  representative_2_first_name?: string;
  representative_2_email?: string;
  representative_2_phone_number?: string;
  representative_2_business_title?: string;
  representative_2_job_position?: string;
  stock_exchange?: string;
  stock_ticker?: string;
  add_representative?: boolean;
  customer_name: string;
  email: string;
  social_media_profile_urls: string;
};

export const defaultValues = {
  business_name: "",
  brand_name: "",
  iso_country: "",
  street: "",
  city: "",
  region: "",
  postal_code: "",
  business_identity: "",
  company_type: "private_company",
  business_type: "",
  business_industry: "",
  business_registration_identifier:"EIN",
  business_registration_number: "",
  business_regions_of_operation: "",
  website_url: "",
  representative_1_last_name: "",
  representative_1_first_name: "",
  representative_1_email: "",
  representative_1_phone_number: "",
  representative_1_business_title: "",
  representative_1_job_position: "",
  representative_2_last_name: "",
  representative_2_first_name: "",
  representative_2_email: "",
  representative_2_phone_number: "",
  representative_2_business_title: "",
  representative_2_job_position: "",
  stock_exchange: "",
  stock_ticker: "",
  add_representative: false,
  customer_name: "",
  email: "",
  social_media_profile_urls: "",
};

export const createBrandValidation = yup.object().shape({
  business_name: yup
    .string()
    .nullable()
    // .matches(NAME_REGEX, "Invalid Legal Name.")
    .required("Legal Name is required."),
  brand_name: yup
    .string()
    .nullable()
    .required("Brand Friendly Name is required."),
  iso_country: yup.string().nullable().required("Country is required"),
  street: yup.string().nullable().required("Street is required."),
  city: yup.string().nullable().required("City is required."),
  region: yup.string().nullable().required("Region is required."),
  postal_code: yup.string().nullable().required("Postal Code is required"),
  company_type: yup.string().nullable().required("Company Type is required"),
  business_type: yup.string().nullable().required("Business Type is required"),
  business_industry: yup
    .string()
    .nullable()
    .required("Business Industry is required"),
    business_registration_identifier: yup
    .string()
    .nullable()
    .required("Business ID Type is required"),
  business_registration_number: yup
    .string()
    .nullable()
    .matches(FIRST_NAME_REGEX, "Invalid Registration Number.")
    .required("Business Registration Number is required"),
  business_regions_of_operation: yup
    .string()
    .nullable()
    .required("Business Region of Operation is required"),
  website_url: yup
    .string()
    .nullable()
    .matches(LINK_REGEX, "Enter Correct Url!")
    .required("Website Url is required"),
    social_media_profile_urls: yup
    .string()
    .nullable()
    // .matches(LINK_REGEX, "Enter Correct Url!")
    .test("is-empty-or-valid", "Enter Correct Url!", value => {
      // Only check the regex if there is a value
      return !value || LINK_REGEX.test(value);
    }),
  representative_1_last_name: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid Last Name.")
    .required("Last Name is required"),
  representative_1_first_name: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid First Name.")
    .required("First Name is required"),
  representative_1_email: yup
    .string()
    .nullable()
    .matches(EMAIL_REGEX, "Invalid Email Address")
    .required("Email is required"),
  representative_1_phone_number: yup
    .string()
    .nullable()
    .required("Phone Number is required")
    .test("phone-number1", "Invalid Phone Number.", function (value) {
      return value ? isValidPhoneNumber(value) : false;
    }),
  representative_1_business_title: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid Business Title.")
    .required("Business Title is required"),
  representative_1_job_position: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid Job Position.")
    .required("Job Position is required"),
  add_representative: yup.boolean(),
  representative_2_last_name: yup
    .string()
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .matches(NAME_REGEX, "Invalid Last Name.")
        .required("Last Name is required"),
      otherwise: yup.string().nullable(),
    }),
  representative_2_first_name: yup
    .string()
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .matches(NAME_REGEX, "Invalid First Name.")
        .required("First Name is required"),
      otherwise: yup.string().nullable(),
    }),
  representative_2_email: yup
    .string()
    .matches(EMAIL_REGEX as any, "Invalid Email Address")
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .matches(EMAIL_REGEX as any, "Invalid Email Address")
        .required("Email is required"),
      otherwise: yup
        .string()
        .nullable()
        .matches(EMAIL_REGEX as any, "Invalid Email Address"),
    }),
  representative_2_phone_number: yup
    .string()
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .required("Phone Number is required")
        .test("phone-number1", "Invalid Phone Number.", function (value) {
          return value ? isValidPhoneNumber(value) : false;
        }),
      otherwise: yup.string().nullable(),
    }),
  representative_2_job_position: yup
    .string()
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .matches(NAME_REGEX, "Invalid Job Position.")
        .required("Job Position is required"),
      otherwise: yup.string().nullable(),
    }),
  representative_2_business_title: yup
    .string()
    .nullable()
    .when("add_representative", {
      is: (add_representative: boolean) => {
        return add_representative;
      },
      then: yup
        .string()
        .nullable()
        .matches(NAME_REGEX, "Invalid Business Title.")
        .required("Business Title is required"),
      otherwise: yup.string().nullable(),
    }),
  stock_exchange: yup
    .string()
    .nullable()
    .when("company_type", {
      is: (company_type: string) => {
        return company_type === "public_company";
      },
      then: yup.string().nullable().required("Stock Exchange is required"),
      otherwise: yup.string().nullable(),
    }),
  stock_ticker: yup
    .string()
    .nullable()
    .when("company_type", {
      is: (company_type: string) => {
        return company_type === "public_company";
      },
      then: yup.string().nullable().required("Stocker Ticker is required"),
      otherwise: yup.string().nullable().nullable(),
    }),
  customer_name: yup
    .string()
    .nullable()
    // .matches(NAME_REGEX, "Invalid Customer Name")
    .required("Customer Name is required."),
  email: yup
    .string()
    .nullable()
    .matches(EMAIL_REGEX, "Invalid Email Address")
    .required("Email is required"),
});
