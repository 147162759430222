import { Box, Button, FormLabel, HStack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { mergeLeadSource } from "../../../utils/api/global-config.api";
import { loadLeadSource } from "../../AccountSettings/LeadCenterSettings/utils";
import { Modal } from "../../common";
import DebouncedAsyncSelect from "../../common/DebouncedAsyncSelect";

type MergeModalProps = {
  selectedId: string;
  isOpen: boolean;
  onClose: () => void;
  refetchParent: () => void;
};

function MergeModal({
  isOpen,
  onClose,
  selectedId,
  refetchParent,
}: MergeModalProps) {
  const toast = useToast();
  const { id: dealerId } = useParams();

  const [selectedLeadSource, setSelectedLeadSource] = useState<any>(null);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await mergeLeadSource(selectedId, dealerId, payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Merged successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetchParent();
      onClose();
    },
    onError: (error: any) => {
      toast({
        description: `Error merging: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleMerge = async () => {
    await mutation.mutateAsync({
      lead_source_id: selectedLeadSource.value,
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Merge Lead Source">
        <Box display="flex" gap={5} flexDirection="column">
          <Box padding="0 20px">
            <FormLabel
              mb="0.31rem"
              color="var(--grey-900)"
              fontSize="0.875rem"
              fontWeight="600"
              lineHeight="1rem"
              alignContent={"center"}
              display={"flex"}
            >
              Merge To
            </FormLabel>
            <DebouncedAsyncSelect
              loadOptions={(searchStr) => loadLeadSource(searchStr, dealerId)}
              defaultOptions
              value={selectedLeadSource}
              onChange={(value: any) => {
                setSelectedLeadSource(value);
              }}
              filterOption={(option: any) =>
                Number(option.value) !== Number(selectedId)
              }
              placeholder="Select Lead Source"
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  maxWidth: "100%",
                }),
              }}
            />
          </Box>

          <HStack
            w="100%"
            justifyContent="flex-end"
            borderTop="1px solid var(--bluegray-100)"
            padding="10px"
          >
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => handleMerge()}
              isDisabled={!selectedLeadSource?.value}
            >
              Merge
            </Button>
          </HStack>
        </Box>
      </Modal>
    </>
  );
}

export default MergeModal;
