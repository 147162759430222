"use client";

import { Drawer, Select } from "../../../components/common";
import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  useToast,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { bulkEnroll } from "../../../utils/api/tags";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { InfoIcon } from "../../../components/icons/svg";
import { useState } from "react";
import { getactiveBDCCampaigns } from "../../../utils/api/bdc-campaign.api";

const EnrollContact = (props: any) => {
  const { onClose, id } = props;
  const toast = useToast();
  const dealerId = useUserInfo("dealership");
  const { data, isLoading } = useQuery({
    queryKey: ["fetchTagById", dealerId?.id, ""],
    queryFn: getactiveBDCCampaigns,
  });
  const [campaign, setCampaign] = useState<SelectOptionProps | null>(null);

  const mutation = useMutation(bulkEnroll);

  const updatedData = data?.data?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      isLoading={isLoading || mutation?.isLoading}
      title={
        <Box>
          Bulk Enroll{" "}
          <Text
            textStyle="h6"
            color="var(--grey-600)"
            fontWeight="400"
            mt="0.12rem"
          >
            Choose the campaign you want this tagged contacts to enroll.
          </Text>
        </Box>
      }
    >
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Select
            label="Select Campaign"
            onChange={(value: any) => setCampaign(value)}
            value={campaign?.value}
            placeholder="Select"
            options={updatedData}
            variant="default"
            w="100%"
          />
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              mutation.mutate(
                {
                  dId: dealerId?.id,
                  tId: id,
                  payload: {
                    bdc_campaign_id: campaign?.value,
                  },
                },
                {
                  onSuccess: (response: any) => {
                    onClose();
                    toast({
                      description: response?.data?.message,
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                  onError: (error: any) => {
                    toast({
                      description: `Error enrolling contacts: ${
                        error?.response?.data?.error?.toString() ??
                        "Something went wrong"
                      }`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                }
              );
            }}
          >
            Submit
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};
export default EnrollContact;
