import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeft } from "@mui/icons-material";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HideViewIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Spinner";
import { validatePassword } from "../../../utils";
import {
  ApiResult,
  getTokenValidity,
  logoutapi,
  resetPassword,
} from "../../../utils/api/login.api";
import { logout, useAuthDispatch } from "../../../utils/context/AuthContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

interface ShowFields {
  password: boolean;
  confirmPassword: boolean;
}

interface ApiRequest {
  user: {
    reset_password_token: string | null;
    password: string;
    password_confirmation: string;
  };
}

const ResetPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAuthDispatch();
  const user = useUserInfo("user");
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("reset_password_token");
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showFields, setShowFields] = useState<ShowFields>({
    password: false,
    confirmPassword: false,
  });

  const mutation = useMutation<ApiResult, Error, ApiRequest>(resetPassword);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };

  const handleToggleField = (fieldName: keyof ShowFields) => {
    setShowFields({
      ...showFields,
      [fieldName]: !showFields[fieldName],
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { password: "", confirmPassword: "" };

    if (!form.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (!validatePassword(form.password)) {
      newErrors.password =
        "Password must be a minimum of 8 characters long and should include both a letter and a number.";
      isValid = false;
    }

    if (!form.confirmPassword) {
      newErrors.confirmPassword = "Password is required";
      isValid = false;
    } else if (form.confirmPassword !== form.password) {
      newErrors.confirmPassword = "Passwords should match";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["checkTokenValidity", token],
    queryFn: () => getTokenValidity({ token }),
    retry: false,
    enabled: !!token,
    onSuccess: async (data) => {
      if (data?.data?.message) {
        if (user && !!Object.keys(user)?.length) {
          const logoutresponse = await logoutapi();
          if (logoutresponse) {
            logout(dispatch);
          }
        }
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      mutation.mutate(
        {
          user: {
            reset_password_token: token,
            password: form.password,
            password_confirmation: form.confirmPassword,
          },
        },
        {
          onSuccess: () => {
            toast({
              description: "Password changed successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
            navigate("/login");
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  };

  if (data?.data?.error) {
    return (
      <VStack
        position="relative"
        alignItems="center"
        justifyContent="center"
        w={{ base: "90%", md: "50%" }}
        textAlign="center"
      >
        <Text textStyle="h2">Token is invalid or expired</Text>
      </VStack>
    );
  }

  return (
    <VStack
      position="relative"
      alignItems="center"
      justifyContent="center"
      w={{ base: "100%", md: "50%" }}
      p={{ base: 4, md: 6 }}
    >
      {!!token && isLoading && <Loader />}
      <Box
        p={4}
        position="absolute"
        top="0"
        left="0"
        sx={{
          a: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          },
        }}
      >
        <Link to="/login">
          <ChevronLeft />
          <Text textStyle="h6" fontWeight="700">
            Back to Login
          </Text>
        </Link>
      </Box>
      <VStack
        w={{ base: "100%", sm: "90%", md: "31.25rem" }}
        borderRadius={8}
        bg="white"
        align="flex-start"
        alignSelf="center"
        spacing={4}
        p={{ base: 4, md: 6 }}
        m="0 auto"
      >
        <Text
          textStyle="h2"
          fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
          whiteSpace="nowrap"
          textAlign="center"
        >
          Update your password
        </Text>
        <Text textStyle="h4" color="grey.700">
          Choose a strong password.
        </Text>
        <FormControl w="full">
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              type={showFields.password ? "text" : "password"}
              name="password"
              value={form.password}
              placeholder="Enter Password"
              onChange={handleChange}
              pr="2.5rem"
            />
            <InputRightElement onClick={() => handleToggleField("password")}>
              {showFields.password ? (
                <HideViewIcon sx={{ color: "var(--grey-500)" }} />
              ) : (
                <ViewIcon sx={{ color: "var(--grey-500)" }} />
              )}
            </InputRightElement>
          </InputGroup>
          <Text color="red.500" fontSize="sm">
            {errors.password}
          </Text>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={showFields.confirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={form.confirmPassword}
              placeholder="Enter Password"
              onChange={handleChange}
              pr="2.5rem"
            />
            <InputRightElement
              onClick={() => handleToggleField("confirmPassword")}
            >
              {showFields.confirmPassword ? (
                <HideViewIcon sx={{ color: "var(--grey-500)" }} />
              ) : (
                <ViewIcon sx={{ color: "var(--grey-500)" }} />
              )}
            </InputRightElement>
          </InputGroup>
          <Text color="red.500" fontSize="sm">
            {errors.confirmPassword}
          </Text>
        </FormControl>
        <Button size="lg" w="full" mt={4} onClick={handleSubmit}>
          Reset Password
        </Button>
      </VStack>
      {mutation?.isLoading && <Loader />}
    </VStack>
  );
};

export default ResetPage;
