import { Box } from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import AttachmentBar from "../../../components/AttachmentBar";
import AttachmentViewer from "../../../components/common/AttachmentViewer";
import TextEditor from "../../../components/common/TextEditor";

interface MessageEditorProps {
  control: any;
  type: any;
  setValue: any;
  getValues: any;
  attachmentUrls: string[];
  setAttachmentUrls: any;
  editorRef: React.RefObject<any>;
}

const MessageEditor: React.FC<MessageEditorProps> = ({
  control,
  type,
  setValue,
  getValues,
  attachmentUrls,
  setAttachmentUrls,
  editorRef,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState<number>(1);

  const handleTextareaChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    onChange: (value: any) => void
  ) => {
    const textarea = e.target;

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    setRows(textarea.value.split("\n").length);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      const cursorPosition = range.index;
      editor.insertText(cursorPosition, text, "user");
      setTimeout(() => {
        editor.setSelection(cursorPosition + text.length, 0);
      }, 0);
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;

        // Insert the emoji at the current cursor position
        editor.insertText(cursorPosition, text);

        // Move the cursor to the end of the inserted emoji
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };

  const handleSetBodySms = (
    text: string,
    concatText: string | undefined,
    type: string | undefined = "text"
  ): void => {
    if (type === "text") {
      const messagesText = `${getValues("messageBody")} ${
        concatText ? `${concatText}: ` : ""
      }${text}`;
      setValue("messageBody", messagesText);
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };
  return (
    <>
      <AttachmentBar
        setBody={type ? handleSetBodySms : handleSetBody}
        type={type ? "sms" : "email"}
        handleTemplate={(value, body = "", attachmentUrls) => {
          setValue("subject", value);
          setValue("messageBody", body);
          setAttachmentUrls(attachmentUrls);
        }}
        parentMenu={"global"}
        subMenu={"announcements"}
      />

      <Controller
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return type ? (
            <div
              style={{
                width: "100%",
              }}
              className="emailMsgEditor"
            >
              <textarea
                ref={textareaRef}
                placeholder="Type here"
                style={{
                  width: "100%",
                  resize: "none",
                  height: "auto",
                  minHeight: "200px",
                  padding: "24px",
                  overflow: "auto",
                  outline: "none",
                }}
                rows={rows}
                onChange={(e) => handleTextareaChange(e, onChange)}
                value={value}
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                minHeight: "calc(100vh - 700px)",
                height: "100%",
                overflow: "auto",
                position: "relative",
              }}
              className="emailComposeWrapper announcementEmailBody"
            >
              <TextEditor
                value={value}
                onChange={(value) => setValue("messageBody", value)}
                error={error?.message}
                editorRef={editorRef}
                showCharCount
              />
            </div>
          );
        }}
        name="messageBody"
        control={control}
        rules={{ required: true }}
      />

      <Box className="attachmentViewer" w="100%">
        <AttachmentViewer
          attachments={attachmentUrls}
          setAttachments={setAttachmentUrls}
        />
      </Box>
    </>
  );
};

export default MessageEditor;
