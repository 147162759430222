import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal } from "../../../../common";
import { Navigation, Thumbs } from "swiper/modules";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getCarPreview,
  setasDefaultImage,
} from "../../../../../utils/api/dealership-setting.api";
import { useParams } from "react-router-dom";
import Loader from "../../../../common/Spinner";
import NoItem from "../../../../LeadCenter/LeadDetails/DetailTabs/History/NoItem";
import { useToast } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import TooltipIfOverflow from "../../../../common/TooltipIfOverflow";
export const PreviewModal = ({ isOpen, onClose, type, media }: any) => {
  const { id } = useParams();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const mutation = useMutation(setasDefaultImage);
  const [swiperIndex, setSwipeIndex] = useState<any>(null);
  const [allInventories, setAllInventories] = useState<any>([]);
  const [swipers, setSwipers] = useState<any>({});
  const {
    data: cars,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["previewcars", id, page, 9, type, media],
    queryFn: getCarPreview,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setAllInventories((prevInventories: any) => [
        ...prevInventories,
        ...data?.data?.inventories,
      ]);
    },
  });
  const setDeafultImageCall = (invID: Number, image: string) => {
    mutation?.mutate(
      {
        id: id,
        invID: invID,
        type: media,
        data: {
          default_image_url: image,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          const swiper = swipers[variables.invID];
          if (swiper) {
            swiper.slideTo(0); // Reset to the first slide
          }
          toast({
            description: "Default picture set successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleSlideChange = (swiper: any) => {
    setSwipeIndex(swiper.activeIndex);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${cars?.data?.total_count ?? 0} ${type} cars`}
      contentMaxW="80vw"
    >
      <div
        id="scrollableDivMeeting"
        style={{ height: "calc(100vh - 200px)", overflow: "auto" }}
      >
        <InfiniteScroll
          dataLength={allInventories?.length}
          next={() => setPage((prev) => prev + 1)}
          hasMore={allInventories?.length < cars?.data?.total_count}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDivMeeting"
        >
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="0 1.5rem 1.5rem"
            // height="calc(100vh - 200px)"
            overflow="auto"
          >
            <HStack
              w="100%"
              flexWrap="wrap"
              justifyContent="space-between"
              gap="1.5rem"
            >
              {allInventories?.map((item: any) => (
                <VStack
                  w="32%"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  padding="1rem"
                  alignItems="flex-start"
                >
                  {item?.vehicle_id && (
                    <Text textStyle="h5" fontWeight="700">
                      Vehicle Id:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.vehicle_id}
                      </Box>
                    </Text>
                  )}
                  {item?.name && (
                    <Text textStyle="h5" fontWeight="700">
                      Title:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.name}
                      </Box>
                    </Text>
                  )}
                  {item?.description && (
                    <Text textStyle="h5" fontWeight="700">
                      Description:{" "}
                      <Box as="span" fontWeight="400">
                        <TooltipIfOverflow>
                          {item?.description}
                        </TooltipIfOverflow>
                      </Box>
                    </Text>
                  )}
                  {item?.vehicle_detail_link && (
                    <Text textStyle="h5" fontWeight="700">
                      VDP Link:{" "}
                      <Box
                        as="span"
                        fontWeight="400"
                        onClick={() => {
                          window.open(item?.vehicle_detail_link);
                        }}
                      >
                        <TooltipIfOverflow>
                          {" "}
                          {item?.vehicle_detail_link}
                        </TooltipIfOverflow>
                      </Box>
                    </Text>
                  )}
                  {item?.vin && (
                    <Text textStyle="h5" fontWeight="700">
                      VIN:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.vin}
                      </Box>
                    </Text>
                  )}
                  {item?.year && (
                    <Text textStyle="h5" fontWeight="700">
                      Year:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.year}
                      </Box>
                    </Text>
                  )}
                  <Text textStyle="h5" fontWeight="700">
                    Price:{" "}
                    <Box as="span" fontWeight="400">
                      {item?.price}
                    </Box>
                  </Text>
                  {item?.make && (
                    <Text textStyle="h5" fontWeight="700">
                      Make:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.make}
                      </Box>
                    </Text>
                  )}
                  {item?.model && (
                    <Text textStyle="h5" fontWeight="700">
                      Model:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.model}
                      </Box>
                    </Text>
                  )}
                  {(item?.odometer || item?.city_mpg) && (
                    <Text textStyle="h5" fontWeight="700">
                      Mileage:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.odometer ?? item?.city_mpg}
                      </Box>
                    </Text>
                  )}
                  {item?.transmission && (
                    <Text textStyle="h5" fontWeight="700">
                      Transmission:{" "}
                      <Box as="span" fontWeight="400">
                        {item?.transmission}
                      </Box>
                    </Text>
                  )}
                  <Text textStyle="h5" fontWeight="700">
                    Image Count:{" "}
                    <Box as="span" fontWeight="400">
                      {item?.images?.length}
                    </Box>
                  </Text>

                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    // onSlideChange={(swiper: any) =>{
                    //   setSwipeIndex(swiper?.activeIndex)
                    //   console.log('Active index is now:', swiper.activeIndex);
                    // }
                    // }
                    onInit={(swiper: any) => {
                      setSwipers((prev: any) => ({
                        ...prev,
                        [item.id]: swiper,
                      }));
                      if (!!swiper.navigation) {
                        swiper.navigation.destroy();
                      }
                    }}
                    onSlideChange={handleSlideChange}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper2 showNavs"
                  >
                    {item?.images?.map((itemm: string, index: any) => (
                      <SwiperSlide key={item}>
                        <HStack
                          w="100%"
                          justifyContent="flex-end"
                          padding="1rem"
                        >
                          <Button
                            variant="outline"
                            onClick={() => setDeafultImageCall(item?.id, itemm)}
                            isDisabled={item?.default_image === itemm}
                          >
                            Set this picture as default
                          </Button>
                        </HStack>
                        <Image
                          src={itemm}
                          alt="car"
                          objectFit="cover"
                          w="100%"
                          h="100%"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {item?.images?.length > 0 && (
                    <Text>{`${swiperIndex + 1} / ${
                      item?.images?.length
                    }`}</Text>
                  )}
                </VStack>
              ))}
              {!isLoading && cars?.data?.inventories?.length === 0 && (
                <NoItem />
              )}
            </HStack>
          </VStack>
        </InfiniteScroll>
      </div>
      {(isLoading || mutation?.isLoading) && <Loader />}
    </Modal>
  );
};
