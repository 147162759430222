import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { markAllReminderAsRead } from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import MissedCallCard from "./MissedCallCard";

interface MissedCallProps {
  onOpen: (val: string, count: number) => void;
  onClose: () => void;
  count: number;
  unreadCount: number;
  countRefetch?: () => void;
}

const MissedCalls = ({
  onOpen,
  onClose,
  count,
  countRefetch,
  unreadCount,
}: MissedCallProps) => {
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const [refetch, setRefetch] = useState(false);

  const mutation = useMutation(markAllReminderAsRead);
  const toast = useToast();
  const handleMarkAllAsRead = () => {
    mutation.mutate(
      { dId: dealerId?.id, payload: { missed: true } },
      {
        onSuccess: (data: any) => {
          onClose();
          toast({
            status: "success",
            description: "All Missed calls are marked as read",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          countRefetch && countRefetch();
          setRefetch(true);
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack w="100%" alignItems="flex-start" gap="0">
      <HStack w="100%" justifyContent="space-between" padding="1rem">
        <Text textStyle="h5" fontWeight="500">
          All Missed Calls ({unreadCount})
        </Text>
        <HStack alignItems="center">
          {isImpersonating && (
            <Text
              textStyle="h6"
              color="var(--primary-600)"
              fontWeight="500"
              cursor="pointer"
              onClick={() => handleMarkAllAsRead()}
            >
              Mark all as read
            </Text>
          )}
          <Button
            variant="none"
            onClick={() => onOpen("Missed Call", count)}
            display="flex"
          >
            <FullscreenIcon />
          </Button>
        </HStack>
      </HStack>
      <MissedCallCard
        page={1}
        perPage={5}
        onClose={onClose}
        refetch={refetch}
      />
    </VStack>
  );
};

export default MissedCalls;
