import { Button, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  AppointConfirmIcon,
  EngagedLeadsIcon,
  NewLeadsIcon,
  UnreadMsgIcon,
} from "../../../components/icons/svg";
import LeadStatCard from "./LeadStatCard";

const LeadResponse = ({
  dashboardStats,
  isLoading,
}: {
  dashboardStats: any;
  isLoading: boolean;
}) => {
  const { leads, messages, contacts } = dashboardStats || {};
  const navigate = useNavigate();

  const handleNavigate = (statKey: string) => {
    navigate(`/leads/lead-details?statKey=${statKey}`);
  };

  return (
    <VStack
      bg="white"
      p="1.5rem"
      gap="1.5rem"
      align="flex-start"
      borderRadius="1rem"
      w="100%"
    >
      <HStack justify="space-between" w="100%">
        <Text textStyle="h2" fontWeight="500">
          Overall Lead Information
        </Text>
      </HStack>

      <Button onClick={() => navigate("/leads/lead-details")}>
        All Contacts ({contacts?.total_count ?? 0})
      </Button>

      <HStack
        w="100%"
        gap="1.5rem"
        mt="1rem"
        pb="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <LeadStatCard
          icon={UnreadMsgIcon}
          count={messages?.unread_messages || 0}
          label="Unread Messages"
          statKey="unread_messages"
          bgColor="var(--primary-800)"
          isClickable={messages?.unread_messages > 0}
          onClick={handleNavigate}
          isLoading={isLoading}
        />

        <LeadStatCard
          icon={NewLeadsIcon}
          count={leads?.new_leads || 0}
          label="New Leads"
          statKey="new_leads"
          bgColor="var(--red-500)"
          isClickable={leads?.new_leads > 0}
          onClick={handleNavigate}
          isLoading={isLoading}
        />

        <LeadStatCard
          icon={EngagedLeadsIcon}
          count={leads?.engaged_leads || 0}
          label="Engaged Leads"
          statKey="engaged_leads"
          bgColor="var(--secondary-600)"
          dateRange={`${moment()
            .subtract(45, "days")
            .format("MM/DD/yy")}-${moment().format("MM/DD/yy")}`}
          isClickable={leads?.engaged_leads > 0}
          onClick={handleNavigate}
          isLoading={isLoading}
        />

        <LeadStatCard
          icon={AppointConfirmIcon}
          count={leads?.appointment_confirmations || 0}
          label="Appointment Confirmations"
          statKey="appt_conf"
          bgColor="var(--secondary-600)"
          isClickable={leads?.appointment_confirmations > 0}
          onClick={handleNavigate}
          isLoading={isLoading}
        />
      </HStack>

      <HStack justify="space-between" mt="1.5rem" align="flex-start" w="100%">
        <Text textStyle="h2" fontWeight="500">
          Response Time Details
        </Text>
        <HStack>
          <VStack maxW="15rem" align="flex-start">
            <Text textStyle="h4">Avg New Lead Response Time</Text>
            <Text textStyle="h3" color="var(--primary-600)">
              {isLoading ? (
                <Spinner size="md" />
              ) : (
                leads?.avg_new_lead_response_time || `0 seconds`
              )}
            </Text>
          </VStack>
          <VStack maxW="15rem" align="flex-start">
            <Text textStyle="h4">Avg Inbound Communication Response Time</Text>
            <Text textStyle="h3" color="var(--secondary-600)">
              {isLoading ? (
                <Spinner size="md" />
              ) : (
                leads?.avg_inbound_communication_response_time || `0 seconds`
              )}
            </Text>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default LeadResponse;
