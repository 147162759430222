import {
  Box,
  Button,
  HStack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Drawer, Input, Select, TagsInput } from "../../../components/common";
import ContactListing from "../../../pages/(dashboard-routes)/leads/lead-details/ContactListing";
import { getTransformedFilters } from "../../../utils";
import { sendBulkEmail, sendEmail } from "../../../utils/api/communication.api";
import {
  fetchContacts,
  fetchDealershipEmail,
} from "../../../utils/api/contact-details";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AttachmentBar from "../../AttachmentBar";
import SearchAndFilterDrawer from "../../Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import ScheduleModal from "../../Drawers/SmsDrawer/ScheduleModal";
import AttachmentViewer from "../../common/AttachmentViewer";
import TextEditor from "../../common/TextEditor";
import FilterIcon from "../../icons/Filter";
import { SearchIcon } from "../../icons/svg";
import {
  ComposeBlastForm,
  composeBlastValidation,
  defaultValues,
} from "./utils";

export const throttlingOptions = [
  { label: "200 per hour", value: "two_hundred" },
  { label: "400 per hour", value: "four_hundred" },
  { label: "600 per hour", value: "six_hundred" },
  { label: "800 per hour", value: "eight_hundred" },
  { label: "Or Max", value: "all_time" },
];

const ComposeBlast = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from, subject } = location.state || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const editorRef = useRef();
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [showRawHtml, setShowRawHtml] = useState(false);

  useEffect(() => {
    if (subject) {
      setValue("subject", `RE: [${subject}]`);
    }
    if (from) {
      setValue("to", from);
    }
  }, []);

  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const toast = useToast();

  const { handleSubmit, control, setValue, reset, trigger, getValues } =
    useForm<ComposeBlastForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(composeBlastValidation(type || "")) as any,
      context: { showRawHtml },
    });

  const user = useUserInfo("user");
  const { dealership } = user || {};

  const filters = useWatch({ control, name: "filters_and_sort" });
  const filterApplied = useWatch({ control, name: "filterApplied" });

  const { data } = useQuery({
    queryKey: ["fetchContacts", page, filters, searchStr],
    queryFn: () => {
      const params: any = { emails: true };

      if (!searchStr) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchStr;
      }

      if (filters) {
        const tempFilters = getTransformedFilters(filters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        params.filter_and_sort = tempFilters;
      }
      return fetchContacts(dealership?.id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: filterApplied,
    onSuccess(data) {
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
    },
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (type ? sendBulkEmail : sendEmail)({
          payload,
          dealershipId: user?.dealership?.id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
      navigate("/email-blast");
    },
    onError: (error: any) => {
      toast({
        description: `Error Sending Email: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail"],
    queryFn: () => fetchDealershipEmail(user?.dealership?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};

  const getEmailPayload = () => {
    const values = getValues();
    return {
      from_address: userEmail,
      to_address: values.to,
      cc_address: values.cc
        .filter((ccValue: any) => ccValue.value !== values.to)
        .map((ccValue: any) => ccValue.value)
        .join(","),
      subject: values.subject,
      body: values.messageBody,
      internal_action: type ? "campaign_outbound" : "outbound",
      attachment_urls: attachmentUrls.map((attachment: any) => attachment.url),
      filter_and_sort: getTransformedFilters(filters),
      removed_contacts: removedContacts,
      throttling_rate: values.throttling_rate,
    };
  };

  const shouldSendEmail = () => {
    if (!!type && (!filterApplied || !data?.data?.contacts_count)) {
      return true;
    }

    if (data?.data?.contacts_count === removedContacts.length) {
      return true;
    }
  };

  const handleFormSubmit = (values: ComposeBlastForm) => {
    if (shouldSendEmail())
      return toast({
        description: "Please apply filters to fetch contacts",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    mutation.mutate({
      ...getEmailPayload(),
    });
  };

  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      const cursorPosition = range.index;
      editor.insertText(cursorPosition, text, "user");
      setTimeout(() => {
        editor.setSelection(cursorPosition + text.length, 0);
      }, 0);
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;

        // Insert the emoji at the current cursor position
        editor.insertText(cursorPosition, text);

        // Move the cursor to the end of the inserted emoji
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };

  const handleSchedule = (date: Date) => {
    if (shouldSendEmail())
      return toast({
        description: "Please apply filters to fetch contacts",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    mutation.mutate({
      ...getEmailPayload(),
      is_scheduled: true,
      date_scheduled: date.toUTCString(),
      broadcast_date: date.toUTCString(),
      broadcast_time: date.toUTCString(),
    });
    setIsScheduleModalOpen(false);
  };

  return (
    <>
      <Box p="1.5rem" h="100%">
        <VStack
          w="100%"
          alignItems="flex-start"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="white"
          padding="1rem"
          h="100%"
        >
          <HStack justifyContent="space-between" w="100%">
            <Button
              variant="none"
              display="flex"
              alignItems="center"
              gap="0.63rem"
              fontWeight="500"
              onClick={() => navigate("/email-blast")}
            >
              <Box
                as="span"
                display="flex"
                alignItems="center"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                padding="0.38rem"
              >
                <ChevronLeftIcon
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    color: "black",
                  }}
                />
              </Box>
              Back
            </Button>
          </HStack>

          <Box w="100%" overflow={"auto"}>
            <HStack w="100%" justifyContent="space-between" mb="1rem">
              <Text textStyle="h4" fontWeight="500">
                Compose {type ? `- ${type}` : ""}
              </Text>
              {!!type && (
                <Button variant="outline" gap="0.5rem" onClick={onOpen}>
                  <FilterIcon />
                  <Text>Advance Filters</Text>
                </Button>
              )}
            </HStack>

            <Box
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              h="calc(100vh - 284px)"
              overflow={"auto"}
              width="calc(100vw - 325px)"
            >
              <VStack gap="0" h="100%" overflow="auto">
                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text
                    textStyle="h6"
                    padding="1rem"
                    w={!type ? "20%" : "20.45%"}
                    fontWeight="500"
                  >
                    {!!type ? `Contacts` : "To"}
                  </Text>
                  {!!type ? (
                    <Button
                      variant="none"
                      maxW="100%"
                      borderLeft="1px solid var(--grey-300)"
                      height="48px !important"
                      w="100%"
                      px="0.875rem"
                      borderRadius="0"
                      fontWeight="500"
                      onClick={onContactsOpen}
                      isDisabled={!filterApplied}
                    >
                      {(data?.data?.contacts_count || 0) -
                        removedContacts.length}{" "}
                      selected
                    </Button>
                  ) : (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            value={value}
                            onChange={onChange}
                            maxW="100%"
                            border="0"
                            borderLeft="1px solid var(--grey-300)"
                            height="48px"
                            borderRadius="0"
                            fontWeight="500"
                            error={error?.message || ""}
                            borderBottom={
                              error?.message ? "1px solid var(--grey-300)" : "0"
                            }
                            errorStyle={{
                              borderLeft: "1px solid var(--grey-300)",
                              paddingTop: "6px",
                              paddingLeft: "8px",
                            }}
                          />
                        );
                      }}
                      name="to"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  )}
                </HStack>
                {!type ? (
                  <HStack
                    w="100%"
                    alignItems="flex-start"
                    borderBottom="1px solid var(--grey-300)"
                    gap="0"
                  >
                    <Text
                      textStyle="h6"
                      padding="1rem"
                      w={"20%"}
                      fontWeight="500"
                    >
                      Cc
                    </Text>
                    <Box
                      borderLeft="1px solid var(--grey-300)"
                      w="100%"
                      h="100%"
                    >
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <TagsInput
                              checkDuplicate
                              placeholder="Enter Email"
                              label=""
                              border="0"
                              pillStyles={{
                                borderLeft: "1px solid var(--grey-300)",
                                paddingLeft: 4,
                                paddingBottom: 4,
                                marginLeft: 5,
                              }}
                              borderRadius="0"
                              type="email"
                              value={value as any}
                              onChange={onChange}
                              error={error?.message}
                              onAdd={async (newValue: string) => {
                                setValue("cc", [
                                  ...value,
                                  { id: Date.now(), value: newValue } as any,
                                ]);
                              }}
                              onRemove={(id: number) =>
                                setValue(
                                  "cc",
                                  value.filter((value: any) => value.id !== id)
                                )
                              }
                            />
                          );
                        }}
                        name="cc"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                  </HStack>
                ) : (
                  <HStack
                    w="100%"
                    alignItems="flex-start"
                    borderBottom="1px solid var(--grey-300)"
                    gap="0"
                  >
                    <Text
                      textStyle="h6"
                      padding="1rem"
                      w={"20%"}
                      fontWeight="500"
                    >
                      Throttling
                    </Text>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Select
                            label=""
                            onChange={(value: any) =>
                              setValue("throttling_rate", value.value)
                            }
                            placeholder="Select Throttling"
                            options={throttlingOptions}
                            variant="default"
                            w="100%"
                            value={value}
                            isRequired
                            error={error?.message}
                            parentStyles={{
                              width: "100%",
                              border: "none",
                              borderLeft: 0,
                              borderRadius: 0,
                              borderBottom: error?.message
                                ? "1px solid var(--grey-300)"
                                : "none",
                              boxShadow: "none",
                              "&:hover": {
                                borderLeft: 0,
                              },
                            }}
                            boxStyles={{
                              borderLeft: "1px solid var(--grey-300)",
                              height: "100%",
                            }}
                          />
                        );
                      }}
                      name="throttling_rate"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </HStack>
                )}

                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text textStyle="h6" padding="1rem" w="20%" fontWeight="500">
                    Enter Subject
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          value={value}
                          onChange={onChange}
                          maxW="100%"
                          border="0"
                          borderLeft="1px solid var(--grey-300)"
                          height="48px"
                          borderRadius="0"
                          fontWeight="500"
                          error={error?.message || ""}
                          borderBottom={
                            error?.message ? "1px solid var(--grey-300)" : "0"
                          }
                          errorStyle={{
                            borderLeft: "1px solid var(--grey-300)",
                            paddingTop: "6px",
                            paddingLeft: "8px",
                          }}
                        />
                      );
                    }}
                    name="subject"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                <AttachmentBar
                  setBody={handleSetBody}
                  type="email"
                  handleTemplate={(value, body = "", attachmentUrls) => {
                    setValue("subject", value);
                    setValue("messageBody", body);
                    setAttachmentUrls(attachmentUrls);
                  }}
                  isEmail
                  showRawHtml={showRawHtml}
                  setShowRawHtml={setShowRawHtml}
                />

                <Controller
                  render={({ field: { value }, fieldState: { error } }) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                        className="ql-editor-wrapper dmsEquityMiningEditor"
                      >
                        {showRawHtml ? (
                          <Textarea
                            placeholder="Paste your HTML here..."
                            value={value}
                            onChange={(e) => setValue("messageBody", e.target.value)}
                            style={{ height: "18rem" }}
                          />
                        ) : (
                          <TextEditor
                            value={value}
                            onChange={(value) => setValue("messageBody", value)}
                            error={error?.message}
                            style={{ overflow: "auto" }}
                            editorRef={editorRef}
                            showCharCount
                          />
                        )}
                      </div>
                    );
                  }}
                  name="messageBody"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
                <Box className="attachmentViewerWrapper" w="100%">
                  <AttachmentViewer
                    attachments={attachmentUrls}
                    setAttachments={setAttachmentUrls}
                  />
                </Box>
              </VStack>
            </Box>
            <HStack justifyContent="flex-end" w="100%" pt="1rem">
              <Button
                variant="outline"
                w="10rem"
                background="white"
                onClick={() => reset(defaultValues)}
              >
                Reset
              </Button>
              <Button
                variant="outline"
                w="10rem"
                onClick={async () => {
                  const isValid = await trigger();

                  isValid && setIsScheduleModalOpen(true);
                }}
              >
                <HStack>
                  <Text>Schedule</Text>
                </HStack>
              </Button>
              <Button
                w="10rem"
                onClick={() =>
                  handleSubmit((values) => handleFormSubmit(values))()
                }
              >
                Send
              </Button>
            </HStack>
          </Box>
        </VStack>

        {/* Filter & Sort Drawer */}
        {isOpen && (
          <SearchAndFilterDrawer
            isOpen={isOpen}
            onClose={onClose}
            setFilters={(searchAndFilters) => {
              setValue("filters_and_sort", searchAndFilters);
              setValue("filterApplied", true);
              setRemovedContacts([]);
              setContacts([]);
            }}
            filters={filters}
            pageReset={() => {
              setValue(
                "filters_and_sort",
                defaultFilterValues["filter_and_sort"]
              );
              setValue("filterApplied", false);
              setRemovedContacts([]);
              setContacts([]);
            }}
          />
        )}

        <Drawer
          isOpen={isContactsOpen}
          onClose={onContactsClose}
          title="Contacts"
        >
          <Box padding="0 20px" marginTop="10px">
            <Input
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Contacts..."
              onChange={({ target: { value } }) => {
                setSearchStr(value);
                setPage(1);
                setContacts([]);
              }}
              width="100%"
              value={searchStr}
            />
          </Box>
          <div
            id="scrollableDiv"
            style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
          >
            <ContactListing
              setPage={setPage}
              contacts={contacts}
              count={data?.data?.contacts_count}
              setRemovedContacts={setRemovedContacts}
              removedContacts={removedContacts}
            />
          </div>
        </Drawer>

        <ScheduleModal
          isOpen={isScheduleModalOpen}
          onClose={() => setIsScheduleModalOpen(false)}
          handleSchedule={handleSchedule}
        />
      </Box>
    </>
  );
};

export default ComposeBlast;
