import { Button } from "@chakra-ui/button";
import { useParams } from "react-router-dom";
import { disenrollUser, fetchContact } from "../../../utils/api/guest-mode";
import { Text, Toast, useToast, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

export default function DisenrollUser() {
    const toast = useToast();
    const { id, dId } = useParams();
    const queryKey = [dId, id]
    const payload = {
        "contact": {
            "dnc": true
        }
    }

    const { data: contactDetails, isLoading } = useQuery({
        queryKey: ["fetchContact", { contact_uuid: id }, dId],
        queryFn: fetchContact,
      });

    const contactInfo = contactDetails?.data?.contacts;
    const fullName = (contactInfo?.first_name || "") + " " + (contactInfo?.last_name || "");

    const clickHandler = async () => {
        try {
            const { status, data } = await disenrollUser(queryKey, payload)
            
            if(status === 200) {
                toast({
                    title: "User disenrolled successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                });
            } 
        } catch (error: any) {
            toast({
                title: "An error occurred",
                description: error?.response?.data?.error || "Unable to disenroll user",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        }
    }

    return (
        <VStack
            w="100%"
            mt="5rem"
            px={{ base: "1rem", md: "0" }}
            height="calc(100vh - 64px)"
            overflow="auto"
        >
            <Text fontSize="larger" fontWeight="semibold" marginBottom="1rem">To disenroll {fullName} please click the button below</Text>
            <Button onClick={clickHandler}>Disenroll User</Button>
        </VStack>
    );
}