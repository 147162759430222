import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getMyAppointments = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/my_calendar?status=${queryKey[2]}&start_date=${queryKey[3]}&end_date=${queryKey[4]}`
  );
export const getDealershipAppointments = async ({
  queryKey,
}: any): Promise<ApiResult> => {
  const [, dealershipId, status, startDate, endDate, participantId] = queryKey;
  let url = `${SERVICES.apiBaseUrl}/api/dealerships/${dealershipId}/dealership_calendar?status=${status}&start_date=${startDate}&end_date=${endDate}&per_page=1000`;

  if (participantId) {
    url += `&participant_id=${participantId}`;
  }

  return axios.get(url);
};
export const getAppointmentDetail = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/dealership_calendar/${queryKey[3]}`
  );
export const createMyAppointment = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/my_calendar`,
    payload.data
  );

export const createDealershipAppointment = async (
  payload: any
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/dealership_calendar`,
    payload.data
  );
export const editAppointment = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/dealership_calendar/${payload?.meetingId}`,
    payload.data
  );
export const deleteAppointment = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/dealership_calendar/${payload?.meetingId}?reason=${payload?.reason}`
  );

export const updateStatus = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/dealership_calendar/${payload?.meetingId}/update_attendee_status`,
    payload.data
  );

// start meeting
export const startMeeting = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload.contact_id}/meetings`,
    payload.data
  );

// appointment in leads
export const createContactAppointment = async (
  payload: any
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload?.contactid}/appointments`,
    payload.data
  );

//lead reminder apis
export const createContactReminder = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload?.contactid}/reminders`,
    payload.data
  );
export const getContactReminder = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/reminders?start_date=${queryKey[3]}&end_date=${queryKey[4]}`
  );
export const viewContactReminder = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[4]}/reminders/${queryKey[3]}`
  );
export const editContactReminder = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload?.contactid}/reminders/${payload?.meetingId}`,
    payload.data
  );
export const deleteContactReminder = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload?.contactid}/reminders/${payload?.meetingId}`
  );

