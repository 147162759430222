import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CloseIcon } from "../icons/svg";
import { useQuery } from "@tanstack/react-query";
import {
  fetchVariables,
  fetchVariablesActive,
} from "../../utils/api/sms-templates.api";
import { fetchSettingsIO } from "../../utils/api/user-settings-dp.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { useTwilioState } from "../../utils/context/TwilioContext";

interface InsertVoiceMailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  isOnRight?: boolean;
  onVariableSelect?: (selectedVariable: string) => void;
}

export const InsertVoiceMailDrawerProps = ({
  isOpen,
  onClose,
  isOnRight,
  onSuccess,
  onVariableSelect,
}: InsertVoiceMailDrawerProps) => {
  const { call } = useTwilioState();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["outbound", dealerId?.id, userID],
    queryFn: fetchSettingsIO,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleSelect = (item: any) => {
    const messageObject = {
      content: {
        voicemail_url:
          item?.recording_url,
      },
      messageType: "user-defined-message", // Your custom type
      contentType: "application/json", // Ensure this is a valid key in the Message interface
    };

    try {
      // Send the message to Twilio and log the returned event SID
      const eventSid = call?.sendMessage(messageObject as any);
      console.log("Message sent successfully. Event SID:", eventSid);
      onSuccess();
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      id={isOnRight ? "variable-drawer-right" : "variable-drawer"}
    >
      <DrawerContent
        padding="0"
        borderTopRightRadius="0.5rem"
        borderTopLeftRadius="0.5rem"
        overflow="auto"
      >
        <DrawerBody padding="0">
          <VStack w="100%" gap="0" alignItems="flex-start">
            <HStack
              justifyContent="space-between"
              w="100%"
              background="var(--grey-200)"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              padding="0.69rem 1rem"
            >
              <Text textStyle="h5" fontWeight="600">
                Select VoiceMail
              </Text>
              <HStack>
                <Button
                  variant="none"
                  padding="0.38rem"
                  borderRadius="0.5rem"
                  onClick={onClose}
                  sx={{
                    svg: {
                      path: {
                        fill: "var(--grey-900)",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </HStack>
            </HStack>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              height="35rem"
              overflow="auto"
              gap="0"
            >
              {data?.voicemails?.map((voice: any, index: number) => (
                <Text
                  padding="0.69rem 0"
                  w="100%"
                  textStyle="h6"
                  fontWeight="500"
                  borderBottom="1px solid var(--grey-300)"
                  key={index}
                  cursor="pointer"
                  _hover={{
                    background: "var(--secondary-50)",
                  }}
                  onClick={() => {
                    handleSelect?.(voice);
                  }}
                >
                  {voice?.title}
                </Text>
              ))}
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
