import { Box, Button, Spinner } from "@chakra-ui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { formatTime } from "../../utils";
import { DownloadIcon, PlayIcon } from "../icons/svg";

interface AudioPlayerProps {
  transcribed?: boolean;
  audioSrc?: any;
  handleTranscribed?: () => void;
  isMulti?: boolean;
  itemIndex?: number;
  isDownload?: boolean;
}

const AudioPlayer = ({
  transcribed,
  handleTranscribed,
  audioSrc,
  isMulti = false,
  itemIndex,
  isDownload = false,
}: AudioPlayerProps) => {
  console.log("audioSrc", audioSrc);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [volume, setVolume] = useState([0, 1]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMute, setIsMute] = useState(false);
  const [lastVolume, setLastVolume] = useState([0, 0]);
  const [totalDuration, setTotalDuration] = useState(0);
  const waveformRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    const id = isMulti ? "#track" + "-" + itemIndex : "#track";
    const track = document.querySelector(id);

    if (track) {
      waveformRef.current = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2.5,
        barHeight: 0.1,
        cursorWidth: 1,
        container: isMulti ? "#waveform" + "-" + itemIndex : "#waveform",
        backend: "WebAudio",
        height: 24,
        progressColor: "#E2502A",
        waveColor: "#9E9E9E",
        cursorColor: "transparent",
      });

      if (audioSrc) waveformRef.current.load(audioSrc);

      // Set duration when audio is loaded
      waveformRef.current.on("ready", () => {
        setTotalDuration(waveformRef.current?.getDuration() || 0);
        setLoading(false); // Audio is loaded, stop showing loader
      });

      waveformRef.current.on("audioprocess", () => {
        setCurrentTime(waveformRef.current?.getCurrentTime() || 0);
      });

      waveformRef.current.on("finish", () => {
        setPlaying(false);
      });
    }

    return () => {
      if (waveformRef.current) {
        waveformRef.current.destroy();
      }
    };
  }, [audioSrc, itemIndex]);

  const handlePlay = () => {
    setPlaying(!playing);
    if (waveformRef.current) {
      waveformRef.current.playPause();
    }
  };

  // const handleVolumeChange = (value: Array<number>) => {
  //   setVolume(value);
  //   if (waveformRef.current) {
  //     waveformRef.current.setVolume(value[1]);
  //   }
  //   setIsMute(false);
  // };

  const handleMute = () => {
    if (!isMute) {
      setLastVolume(volume);
      setVolume([0, 0]);
      if (waveformRef.current) {
        waveformRef.current.setVolume(0);
      }
      setIsMute(true);
    } else {
      setVolume(lastVolume);
      if (waveformRef.current) {
        waveformRef.current.setVolume(lastVolume[1]);
      }
      setIsMute(false);
    }
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(audioSrc, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch audio file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "recording.mp3"; // Specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      maxW="100%"
      backgroundColor={!isMulti ? "var(--secondary-50)" : "transparent"}
      borderRadius={!isMulti ? "0.5rem" : "0"}
      borderLeft={isMulti ? "1px solid var(--grey-300)" : ""}
      w="100%"
      gap={!isMulti ? "0.6rem" : "1rem"}
      padding="1rem"
      cursor={loading ? "not-allowed" : "pointer"} // Change cursor when loading
      opacity={loading ? 0.5 : 1} // Grey it out when loading
      pointerEvents={loading ? "none" : "auto"} // Disable interactions when loading
    >
      <Button
        onClick={handlePlay}
        variant="none"
        borderRadius="50%"
        background="#E2502A"
        border="1px solid white"
        minHeight={!isMulti ? "2.5rem" : "1.87rem"}
        minWidth={!isMulti ? "2.5rem !important" : "1.87rem !important"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        disabled={loading} // Disable button when loading
      >
        {loading ? (
          <Spinner size="sm" color="white" />
        ) : !playing ? (
          <PlayIcon color={"white"} />
        ) : (
          <PauseIcon
            style={{
              color: "#fff",
            }}
          />
        )}
      </Button>

      <Box
        id={isMulti ? "waveform" + "-" + itemIndex : "waveform"}
        width="100%"
      ></Box>
      <audio
        id={isMulti ? "track" + "-" + itemIndex : "track"}
        src={audioSrc}
      />
      {/* <RangeSlider
        defaultValue={volume}
        min={0}
        max={1}
        step={0.01}
        onChange={(val) => handleVolumeChange(val)}
      >
        <RangeSliderTrack bg="red.100">
          <RangeSliderFilledTrack bg="tomato" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={4} index={1}>
          <Box color="tomato" />
        </RangeSliderThumb>
      </RangeSlider> */}
      <Box display="flex" alignItems="center">
        <Box
          color="var(--grey-900)"
          fontSize={isMulti ? "0.65rem" : "0.875rem"}
          fontWeight="700"
          lineHeight="1rem"
          whiteSpace="nowrap"
        >
          {formatTime(currentTime)} / {formatTime(totalDuration)}
        </Box>
        <Button
          variant="none"
          onClick={handleMute}
          display="flex"
          alignItems="center"
          ml={1}
        >
          {!isMute ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </Button>
      </Box>
      {!isMulti && (
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          onClick={handleTranscribed}
        >
          <CancelIcon
            style={{
              color: "var(--bluegray-200)",
              // background: "var(--bluegray-200)",
            }}
          />
        </Button>
      )}
      {isDownload && (
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          onClick={handleDownload}
        >
          {/* <a href={audioSrc} download> */}
          <DownloadIcon />
          {/* </a> */}
        </Button>
      )}
    </Box>
  );
};

export default AudioPlayer;
