import { HStack, VStack, Spinner, Center, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getHistory } from "../../../../../../utils/api/history.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import SlideRange from "../../../../../common/SlideRange";
import Export from "../Export";
import NoItem from "../NoItem";
import SingleMeetingCard from "./SingleMeetingCard";
import { CreateAppointment } from "../../../../../MeetingCalendar/CreateAppointment";
import { deleteAppointment, getAppointmentDetail } from "../../../../../../utils/api/meeting.api";
import { EditMeeting } from "../../../../../MeetingCalendar/EditMeeting";
import { extractTime } from "../../../../../../utils";
import AppointmentReasonModal from "../../../../../MeetingCalendar/AppointmentReasonModal";
import { getPermissions } from "../../../../../../utils/api/usersetting.api";

type MeetingType = {
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
  clickedRecord: any;
  refetchHistory?: any;
};

function Meeting({ clickedRecord, setClickedRecord, clickedRow, refetchHistory }: MeetingType) {
  const toast = useToast();
  const dealership = useUserInfo("dealership");
  const user = useUserInfo("user");
  const [meetingActivities, setMeetingActivities] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState("30");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  
  const {
    isOpen: isOpenMeeting,
    onOpen: onOpenMeeting,
    onClose: onCloseMeeting,
  } = useDisclosure();

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["meetingHistory", clickedRow?.id, sliderValue, page],
    queryFn: () =>
      getHistory({
        dealershipId: dealership?.id,
        contactId: clickedRow?.id,
        sub_type: "meeting",
        days: sliderValue,
        page,
      }),
    retry: false,
    onSuccess: (data) => {
      if (meetingActivities[0]?.contact_id !== clickedRow?.id) {
        setMeetingActivities(data.data.histories || []);
        setPage(1);
      } else {
        const filteredNewHistories = data?.data?.histories?.filter(
          (newHistory: any) =>
            !meetingActivities?.some(
              (existing) => existing.id === newHistory.id
            )
        );
        setMeetingActivities((prev) => [...prev, ...filteredNewHistories]);
      }
      setTotalCount(data.data.histories_count);
    },
  });

  const { data: userObj } = useQuery({
    queryKey: ["userById", user?.id, user?.dealership?.id],
    queryFn: getPermissions,
    enabled: !!user?.id,
  });  

  const isMeetingUpdateAllowed = userObj?.data?.permissions.some(
    (permission: any) => permission.module_key === "meeting_edit_update" && permission.allowed
  );

  const { data: meetingDetail }: any = useQuery({
    queryKey: [
      "Meeting-detail",
      dealership?.id,
      null,
      clickedRecord?.details?.id,
      null,
    ],
    queryFn: getAppointmentDetail,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clickedRecord?.details?.id,
  });

  const modifiedData = useMemo(() => {
    if (!meetingDetail) return {};
    return {
      ...meetingDetail?.data,
      date: meetingDetail?.data?.start_time?.substring(0, 10),
      start_time: extractTime(meetingDetail?.data?.start_time),
      type: { value: String(meetingDetail?.data?.is_sale_meeting) },
      userid: meetingDetail?.data?.participants[0]?.id,
      notes: clickedRecord?.details?.notes,
    };
  }, [meetingDetail]);

  const deletemutation = useMutation(deleteAppointment);

  const handleDelete = () => {
    deletemutation.mutate(
      {
        id: dealership.id,
        meetingId: clickedRecord?.details?.id,
        contactid: clickedRow?.id,
        reason: deleteReason,
      },
      {
        onSuccess(data, variables, context) {
          setIsDeleteModalOpen(false);
          setMeetingActivities([]);
          refetch();
          refetchHistory();
          toast({
            description: "Event deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };


  useEffect(() => {
    setPage(1);
    setMeetingActivities([]);
    refetch();
  }, [clickedRow?.id, refetch, sliderValue]);

  return (
    <>
      {" "}
      <Export sub_type="meeting" days={sliderValue} id={clickedRow?.id} type="MeetingsHistory"/>
      <HStack background="var(--grey-200)" padding="1rem" w="100%">
        <SlideRange handleClick={(value) => setSliderValue(value)} />
      </HStack>{" "}
      {(isFetching&&page===1) && (
        <Center w="100%" h="calc(100vh - 375px)">
          <Spinner />
        </Center>
      )}{" "}
      {!!meetingActivities?.length &&
      !!meetingActivities?.filter((item) => !!item.details).length ? (
        <div
          id="scrollableDivMeeting"
          style={{ height: "calc(100vh - 375px)", overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={meetingActivities?.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={meetingActivities?.length < totalCount}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDivMeeting"
          >
            <VStack
              alignItems="flex-start"
              gap="1rem"
              padding="1rem"
              background="var(--grey-200)"
            >
              {meetingActivities?.map((item) => {
                return (
                  <SingleMeetingCard
                    item={item}
                    setClickedRecord={setClickedRecord}
                    clickedRow={clickedRow}
                    openMeetingDrawer={onOpenMeeting}
                    onDeleteClick={() => {
                      setDeleteReason("");
                      setIsDeleteModalOpen(true);
                    }}
                    isEditable={true}
                    isMeetingUpdateAllowed={isMeetingUpdateAllowed}
                  />
                );
              })}
            </VStack>
          </InfiniteScroll>
          {
            isOpenMeeting &&
            <EditMeeting
              onClose={() => {
                onCloseMeeting();
              }}
              isOpen={isOpenMeeting}
              contactid={clickedRow?.id}
              slotData={modifiedData}
              editMode={true}
              apiCall={() => {
                setMeetingActivities([]);
                refetch(); 
                refetchHistory();
              }}
            />
          }
          {isDeleteModalOpen && (
            <AppointmentReasonModal
              isOpen={isDeleteModalOpen}
              headerText="Reason for Cancellation"
              bodyText="Please provide a reason for cancelling this appointment:"
              setModalOpen={setIsDeleteModalOpen}
              onSubmit={handleDelete}
              reason={deleteReason}
              setReason={setDeleteReason}
            />
          )}
        </div>
      ) : (
        !isLoading && !isFetching && <NoItem />
      )}
    </>
  );
}

export default Meeting;
