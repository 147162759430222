import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//brand apis
export const getContactByPhoneNo = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.id}/contacts/find_by_number/${params.phoneNumber}`
  );

export const createContact = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.id}/contacts`,
    params.payload
  );

export const createLead = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.id}/contacts/${params.contactId}/leads`,
    params.payload
  );

export const getDashboardStats = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${params.dealershipId}/dashboard`
  );

export const applyFilters = async (params: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${params.dealershipId}/${params.userId}/filters`,
    params.payload
  );

export const getFilters = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${params.dealershipId}/${params.userId}/filters`
  );

export const getVideoToken = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/videos?dealership_id=${params.dealershipId}&user_id=${params.userId}?contact_id=${params.contactId}`
  );

export const uploadVideo = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/messages/upload_file`,
    params.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );

export const attachFile = (params: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/upload_file`, params.payload, {
    headers: {
      "Content-Type": "multipart/form-data", // Set the correct content type for file upload
    },
  });

export const uploadImage = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/upload_file`,
    params.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );

export const uploadSmsImage = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/messages/mms_attachment`,
    params.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );

export const fetchDuplicateRecords = (params: any) => {
  return axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${params.contactId}/dup_check?page=${params.page}&per_page=${params.perPage}`
  );
};

export const mergedDupLeads = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${params.contactId}/merge_contacts`,
    params.payload
  );

export const addBulkCoBuyers = (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${params.contactId}/bulk_cobuyers`,
    params.payload
  );

export const getPingMessages = (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/messages/ping_messages?dealership_id=${
      params.dealershipId
    }&contact_id=${params.contactId || ""}&page=${params.page || 1}&per_page=${
      params.perPage || 10
    }`,
    params.payload
  );

export const getSingleMessage = (params: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/user_messages/${params.id}`);

export const loadLeadSourceApi = async ({
  searchValue = "",
  dealership_id = "",
}) => {
  try {
    const params: any = {};
    if (searchValue !== "") {
      params.q = searchValue;
    }
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dealership_id}/lead_sources`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getTinyUrls = (params: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/tiny_urls`, params.payload);
