import React, { useState, useCallback, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

interface DebouncedAsyncSelectProps {
  loadOptions: (inputValue: string) => Promise<any>;
  debounceTime?: number;
  defaultOptions?: boolean | any[];
  initialOptions?: any[];
  [key: string]: any;
}

const DebouncedAsyncSelect: React.FC<DebouncedAsyncSelectProps> = ({
  loadOptions,
  debounceTime = 500,
  defaultOptions = true,
  initialOptions = [],
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>(initialOptions);
  

  const loadOptionsRef = useRef(loadOptions);
  useEffect(() => {
    loadOptionsRef.current = loadOptions;
  }, [loadOptions]);


  const debouncedLoadOptions = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptionsRef.current(inputValue)
        .then((data) => {
          callback(data);
        })
        .catch((error) => {
          console.error('Error loading options:', error);
          callback([]);
        });
    }, debounceTime),
    [debounceTime]
  );


  useEffect(() => {
    if (initialOptions.length === 0 && defaultOptions === true) {
      loadOptionsRef.current('')
        .then(setOptions)
        .catch((error) => console.error('Error loading initial options:', error));
    }
  }, []);

  const loadOptionsWrapper = useCallback((inputValue: string, callback: (options: any) => void) => {
    if (inputValue) {
      debouncedLoadOptions(inputValue, callback);
    } else {
      callback(options);
    }
  }, [debouncedLoadOptions, options]);

  return (
    <AsyncSelect
      loadOptions={loadOptionsWrapper}
      onInputChange={setInputValue}
      inputValue={inputValue}
      defaultOptions={defaultOptions === true ? options : defaultOptions}
      {...props}
    />
  );
};

export default DebouncedAsyncSelect;