import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { formatToE164 } from "../../../utils";

export type SendSmsForm = {
  from: string;
  to: string;
  messageBody: string;
};

export const defaultValues = {
  from: "",
  to: "",
  messageBody: "",
};

export const sendSmsFormValidation = yup.object().shape({
  from: yup.string().required("Sender is required"),
  to: yup
    .string()
    .nullable()
    .required("Recipient is required")
    .test("phone-number1", "Invalid Phone Number.", function (value) {
      const formattedValue = formatToE164(value);
      return formattedValue ? isValidPhoneNumber(formattedValue) : true;
    }),
  // messageBody: yup.string().required("Message Body is required.").max(160, 'Max 160 characters allowed.')
});
