import { useEffect, useState } from "react";

const CHECK_INTERVAL = 5 * 60 * 1000;

function VersionChecker() {
  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json?t=" + new Date().getTime());
        const { version } = await response.json();

        if (currentVersion && currentVersion !== version) {
          window.location.reload();
        }
        setCurrentVersion(version);
      } catch (error) {
        console.error("Error checking version:", error);
      }
    };

    checkVersion();
    const interval = setInterval(checkVersion, CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [currentVersion]);

  return null;
}

export default VersionChecker;