import { Box, Flex, ScaleFade } from "@chakra-ui/react";
import GuestModeHeader from "./GuestModeHeader";

interface GuestModeContentProps {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}
const GuestModeContent = ({
  setIsDrawerOpen,
  children,
}: GuestModeContentProps) => {
  return (
    <Flex
      id="main"
      className="main-content"
      as="main"
      ml={{ base: 0, Tablet: 8 }}
      background="var(--grey-100)"
      flexGrow={1}
      direction="column"
      position="relative"
    >
      <ScaleFade
        in={true}
        transition={{ enter: { duration: 0.5, delay: 0.3 } }}
      >
        <GuestModeHeader setIsDrawerOpen={setIsDrawerOpen} />
        <Box mt="4.3rem" height={{base: "calc(100vh - 120px)", md: "calc(100vh - 64px)"}} overflow="auto" bg="#fff">
          {children}
        </Box>
      </ScaleFade>
    </Flex>
  );
};

export default GuestModeContent;
