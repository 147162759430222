import { Box, Button, Spinner } from "@chakra-ui/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface ContactsSelectedButtonProps {
  isLoading: boolean;
  contactsCount: number;
  onClick: () => void;
}

const ContactsSelectedButton: React.FC<ContactsSelectedButtonProps> = ({
  isLoading,
  contactsCount,
  onClick,
}) => {
  return (
    <Box mb="1rem" position="relative" width="full">
      <Button
        variant="outlineBlue"
        rightIcon={<ChevronRightIcon />}
        justifyContent="space-between"
        color="var(--grey-900)"
        width="100%"
        size="xl"
        onClick={onClick}
        isDisabled={isLoading}
      >
        <Box as="span" mr="auto">
          {contactsCount} Contacts Selected
        </Box>
      </Button>
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="rgba(255, 255, 255, 0.6)"
          borderRadius="md"
        >
          <Spinner size="lg" />
        </Box>
      )}
    </Box>
  );
};

export default ContactsSelectedButton;
