import { useState } from "react";
import {
    Box,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import { PaginatedTable } from "../../../common";
  import { useQuery } from "@tanstack/react-query";
  import { getDealershipInventory } from "../../../../utils/api/inventory-mapping.api";
  import { useParams } from "react-router-dom";
  import Loader from "../../../common/Spinner";

export const DealershipInventory = () => {
  const { id } = useParams();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["dealershipinventoryApi",id,page,perPage,""],
    queryFn: getDealershipInventory,
    refetchOnWindowFocus: false,
    retry: false,
  });

  

  
    const totalItems = data?.data?.total_count;
    const headers = data?.data?.inventories[0]
      ? Object.keys(data?.data?.inventories[0]).map((key) => ({
          label: key,
          value: key,
        }))
      : [];
  
    // Calculate pagination
    const activeData = data?.data?.inventories;
  return (
    <VStack
    w="100%"
    alignItems="flex-start"
    gap="0"
    overflow="auto"
    h="calc(100vh - 240px)"
  >
     <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="1500px"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            overflow= "auto"
            
          >
                 <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Rows: {totalItems}
            </Text>
           <PaginatedTable
              header={headers} // Dynamic headers
              data={activeData} // Mapping data according to headers
              itemsPerPage={perPage}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={totalItems}
              isPaginated={true}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              tableHeight={300} // Adjust table height as needed
              maxPageNumbers={5}
              isLoadingData={isFetching}
            />
          </Box>
        </VStack>
        </VStack>
      )
}
