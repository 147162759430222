import {
  Box,
  BoxProps,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Progress,
  Text,
  Textarea,
  TextareaProps,
  Tooltip,
} from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";
import React, { useState, useCallback, useEffect } from "react";
import TranslateButton from "./TranslateButton";
import { debounce } from "lodash"; // Import debounce
interface InputProps extends BoxProps {
  type?: string;
  value?: any;
  onChange?: (value: any | any[]) => void;
  onKeyDown?: (value: any | any[]) => void;
  hasIcon?: boolean;
  isIconRight?: boolean;
  icon?: React.ReactNode;
  label?: string;
  maxW?: string;
  minW?: string | undefined;
  isTextarea?: boolean;
  fullHeight?: boolean;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  error?: string;
  info?: string;
  errorStyle?: any;
  accept?: string;
  max?: any;
  min?: any;
  isNumeric?: boolean;
  isTranslatable?: boolean;
  showCharCount?: boolean; // Prop to show character count
  maxCharLimit?: number; // Prop to define max character limit
  top?: string;
  ref?: any;
  maxNumeric?: number;
  minNumeric?: number;
}

const CustomInput = ({
  type = "text",
  value,
  onChange,
  icon,
  hasIcon = false,
  isIconRight,
  label = "",
  maxW = "100%",
  minW = "auto",
  isTextarea = false,
  fullHeight = false,
  isRequired,
  error,
  defaultValue,
  onKeyDown,
  info = "",
  errorStyle = {},
  accept = "",
  isNumeric = false,
  isTranslatable = false,
  showCharCount = false, // Default to not showing character count
  maxCharLimit = 1600, // Default character limit
  top = "5%",
  maxNumeric = 60,
  minNumeric = 0,
  ref,
  ...rest
}: InputProps & TextareaProps & NumberInputProps) => {
  const [charCount, setCharCount] = useState(value ? value.length : 0);
  const [internalValue, setInternalValue] = useState(value || "");
  const perMessageLimit = 160;

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  // Debounced onChange handler
  const debouncedOnChange = useCallback(
    debounce((newValue) => {
      if (onChange) {
        onChange(newValue);
      }
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleChange = (e: any) => {
    const newValue = isNumeric ? e : e.target.value;
    setInternalValue(newValue); // Update internal state immediately
    setCharCount(newValue.length);
    debouncedOnChange(e); // Trigger debounced change
  };
  const isLimitReached = charCount >= maxCharLimit;

  return (
    <Box
      maxW={maxW}
      minW={minW}
      w="100%"
      height={fullHeight ? "100%" : "auto"}
      className="input-wrapper"
      position="relative"
    >
      {label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
          alignContent={"center"}
          display={"flex"}
        >
          {label}{" "}
          {isRequired && (
            <Box as="span" color="var(--red-600)">
              *
            </Box>
          )}{" "}
          {info && (
            <Tooltip label={info}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <InputGroup height={fullHeight ? "100%" : "auto"}>
        {hasIcon && !isIconRight && (
          <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
        )}
        {!isTextarea && !isNumeric ? (
          <Input
            type={type}
            value={type === "file" ? undefined : internalValue} // Set value only if not 'file'
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onFocus={(e) => {
              if (type === "time") {
                e?.target?.showPicker();
              }
            }}
            paddingRight={hasIcon && isIconRight ? "20px" : ""}
            paddingLeft={hasIcon && !isIconRight ? "42px" : ""}
            borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            accept={accept}
            {...rest}
          />
        ) : isNumeric ? (
          <NumberInput
            value={value}
            onChange={handleChange}
            min={minNumeric}
            max={maxNumeric}
            w={"100%"}
          >
            <NumberInputField
              placeholder={rest.placeholder}
              borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        ) : (
          <Textarea
            background="white"
            value={internalValue}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            {...rest}
            position="relative"
            ref={ref}
            paddingRight={isTranslatable ? "2rem" : "unset"}
          />
        )}
        {hasIcon && isIconRight && (
          <InputRightElement
            pointerEvents="none"
            background="white"
            right="2px"
            top="50%"
            transform="translateY(-50%)"
            height="30px"
          >
            {icon}
          </InputRightElement>
        )}
      </InputGroup>

      <Box
        position="absolute"
        top={top}
        right="6px"
        width="2rem"
        height="2rem"
        className="translateIcon"
      >
        {isTranslatable && onChange && (
          <TranslateButton value={value} onChange={onChange} />
        )}
      </Box>

      {/* Character count progress bar */}
      {showCharCount && (
        <Box mt="0.5rem" marginLeft="0.5rem">
          <Text
            fontSize="0.75rem"
            color={isLimitReached ? "var(--red-600)" : "var(--grey-500)"}
          >
            <strong>Message#{Math.ceil(charCount / perMessageLimit)}</strong>:{" "}
            {charCount % perMessageLimit}/{perMessageLimit}
          </Text>
          {isLimitReached && (
            <Text fontSize="0.75rem" color="var(--red-600)">
              The message body has reached the allowed character limit.
            </Text>
          )}
        </Box>
      )}

      <Text
        textStyle="captionSmall"
        color="var(--red-600)"
        style={{ ...errorStyle }}
      >
        {error}
      </Text>
    </Box>
  );
};

export default CustomInput;
