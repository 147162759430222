import { Box, Text, VStack } from "@chakra-ui/react";
import { geoCentroid } from "d3-geo";
import { memo, useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import allStates from "../../assets/allStates.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const USMapChart = (props: any) => {
  const { count, onOpen, setStateValue } = props;
  const [tooltipContent, setTooltipContent] = useState<string>();
  const [number, setNumber] = useState<number>();
  const [id, setId] = useState<string>();
  const [maxCount, setMaxCount] = useState<number>(0);

  const nameMapping: Record<string, string> = {
    Washington: "Washington, D.C.",
  };
  const getBackendMapping = (name: string) =>
    nameMapping?.hasOwnProperty(name) ? nameMapping[name] : name;

  const getCount = (name: string) => {
    const backendMapping = getBackendMapping(name);
    return count?.hasOwnProperty(backendMapping) ? count[backendMapping] : 0;
  };

  useEffect(() => {
    if (count) {
      const counts: number[] = Object.values(count) as number[];
      setMaxCount(Math.max(...counts));
    }
  }, [count]);

  const getRelativeFillColor = (count: number, maxCount: number): string => {
    if (count === 0) return "var(--primary-100)";
    const scale = count / maxCount;
    if (scale > 0.75) return "var(--primary-900)";
    if (scale > 0.5) return "var(--primary-700)";
    if (scale > 0.25) return "var(--primary-500)";
    return "var(--primary-300)";
  };

  return (
    <Box
      sx={{
        "path.rsm-geography:focus": {
          outline: "none",
        },
      }}
    >
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }: any) => (
            <>
              {geographies.map((geo: any) => {
                const geoCount = getCount(geo.properties.name);
                return (
                  <Geography
                    key={geo.rsmKey}
                    onMouseOver={() => {
                      setTooltipContent(`${geo.properties.name}`);
                      setNumber(getCount(`${geo.properties.name}`));
                      setId(`#${geo.rsmKey}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                      setNumber(undefined);
                      setId("");
                    }}
                    style={{
                      hover: {
                        fill: "#212121",
                        stroke: "#829DBA",
                      },
                      pressed: {
                        fill: "#F53",
                        outline: "none",
                      },
                    }}
                    id={geo.rsmKey}
                    geography={geo}
                    fill={getRelativeFillColor(geoCount, maxCount)}
                    onClick={() => {
                      setStateValue(geo.properties.name);
                      onOpen();
                    }}
                  />
                );
              })}
              {geographies.map((geo: any) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);
                return (
                  <g
                    key={geo.rsmKey + "-name"}
                    style={{
                      fill: "#fff",
                    }}
                  >
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text
                            textAnchor="middle"
                            fontSize="0.75rem"
                            fontWeight="600"
                          >
                            {cur.id}
                          </text>
                        </Marker>
                      ) : (
                        <></>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
        {/* </ZoomableGroup> */}
      </ComposableMap>
      <Tooltip
        anchorSelect={id}
        style={{
          backgroundColor: "var(--bluegray-900)",
          width: "7rem",
          padding: "0.25rem 0.625rem !important",
          borderRadius: "0.5rem",
        }}
        place="top"
        isOpen={!!tooltipContent}
        float={true}
      >
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="captionSmall" color="#E0E0E0" fontWeight="500">
            {tooltipContent}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {number}
          </Text>
        </VStack>
      </Tooltip>
    </Box>
  );
};

export default memo(USMapChart);
