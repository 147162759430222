import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//brand apis
export const getHistory = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${
      params.contactId
    }/histories?type=${params.type || ""}&sub_type=${
      params.sub_type || ""
    }&days=${params.days ?? "30"}&page=${params.page || 1}&per_page=${
      params.per_page || 20
    }`
  );
export const getHistoryById = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${params.contactId}/histories/${params.id}`
  );

export const getThreadMessages = async (params: any, type="message"): Promise<ApiResult> => {
  const url = type === "message" ? 
  `/api/messages?date=${params.date}&contact_id=${params.contactId}&dealership_id=${params.dealershipId}&page=${params.page || 1}&per_page=${params.perPage}` : 
  `/guest_mode/dealership/${params.dealershipId}/contact/${params.contactId}/messages?&page=${params.page || 1}&per_page=${params.perPage}${params?.date ? `&date=${params?.date}`:""}`;

  return axios.get(
    `${SERVICES.apiBaseUrl+url}`
  );
}

export const fetchEmailById = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/email_logs/${queryKey[2]}`
  );
};
export const getHistoryCount = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/histories/record_counts`
  );
};
