import {
  Box,
  Button,
  Checkbox,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import {
  emailValidation,
  phoneValidation,
  textValidation,
} from "../../../../../utils";
import {
  fetchContactsById,
  fetchPrefix,
  updateContact,
} from "../../../../../utils/api/contact-details";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { Input, PhoneInput, Select } from "../../../../common";

const schema = yup.object().shape({
  first_name: textValidation("First Name").required("First Name is required"),
  middle_name: textValidation("Middle Name"),
  last_name: textValidation("Last Name"),
  nick_name: textValidation("Nick Name"),
  phone_number: phoneValidation(),
  home_number: phoneValidation(),
  work_number: phoneValidation(),
  email: emailValidation(),
});

const PersonalInfo = (props: any) => {
  const {
    id,
    onCPClose,
    refetchParent,
    setIsLoadingChild,
    direction = false,
  } = props;
  const dealer = useUserInfo("dealership");
  const toast = useToast();
  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const email = useWatch({ control, name: "email" });
  const phone_number = useWatch({ control, name: "phone_number" });

  const isEmailOrPhoneEmpty = !email && !phone_number;

  const { isFetching } = useQuery({
    queryKey: ["fetchContactsById"],
    queryFn: () => fetchContactsById(dealer?.id, id),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      if (data?.data) {
        Object.keys(data.data).forEach((key: any) => {
          setValue(key, data.data[key]);
        });
      }
    },
  });

  const { data: options, isLoading: isLoadingPrefix } = useQuery({
    queryKey: ["fetchPrefix"],
    queryFn: () => fetchPrefix(dealer?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    if (!data.phone_number) {
      data.phone_number = "";
    }
    if (!data.home_number) {
      data.home_number = "";
    }
    if (!data.work_number) {
      data.work_number = "";
    }
    mutation.mutate(
      { dId: dealer?.id, id, payload: { contact: data } },
      {
        onSuccess: (data: any) => {
          refetchParent(id, data?.data);
          onCPClose();
          toast({
            description: "Setting updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error updating setting: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  useEffect(() => {
    if (isFetching || mutation?.isLoading || isLoadingPrefix) {
      setIsLoadingChild(true);
    } else {
      setIsLoadingChild(false);
    }
  }, [isFetching, mutation?.isLoading, isLoadingPrefix, setIsLoadingChild]);

  return (
    <VStack position="relative">
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem 0 4rem"
        gap="0.5rem"
        h="calc(100vh - 140px)"
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            User Information
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <HStack
              w="100%"
              alignItems="flex-start"
              flexWrap={{ md: "nowrap", base: "wrap" }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="First Name"
                    maxLength={100}
                    error={error?.message || ""}
                    isRequired
                  />
                )}
              />
              <Controller
                name="middle_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Middle Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Last Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-start">
              <Controller
                name="prefix"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    label="Prefix"
                    placeholder="Prefix"
                    onChange={(value: any) => setValue("prefix", value?.value)}
                    options={options?.data?.prefixes?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                    error={error?.message || ""}
                    isClearable
                  />
                )}
              />

              <Controller
                name="nick_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Nick Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-end">
              <Controller
                name="company_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Company Name"
                    maxLength={200}
                    error={error?.message || ""}
                  />
                )}
              />
              <Box w="50%" padding="10px">
                <Controller
                  name="use_nick_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      {...field}
                      size="lg"
                      variant="whiteCheck"
                      onChange={(e: any) => {
                        setValue("use_nick_name", e.target.checked);
                      }}
                      isChecked={Boolean(field.value)}
                    >
                      User Nickname
                    </Checkbox>
                  )}
                />
              </Box>
            </HStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            Phone
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <VStack
              w="100%"
              alignItems="flex-start"
              flexDirection={`${direction ? "row" : "column"}`}
              // flexWrap={direction ? "wrap" : "nowrap"}
            >
              <Controller
                name="phone_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Mobile"
                    placeholder="Enter phone number"
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="home_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Home"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="work_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Work"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
            </VStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Email
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder=""
                  label="Email Address"
                  maxLength={320}
                  error={error?.message || ""}
                />
              )}
            />
          </VStack>
          {isEmailOrPhoneEmpty && (
            <Text color="red.500">
              Please ensure you provide either an email address or a phone
              number
            </Text>
          )}
        </VStack>
      </VStack>
      <Button
        w="100%"
        marginTop="auto"
        position="absolute"
        left="0"
        bottom="0"
        onClick={handleSubmit(onSubmit)}
        isDisabled={isEmailOrPhoneEmpty}
      >
        Save Settings
      </Button>
    </VStack>
  );
};

export default PersonalInfo;
