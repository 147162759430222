export const BUSINESS_IDENTITY_OPTIONS = [
  {
    label: "Direct Customer",
    value: "",
  },
  {
    label: "ISV Reseller or Partner",
    value: "",
  },
];

export const BUSINESS_TYPE_OPTIONS = [
  {
    label: "Limited Liability Corporation",
    value: "Limited Liability Corporation",
  },
  {
    label: "Co-operative",
    value: "Co-operative",
  },
  {
    label: "Non-profit Corporation",
    value: "Non-profit Corporation",
  },
  {
    label: "Corporation",
    value: "Corporation",
  },
  {
    label: "Partnership",
    value: "Partnership",
  },
];

export const COMPANY_TYPE_OPTIONS = [
  { value: "public_company", label: "Public Organization" },
  { value: "private_company", label: "Private Organization" },
  { value: "non_profit", label: "Non-profit" },
  { value: "government", label: "Government" },
];

export const JOB_TITLES = [
  { value: "Director", label: "Director" },
  { value: "GM", label: "GM" },
  { value: "VP", label: "VP" },
  { value: "CEO", label: "CEO" },
  { value: "CFO", label: "CFO" },
  { value: "General Counsel", label: "General Counsel" },
  { value: "Other", label: "Other" }
];

export const BUSINESS_INDUSTRY_OPTIONS = [
  { value: "BUSINESS_INDUSTRY", label: "BUSINESS INDUSTRY" },
  { value: "AUTOMOTIVE", label: "AUTOMOTIVE" },
  { value: "AGRICULTURE", label: "AGRICULTURE" },
  { value: "BANKING", label: "BANKING" },
  { value: "CONSTRUCTION", label: "CONSTRUCTION" },
  { value: "CONSUMER", label: "CONSUMER" },
  { value: "EDUCATION", label: "EDUCATION" },
  { value: "ENGINEERING", label: "ENGINEERING" },
  { value: "ENERGY", label: "ENERGY" },
  { value: "OIL_AND_GAS", label: "OIL_AND_GAS" },
  { value: "FAST_MOVING_CONSUMER_GOODS", label: "FAST MOVING CONSUMER GOODS" },
  { value: "FINANCIAL", label: "FINANCIAL" },
  { value: "FINTECH", label: "FINTECH" },
  { value: "FOOD_AND_BEVERAGE", label: "FOOD AND BEVERAGE" },
  { value: "GOVERNMENT", label: "GOVERNMENT" },
  { value: "HEALTHCARE", label: "HEALTHCARE" },
  { value: "HOSPITALITY", label: "HOSPITALITY" },
  { value: "INSURANCE", label: "INSURANCE" },
  { value: "LEGAL", label: "LEGAL" },
  { value: "MANUFACTURING", label: "MANUFACTURING" },
  { value: "MEDIA", label: "MEDIA" },
  { value: "ONLINE", label: "ONLINE" },
  { value: "PROFESSIONAL_SERVICES", label: "PROFESSIONAL SERVICES" },
  { value: "RAW_MATERIALS", label: "RAW MATERIALS" },
  { value: "REAL_ESTATE", label: "REAL ESTATE" },
  { value: "RELIGION", label: "RELIGION" },
  { value: "RETAIL", label: "RETAIL" },
  { value: "JEWELRY", label: "JEWELRY" },
  { value: "TECHNOLOGY", label: "TECHNOLOGY" },
  { value: "TELECOMMUNICATIONS", label: "TELECOMMUNICATIONS" },
  { value: "TRANSPORTATION", label: "TRANSPORTATION" },
  { value: "TRAVEL", label: "TRAVEL" },
  { value: "ELECTRONICS", label: "ELECTRONICS" },
  { value: "NOT_FOR_PROFIT", label: "NOT FOR PROFIT" },
];

export const REGION_OF_OPERATIONS = [
  { value: "AFRICA", label: "AFRICA" },
  { value: "ASIA", label: "ASIA" },
  { value: "EUROPE", label: "EUROPE" },
  { value: "LATIN_AMERICA", label: "LATIN AMERICA" },
  { value: "USA_AND_CANADA", label: "USA AND CANADA" },
];
export const BUSINESS_ID_TYPE = [
  { value: "EIN", label: "Employer Identification Number (EIN)" },
  { value: "CBN", label: "Canadian Business Number" },
  // { value: "DUNS", label: "DUNS Number (Dun & Bradstreet)" },
  // { value: "CN", label: "Company Number" },
  // { value: "ACN", label: "Company Number from ASIC (ACN)" },
  // { value: "CIN", label: "Corporate Identity Number" },
  // { value: "VAT", label: "VAT Number" },
  // { value: "VATRN", label: "VAT Registration Number" },
  // { value: "RN", label: "Registration Number" },
  // { value: "Other", label: "Other" },


]