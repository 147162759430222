import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Box,
} from "@chakra-ui/react";

const EventsModal = ({ isOpen, onClose, events, handler }: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent height="70vh">
        <ModalHeader>All Events</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <VStack spacing={4} align="stretch">
            {events?.map((event: any, index: any) => (
              <Box
                key={index}
                p={4}
                borderWidth="1px"
                borderRadius="lg"
                onClick={() => handler(event)}
              >
                <strong>{event.title}</strong>
                <p>{event?.start.toLocaleString()}</p>
              </Box>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EventsModal;
