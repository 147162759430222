import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams, useSearchParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Car from "../../../assets/images/car.svg";
import { Drawer, Spinner } from "../../../components/common";
import FilterIcon from "../../../components/icons/Filter";
import { EmptyIcon } from "../../../components/icons/svg";
import { fetchInventory } from "../../../utils/api/guest-mode";
import InventoryFiltersDrawer from "./InventoryFiltersDrawer";

const BrowseInventory = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dId } = useParams();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("inventory_id");
  const [page, setPage] = useState(1);
  const [list, setList] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [params, setParams] = useState<any>(null);
  const [query, setQuery] = useState<any>(null);
  const [fields, setFields] = useState<any>(null);

  useEffect(() => {
    if (id) {
      setQuery(id);
      setFields("id");
    }
  }, [id]);

  const { isFetching, refetch } = useQuery({
    queryKey: ["fetchInventory", dId, { page, per_page: 9, query, fields }],
    queryFn: fetchInventory,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: dId !== ":dId",
    onSuccess(data) {
      setCount(data?.data?.total_count);
      setList([...list, ...data?.data?.inventories]);
    },
  });

  useEffect(() => {
    if (params) {
      let fields = Object.keys(params).join(",");
      fields = fields?.replace("exteriorColor", "color");
      fields = fields?.replace("interiorColor", "interior_color");
      fields = fields?.replace("mileage", "odometer");

      setFields(fields);
      setQuery(Object.values(params).join(","));
      setList([]);
      setPage(1);
    }
  }, [params]);

  useEffect(() => {
    refetch();
  }, [query]);

  return (
    <VStack w="100%">
      <VStack
        padding={{ md: "1rem", base: "0.5rem" }}
        w="100%"
        alignItems="center"
      >
        <VStack
          background="white"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
          alignItems="flex-start"
          gap="0"
          h="calc(100vh - 130px)"
          overflow="auto"
          w="100%"
        >
          <HStack justifyContent="flex-end" w="100%">
            <Box mb="2rem">
              <Button
                variant="outlineBlue"
                leftIcon={<FilterIcon />}
                rightIcon={<ChevronRightIcon />}
                justifyContent="space-between"
                color="var(--grey-900)"
                width="100%"
                size="xl"
                onClick={onOpen}
              >
                <Box>Filter & Sort</Box>
              </Button>
            </Box>
          </HStack>

          <VStack
            w="100%"
            alignItems="flex-start"
            padding={{ md: "0 1.5rem 1.5rem", base: "0 " }}
            overflow="auto"
            h="100%"
            // mt="24px"
          >
            {list && list?.length > 0 ? (
              <div
                id="scrollableDivInv"
                style={{
                  height: "calc(115vh - 415px)",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <InfiniteScroll
                  dataLength={list?.length}
                  next={() => {
                    setPage((prev) => prev + 1);
                  }}
                  hasMore={list?.length < count}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDivInv"
                >
                  <HStack
                    w="100%"
                    h="100%"
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap="1.5rem"
                  >
                    {list?.map((item: any) => (
                      <VStack
                        border="1px solid var(--grey-300)"
                        borderRadius="0.5rem"
                        padding="1rem"
                        alignItems="flex-start"
                        maxW={{
                          xl: "calc(32% - 0.5rem)",
                          md: "calc(50% - 0.75rem)",
                          base: "100%",
                        }}
                        w="100%"
                      >
                        <Box mt="12px" w="100%">
                          <VStack
                            maxW="100%"
                            w={{ md: "500px", base: "275px" }}
                            className="garageSwipper"
                          >
                            {item?.images?.length > 0 ? (
                              <Swiper
                                slidesPerView={1}
                                navigation={true}
                                modules={[Navigation]}
                                style={{ maxWidth: "100%" }}
                              >
                                {item?.images?.map(
                                  (itemm: string, index: any) => (
                                    <SwiperSlide
                                      key={item}
                                      style={{
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <VStack
                                        w="100%"
                                        minH={{ md: "200px", base: "100%" }}
                                      >
                                        <Image
                                          src={itemm}
                                          alt="car"
                                          objectFit="cover"
                                          w="100%"
                                          h="100%"
                                        />
                                      </VStack>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            ) : (
                              <VStack
                                h={{ md: "200px", base: "100%" }}
                                w="100%"
                              >
                                <Image
                                  src={Car}
                                  alt="car"
                                  objectFit="cover"
                                  w="100%"
                                  h="100%"
                                />
                              </VStack>
                            )}
                          </VStack>
                        </Box>
                        {/* <Text textStyle="h5" fontWeight="700">
                          Vehicle Id:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.id}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Title:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.name}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Description:{" "}
                          <Box as="span" fontWeight="400">
                            <TooltipIfOverflow>
                              {item?.description}
                            </TooltipIfOverflow>
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          VDP Link:{" "}
                          <Box as="span" fontWeight="400">
                            <TooltipIfOverflow>
                              {" "}
                              {item?.vehicle_detail_link}
                            </TooltipIfOverflow>
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          VIN:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.vin}
                          </Box>
                        </Text> */}
                        <Text textStyle="h5" fontWeight="700">
                          Price:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.price}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Year:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.year}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Make:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.make}
                          </Box>
                        </Text>
                        &&
                        <Text textStyle="h5" fontWeight="700">
                          Model:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.model}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Color Exterior :{" "}
                          <Box as="span" fontWeight="400">
                            {item?.color}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Color Interior:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.interior_color}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Mileage:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.odometer ?? item?.city_mpg}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          VIN:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.vin}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Stock:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.stock_number}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Seller's Notes:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.notes}
                          </Box>
                        </Text>
                        {/* <Text textStyle="h5" fontWeight="700">
                          Transmission:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.transmission}
                          </Box>
                        </Text>
                        <Text textStyle="h5" fontWeight="700">
                          Image Count:{" "}
                          <Box as="span" fontWeight="400">
                            {item?.images?.length}
                          </Box>
                        </Text> */}
                      </VStack>
                    ))}
                  </HStack>
                </InfiniteScroll>
              </div>
            ) : (
              <VStack
                w="100%"
                justifyContent="center"
                alignItems="center"
                h="100%"
              >
                <EmptyIcon />
                <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
                  No vehicles found.
                </Text>
              </VStack>
            )}
          </VStack>
        </VStack>
      </VStack>

      {isOpen && (
        <Drawer isOpen={isOpen} onClose={onClose} title="Filter & Sort">
          <InventoryFiltersDrawer
            onClose={onClose}
            refetch={refetch}
            setParams={setParams}
            params={params}
            reset={() => {
              setQuery(null);
              setFields(null);
              setParams(null);
            }}
          />
        </Drawer>
      )}
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default BrowseInventory;
