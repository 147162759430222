import { HStack, Text, VStack } from "@chakra-ui/react";
import { EmptyIcon } from "../../../../icons/svg";

function NoItem() {
  return (
    <VStack w="100%" height="400px" justifyContent="center">
      <EmptyIcon />
      <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
        Looks like there's no data at the moment.
      </Text>
    </VStack>
  );
}

export default NoItem;
