import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import CreatePowerDialer from "./CreatePowerDialer";
import PowerDialerListing from "./PowerDialerListing";
import { isInBusinessHours } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { fetchSettings } from "../../utils/api/dealership-setting.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { useTwilioState } from "../../utils/context/TwilioContext";
import { disableOpenPhone } from "../../utils/api/dealership-setting.api";

const tabMapping: any = {
  All: "",
  "In Progress": "in_progress",
  Pending: "pending",
  Paused: "paused",
  Completed: "completed",
};

function PowerDialer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  let user = useUserInfo("user");

  const { devices } = useTwilioState();
  const { data: isOpenPhone } = useQuery({
    queryKey: ["openPhone", user?.dealership?.id],
    queryFn: disableOpenPhone,
    refetchOnWindowFocus: false,
    retry: false,
  });


  return (
    <VStack w="100%" h="100%">
      <HStack
        padding="1rem 1.5rem"
        w="100%"
        background="white"
        justifyContent="space-between"
      >
        <Text textStyle="h4" fontWeight="700">
          SimpSocial Power Dialer
        </Text>
        <Tooltip
          hasArrow
          label={
            !isOpenPhone?.data?.within_hours
              ? "Outside business hours, outbound calls are not permitted."
              : devices.length
              ? ""
              : "No Line Exists!"
          }
          borderRadius="0.5rem"
          placement="top"
          background="var(--grey-900)"
          padding="0.5rem"
          fontSize="0.75rem"
          fontWeight="600"
          color="white"
        >
          <Button
            onClick={onOpen}
            isDisabled={!(devices.length && isOpenPhone?.data?.within_hours)}
          >
            Create Power Dialer Campaign
          </Button>
        </Tooltip>
      </HStack>
      <Box padding="1.5rem" w="100%">
        <VStack
          w="100%"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="1rem"
        >
          <Tabs variant="primary" w="100%" isLazy>
            <TabList w="100%">
              <Tab>All Statuses</Tab>
              <Tab borderRight="0">In Progress</Tab>
              <Tab borderRight="0">Pending</Tab>
              <Tab borderRight="0">Paused</Tab>
              <Tab>Completed</Tab>
            </TabList>
            <TabPanels>
              {["All", "In Progress", "Pending", "Paused", "Completed"].map(
                (type) => {
                  return (
                    <TabPanel>
                      <PowerDialerListing
                        type={tabMapping[type]}
                        title={type}
                        shouldRefetch={shouldRefetch}
                        setShouldRefetch={setShouldRefetch}
                      />
                    </TabPanel>
                  );
                }
              )}
            </TabPanels>
          </Tabs>
        </VStack>
      </Box>
      {isOpen && (
        <CreatePowerDialer
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setShouldRefetch(true);
          }}
        />
      )}
    </VStack>
  );
}

export default PowerDialer;
