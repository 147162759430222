import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import appStoreIcon from "../../../assets/appStoreIcon.svg";
import playStoreIcon from "../../../assets/googlePlay.svg";

const appLinks = {
  appStore: "https://apps.apple.com/us/app/simpsocial-gocrm/id6502897000",
  playStore:
    "https://play.google.com/store/apps/details?id=com.simpsocial.mobileApp&pli=1",
};

const IconContainer = ({ children }: { children: React.ReactNode }) => (
  <Box
    as="span"
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="0.38rem"
    border={`1px solid ${useColorModeValue("gray.300", "gray.600")}`}
    borderRadius="0.5rem"
  >
    {children}
  </Box>
);

const SupportButtons = () => {
  const handleExternalLink = (url: string) => {
    window.open(url, "_blank");
  };

  const textColor = useColorModeValue("gray.800", "gray.200");

  return (
    <VStack w="full" justify="center" spacing={4}>
      <Text textStyle="h5" fontWeight="700">
        Download our new and improved app
      </Text>

      <HStack w="full" justify="center" spacing={4} mt={4}>
        {[
          {
            // src: "https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg",
            src: appStoreIcon,
            alt: "Download it on the App Store",
            link: appLinks.appStore,
          },
          {
            // src: "https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg",
            src: playStoreIcon,
            alt: "Get it on Google Play",
            link: appLinks.playStore,
          },
        ].map(({ src, alt, link }) => (
          <Box
            key={link}
            as="button"
            onClick={() => handleExternalLink(link)}
            width="160px"
            height="50px"
            borderRadius="8px"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="md"
          >
            <Image
              src={src}
              alt={alt}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Box>
        ))}
      </HStack>

      <HStack w="full" justify="center" mt={2} spacing={3}>
        <HStack
          spacing={1}
          color={textColor}
          fontSize="0.75rem"
          fontWeight={500}
        >
          <IconContainer>
            <PhoneIcon />
          </IconContainer>
          <Text>
            You can contact us on our toll-free support line 888.829.1110
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default SupportButtons;
