import { HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { humanize } from "../../../../../../utils";
import { StartMeetingIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";
import { fetchUserById } from "../../../../../../utils/api/users.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";

type SingleMeetingCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
  openMeetingDrawer?: () => void;
  onDeleteClick?: () => void;
  isEditable?: boolean;
  isMeetingUpdateAllowed: boolean;
};

function SingleMeetingCard({
  item,
  setClickedRecord,
  clickedRow,
  openMeetingDrawer,
  onDeleteClick,
  isEditable,
  isMeetingUpdateAllowed,
}: SingleMeetingCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => {
        isMeetingUpdateAllowed && isEditable && setClickedRecord(item);
      }}
      title={
        item?.details?.is_sale_meeting ? "Meeting (Sales)" : "Meeting (Service)"
      }
      icon={<StartMeetingIcon />}
      isEditible={
        isMeetingUpdateAllowed &&
        isEditable &&
        (item?.details?.status == "shown_meeting" ||
          item?.details?.status == "service_appointment_shown")
      }
      isDeletable={
        isMeetingUpdateAllowed &&
        isEditable &&
        (item?.details?.status == "shown_meeting" ||
          item?.details?.status == "service_appointment_shown")
      }
      onEditClick={() => {
        openMeetingDrawer && openMeetingDrawer();
      }}
      onDeleteClick={() => {
        onDeleteClick && onDeleteClick();
      }}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created At:
              </Text>
              <Text color="var(--primary-800)" textStyle="h6" fontWeight="500">
                {moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")}
              </Text>
            </HStack>
            {item?.details?.updated_by ? (
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="600">
                  Updated By:
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.updated_by?.user_name}
                </Text>
              </HStack>
            ) : (
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="600">
                  Created By:
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.created_by?.name}
                </Text>
              </HStack>
            )}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created For:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {clickedRow.first_name} {clickedRow?.last_name}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Notes:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.notes}
              </Text>
            </HStack>
            {(item?.details?.status == "rescheduled" ||
              item?.details?.status == "deleted") && (
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="600">
                  Status:
                </Text>
                {item?.details?.status == "rescheduled" ? (
                  <Tag colorScheme="blue" size="sm">
                    {humanize(item?.details?.status)}
                  </Tag>
                ) : (
                  <Tag colorScheme="red" size="sm">
                    {humanize(item?.details?.status)}
                  </Tag>
                )}
              </HStack>
            )}
            {item?.details?.reason && (
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="600">
                  Reason:
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {humanize(item?.details?.reason)}
                </Text>
              </HStack>
            )}
          </VStack>
        </>
      }
    />
  );
}

export default SingleMeetingCard;
