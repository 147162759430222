import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeft } from "@mui/icons-material";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HideViewIcon from "@mui/icons-material/VisibilityOffOutlined";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { GoogleIcon } from "../../../components/icons/svg";
import { isValidEmail } from "../../../utils";
import { getUrl } from "../../../utils/api/login.api";
import {
  loginUser,
  resetLogin,
  useAuthDispatch,
  useAuthState,
} from "../../../utils/context/AuthContext";
import useIntercomScript from "../../../utils/useIntercom";
import SelectTwoFa from "./SelectTwoFa";
import SupportButtons from "./SupportButtons";
import TwoFA from "./TwoFA";

const LoginPage = () => {
  const dispatch = useAuthDispatch();
  const toast = useToast();
  const user = useAuthState();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };
  useEffect(() => {
    if (user?.errorMessage) {
      setErrors({
        email: "",
        password: user?.errorMessage,
      });
    }
  }, [user?.errorMessage]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: "", password: "" };

    // Validate email
    if (!form.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(form.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }

    // Validate password
    if (!form.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (e: ReactEvent) => {
    e.preventDefault();
    resetLogin(dispatch);
    if (validateForm()) {
      await loginUser(dispatch, {
        user: {
          email: form.email,
          password: form.password,
        },
      });
    }
  };
  const handleGoogleAuth = async (val: string) => {
    try {
      const url = await getUrl(val);

      if (url) {
        var decodedUrl = JSON.parse(url?.data?.auth_url);

        if (decodedUrl) {
          window.open(decodedUrl, "_self");
        }
      }
    } catch (error) {
      toast({
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  //..........................intercom........................
  const appId = "v0z6q502";

  useIntercomScript(appId);
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        app_id: appId,
      });
    }

    return () => {
      window.Intercom && window.Intercom("shutdown");
    };
  }, [appId]);
  return (
    <VStack
      position="relative"
      alignItems="center"
      justifyContent="center"
      w="50%"
    >
      <VStack
        w="31.25rem"
        borderRadius={8}
        bg="white"
        align="flex-start"
        alignSelf="center"
        spacing={4}
        m="0 auto"
      >
        {user.twoFa && (
          <Box
            style={{ paddingLeft: 30, paddingTop: 30 }}
            position="absolute"
            top="0"
            left="0"
            sx={{
              a: {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              },
            }}
            onClick={() => resetLogin(dispatch)}
          >
            <Link to="/login">
              <ChevronLeft />
              <Text textStyle="h6" fontWeight="700">
                Back to Login
              </Text>
            </Link>
          </Box>
        )}
        {!user.twoFa ? (
          <>
            <Text textStyle="h2">Login to your Account</Text>
            <Text textStyle="h4" color="grey.700">
              Enter your credentials to login or simply choose one from existing
              accounts
            </Text>
            <FormControl
              w="full"
              as="form" // Add the form element here
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
            >
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  value={form.email}
                  placeholder="Enter email address"
                  onChange={handleChange}
                />
              </InputGroup>
              <Text color="red.500" fontSize="sm">
                {errors.email}
              </Text>

              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={form.password}
                  placeholder="Enter Password"
                  onChange={handleChange}
                  pr="2.5rem"
                />
                <InputRightElement onClick={handleTogglePassword}>
                  {showPassword ? (
                    <HideViewIcon sx={{ color: "var(--grey-500)" }} />
                  ) : (
                    <ViewIcon sx={{ color: "var(--grey-500)" }} />
                  )}
                </InputRightElement>
              </InputGroup>
              <Text color="red.500" fontSize="sm">
                {errors.password}
              </Text>
            </FormControl>
            <HStack w="full" justify="space-between">
              {/* <Checkbox
              color="grey.700"
              variant="whiteCheck"
              textStyle="h6"
              defaultChecked
            >
              Keep me Signed in
            </Checkbox> */}
              <Link to="/forget-password">
                <Text textStyle="h6">Forgot Password</Text>
              </Link>
            </HStack>
            <Button size="xl" w="full" mt={4} onClick={handleSubmit}>
              Login
            </Button>
            <Button
              variant="outline"
              size="xl"
              w="full"
              leftIcon={<GoogleIcon />}
              onClick={() => {
                handleGoogleAuth("google");
              }}
            >
              Sign in with Google
            </Button>
            {/* <Button
              variant="outline"
              size="xl"
              w="full"
              leftIcon={<FacebookIcon />}
              onClick={() => {
                handleGoogleAuth("facebook");
              }}
            >
              Sign in with Facebook
            </Button> */}

            <SupportButtons></SupportButtons>
          </>
        ) : user?.medium && user?.medium === "both" ? (
          <SelectTwoFa email={form.email} />
        ) : (
          <TwoFA email={form.email} medium={user?.medium} />
        )}
        {user?.loading && <Spinner />}
      </VStack>
    </VStack>
  );
};

export default LoginPage;
