import {
  Button,
  DrawerFooter,
  VStack,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  suspendOrRestoreDPUser,
  suspendOrRestoreDealershipUser,
} from "../../../utils/api/users.api";
import { Drawer, Select } from "../../common";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AsyncSelect from "react-select/async";
import { useState } from "react";
import { loadUsers } from "../../AccountSettings/LeadCenterSettings/utils";
import DebouncedAsyncSelect from "../../common/DebouncedAsyncSelect";

type data = {
  isOpen: boolean;
  onClose: any;
  userId: number;
  dealershipId?: string;
  refetchParent: any;
  selectedUser?: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  status: string;
  suspend_option: string;
  suspend_option_users: any;
}

const suspendUserOptions = [
  {
    value: "leave",
    label: "Leave this person's contacts without a Point Person",
  },
  { value: "shuffle", label: "Shuffle between one or multiple users" },
];

const SuspendDPUser = (props: data) => {
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, userId, refetchParent, dealershipId, selectedUser } =
    props;
  const toast = useToast();

  console.log("props", props);

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request: ApiRequest): Promise<ApiResult> => {
      if (dealershipId) {
        try {
          const response = await suspendOrRestoreDealershipUser(
            userId,
            dealershipId,
            request,
            {
              menu: "User",
              "Sub-Menu": "Dealership",
              Dealership: dealershipId,
            }
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await suspendOrRestoreDPUser(
            userId,
            dealerId?.id,
            request,
            {
              menu: "User",
              "Sub-Menu": "Dealership",
              Dealership: dealerId?.id,
            }
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "User suspended successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error suspending user: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {
      status: "suspended",
      suspend_option: selectedOption,
      suspend_option_users: selectedUsers?.map((user: any) => user.value) || [],
    };

    mutation.mutate(requestData);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title={"Suspend User"}>
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to suspend the user?
        </Text>

        <Select
          label="Suspend Option"
          onChange={(value: any) => setSelectedOption(value.value)}
          placeholder="Select Suspend Option"
          options={suspendUserOptions}
          variant="default"
          w="100%"
        />

        {selectedOption === "shuffle" && (
          <Box marginLeft="1rem" width="98%">
            <Text textStyle="h4" marginBottom="5px">
              The selected users will get the contacts of this user (which we
              are suspending) among the users of the same role (e.g. Sales
              Person) in a round robin fashion
            </Text>
            <DebouncedAsyncSelect
              loadOptions={(searchStr: string) =>
                loadUsers(
                  searchStr,
                  dealershipId || dealerId?.id,
                  selectedUser?.access_level || selectedUser?.accessLevel,
                  !dealershipId
                )
              }
              defaultOptions
              value={selectedUsers}
              onChange={(value: any) => {
                setSelectedUsers(value);
              }}
              filterOption={(option: any) => Number(option.value) !== Number(userId)}
              isMulti={true}
              placeholder="Select Users"
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  maxWidth: "100%",
                }),
              }}
            />
          </Box>
        )}
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default SuspendDPUser;
