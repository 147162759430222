import * as yup from "yup";
import { EMAIL_REGEX } from "../../../config";
import { fetchDispositionDS } from "../../../utils/api/disposition-settings.api";
import {
  fetchLeadSource,
  fetchTags,
} from "../../../utils/api/global-config.api";
import { getInfiniteLines } from "../../../utils/api/line-rings.api";
import {
  fetchDealershipUserById,
  fetchUsersDP,
} from "../../../utils/api/users.api";

export type LeadCenterSettingsForm = {
  priorities: {
    start: number;
    end: number;
  }[];
  crm_intake_email: string;
  xml_email_recipients: {
    emails: string[];
    appt: boolean;
    leads: boolean;
    lead_source: string[];
  };
  text_email_recipients: {
    emails: string[];
    appt: boolean;
    leads: boolean;
    lead_source: string[];
  };
  leads_distribution: {
    option: string;
    assign_specific_user: {
      select_lead_source: string[];
      assign_leads: string[];
    }[];
  };
};

export const defaultFilterValues = {
  start: 0,
  end: 0,
};

export const defaultValues: LeadCenterSettingsForm = {
  priorities: new Array(3).fill(defaultFilterValues),
  crm_intake_email: "",
  xml_email_recipients: {
    emails: [],
    appt: false,
    leads: false,
    lead_source: [],
  },
  text_email_recipients: {
    emails: [],
    appt: false,
    leads: false,
    lead_source: [],
  },
  leads_distribution: {
    option: "",
    assign_specific_user: [{ select_lead_source: [], assign_leads: [] }],
  },
};

export const leadCenterSettingsValidation: any = yup.object().shape({
  priorities: yup.array().of(
    yup.object().shape({
      start: yup
        .number()
        .typeError("Start is required.")
        .required("Start is required.")
        .min(1, "Start must be equal or greater than 1"),
      end: yup
        .number()
        .typeError("End is required.")
        .required("End is required.")
        .min(1, "End must be equal or greater than 1")
        .moreThan(yup.ref("start"), "End must be greater than Start."),
    })
  ),
  crm_intake_email: yup.string().nullable().matches(EMAIL_REGEX, {
    message: "Invalid Email",
    excludeEmptyString: true,
  }),
  // leads_distribution: yup.object().shape({
  //   option: yup.string().nullable(),
  //   assign: yup.boolean().nullable(),
  //   assign_specific_user: yup.array().of(
  //     yup.object().shape({
  //       select_lead_source: yup
  //         .array()
  //         .of(
  //           yup.object().shape({
  //             value: yup.string(),
  //           })
  //         )
  //         .test(
  //           "leadSource",
  //           "Please select at least one Lead Source.",
  //           function (value) {
  //             return (this as any).from[1].value.option
  //               ? (this as any).from[1].value.assign
  //                 ? this.parent.select_lead_source?.length
  //                 : true
  //               : true;
  //           }
  //         ),
  //       assign_leads: yup
  //         .array()
  //         .of(
  //           yup.object().shape({
  //             value: yup.string(),
  //           })
  //         )
  //         .test(
  //           "leadSource",
  //           "Please select at least one Lead.",
  //           function (value) {
  //             return (this as any).from[1].value.option
  //               ? (this as any).from[1].value.assign
  //                 ? this.parent.assign_leads?.length
  //                 : true
  //               : true;
  //           }
  //         ),
  //     })
  //   ),
  // }),
});

export const loadLeadSource = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchLeadSource(
      { q: searchStr, per_page: 10000 },
      id
    );
    const options = response?.lead_sources?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadUsers = async (
  searchStr: string,
  id?: string,
  role_titles = "Sales Person",
  isDealership = false
) => {
  try {
    const response = await (isDealership
      ? fetchUsersDP({
          searchValue: searchStr,
          dealership_id: id,
          status: "active",
          role_titles,
        })
      : fetchDealershipUserById({
          role_titles,
          searchValue: searchStr,
          id: id,
          status: "active",
          page: 1,
          per_page: 10000,
        }));
    const options = response?.users?.map((user: any) => ({
      value: user.id,
      label: user.full_name,
    }));

    return options?.filter(
      (option: any) => !["Sarah AI", "SimpSocial Engage"].includes(option.label)
    );
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadTags = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchTags({ q: searchStr }, id);
    const options = response?.tags?.map((tag: any) => ({
      value: tag.id,
      label: tag.name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadDispositionSettings = async (
  searchStr: string,
  id?: string
) => {
  try {
    const response = await fetchDispositionDS(id || "", { q: searchStr });
    const options = response?.contact_dispositions?.map(
      (contactDisposition: any) => ({
        value: contactDisposition.id,
        label: contactDisposition.name,
      })
    );

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadLines = async (searchStr: string, id?: string) => {
  try {
    const response = await getInfiniteLines({ dealershipId: id, searchStr });
    const options = response?.data?.lines?.map((line: any) => ({
      value: line.id,
      label: line.name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const priorityMapping: Record<number, string> = {
  0: "priority_one_follow_up",
  1: "priority_two_follow_up",
  2: "priority_three_follow_up",
};

export const countDifferentAttributes = <T>(
  otherObject: T,
  defaultValues = defaultFilterValues as T
) => {
  let count = 0;

  function compareObjects(obj1: any, obj2: any) {
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        // Special case for date_range: treat it as a single unit
        if (key === "date_range") {
          const { start_date: start1, end_date: end1 } = obj1[key] || {};
          const { start_date: start2, end_date: end2 } = obj2[key] || {};

          if (
            (start1 || "") !== (start2 || "") ||
            (end1 || "") !== (end2 || "")
          ) {
            count++; // Count date_range as one difference
          }
          continue;
        }

        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          // Convert arrays to JSON strings for deep comparison
          if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            count++;
          }
        } else if (
          typeof obj1[key] === "object" &&
          typeof obj2[key] === "object"
        ) {
          compareObjects(obj1[key], obj2[key]); // Recursively compare objects
        } else if (
          !(
            obj1[key] === undefined &&
            Array.isArray(obj2[key]) &&
            obj2[key].length === 0
          ) &&
          obj1[key] !== obj2[key]
        ) {
          count++;
        }
      }
    }
  }

  compareObjects(defaultValues, otherObject);
  return count;
};
