import { HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  getactiveBDCCampaigns,
  getactiveUserBDCCampaigns,
  userCompaignStatus,
} from "../../../../../utils/api/bdc-campaign.api";
import Popup from "../../../../Charts/Popup";
import { Input } from "../../../../common";
import CustomDrawer from "../../../../common/Drawer";
import SwitchToggle from "../../../../common/Switch";
import { SearchIcon } from "../../../../icons/svg";
export const EnrolledCampaignDrawer = ({
  isOpen,
  onClose,
  clickedRow,
  dealership_id,
}: any) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [search, setSearch] = useState("");
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: false,
    id: "",
  });
  const { data } = useQuery({
    queryKey: ["campaignbyid", dealership_id, search],
    queryFn: getactiveBDCCampaigns,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: activecampaigns, refetch } = useQuery({
    queryKey: ["activecampaigns", dealership_id, clickedRow?.id, 1, 10, ""],
    queryFn: getactiveUserBDCCampaigns,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const mutation = useMutation(userCompaignStatus);
  const toast = useToast();
  const updateStatus = (campid: any, value: any) => {
    mutation?.mutate(
      {
        id: dealership_id,
        contactid: clickedRow?.id,
        payload: {
          campaign_id: campid,
          enroll: value,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          onClosePopupOpen();
          toast({
            description: "Campaign updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <CustomDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Enroll/Disenroll from Campaigns"
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <HStack
          justifyContent="space-between"
          padding="1rem"
          background="var(--secondary-50)"
          borderRadius="0.5rem"
          w="29rem"
        >
          <VStack alignItems="flex-start" gap="0.38rem">
            <Text fontSize="1.5rem" fontWeight="600">
              {clickedRow.first_name} {clickedRow?.last_name}
            </Text>
            <Text fontSize="1rem" fontWeight="400" color="var(--grey-900)">
              {clickedRow?.phone_number}
            </Text>
            <Text fontSize="1rem" fontWeight="400" color="var(--grey-900)">
              {clickedRow?.email}
            </Text>
          </VStack>

          {/* <Box
          borderRadius="2.5rem"
          padding="0.25rem 0.625rem"
          background="#EDE7F6"
          color="#9C27B0"
          fontSize="0.75rem"
          fontWeight="500"
        >
          Wireless
        </Box> */}
        </HStack>

        <Text color="#EF6C00" fontWeight="500">
          Enroll/Disenroll
        </Text>
        <Input
          maxW="100%"
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Search Campaign here...."
          onChange={(e) => setSearch(e.target.value)}
        />

        <VStack
          // padding="1rem"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          gap="0"
          w="100%"
          alignItems="flex-start"
        >
          <HStack justifyContent="space-between" w="100%" padding="0.75rem">
            <Text color="var(--primary-600)">All Campaigns</Text>
            {/* <HStack>
            <SwitchToggle defaultChecked={true} />
            <Box
              background="var(--grey-300)"
              borderRadius="50%"
              padding="0.25rem"
              sx={{
                svg: {
                  width: "0.75rem",
                  height: "0.75rem",
                },
              }}
            >
              <PauseIcon color="white" />
            </Box>
          </HStack> */}
          </HStack>
          {data?.data?.map((item: any) => (
            <HStack
              justifyContent="space-between"
              w="100%"
              borderTop="1px solid var(--grey-300)"
              padding="0.75rem"
            >
              <Text textStyle="h6" fontWeight="500">
                {item?.name}
              </Text>
              <HStack>
                <SwitchToggle
                  defaultChecked={
                    !!activecampaigns?.data?.find(
                      (camp: any) => camp?.id === item?.id
                    )
                      ? true
                      : false
                  }
                  onChange={(check: boolean) => {
                    setPopup({
                      name: `Are you sure you want to ${
                        check ? "enroll to" : "disenroll from"
                      } this campaign?`,
                      description: `By confirming, this contact will be ${
                        check ? "added to" : "removed from"
                      } this campaign`,
                      value: check,
                      id: item?.id,
                    });
                    onOpenPopupOpen();
                  }}
                />
              </HStack>
            </HStack>
          ))}
        </VStack>
        {isPopupOpen && (
          <Popup
            isOpen={isPopupOpen}
            onClose={onClosePopupOpen}
            onConfirm={() => updateStatus(popup?.id, popup?.value)}
            title={popup.name}
            message={popup.description}
            loading={mutation?.isLoading}
          />
        )}
      </VStack>
    </CustomDrawer>
  );
};
