export const tableHeader = [
  { id: 2, value: "Campaign Category", label: "campaignCategory" },
  { id: 3, value: "Total Calls", label: "totalCalls" },
  { id: 4, value: "Completed Calls", label: "completedCalls" },
  { id: 5, value: "Email Sent", label: "emailSent" },
  { id: 6, value: "Email Replies", label: "emailReplies" },
  { id: 7, value: "Texts Sent", label: "textSent" },
  { id: 8, value: "Text Replies", label: "textReplies" },
];

export const PowerDialerHeader = [
  { id: 2, value: "Users", label: "users" },
  { id: 3, value: "Outbound Calls", label: "outboundCalls" },
  { id: 4, value: "Value Calls", label: "valueCalls" },
  { id: 5, value: "Appointment Set", label: "apptSet" },
  { id: 6, value: "Avg Time Spent", label: "avgTimeSpent" },
];

export const BroadcastHeader = [
  { id: 2, value: "Broadcast Name", label: "broadcastName" },
  { id: 3, value: "Total Messages", label: "totalMessages" },
  { id: 4, value: "Total Replies", label: "totalReplies" },
  { id: 5, value: "Percentage Engaged", label: "percentageEngaged" },
  { id: 6, value: "Opt Outs", label: "optOuts" },
];
export const RVMHeader = [
  { id: 1, value: "Campaign Name", label: "campaign_name" },
  { id: 2, value: "Campaign Time", label: "campaign_time" },
  { id: 3, value: "Total Voicemails", label: "total_voicemails" },
  { id: 4, value: "Failed Voicemails", label: "failed_voicemails" },
];

export const UserHeader = [
  { id: 1, value: "User's Name", label: "name" },
  { id: 2, value: "Outbound Calls", label: "outboundCalls" },
  {
    id: 3,
    value: "Outbound Completed Calls",
    label: "outboundCompletedCalls",
  },
  {
    id: 4,
    value: "Outbound Call Engagement",
    label: "outboundCallEngagement",
  },
  // { id: 5, value: "Inbound Completed Calls", label: "inboundCompletedCalls" },
  { id: 6, value: "Outbound Messages", label: "outboundMessages" },
  { id: 7, value: "Inbound Messages", label: "inboundMessages" },
  {
    id: 8,
    value: "Messages Engagement Rate",
    label: "messagesEngagementRate",
  },
  { id: 9, value: "Outbound Emails", label: "outboundEmails" },
  { id: 10, value: "Appointments Set", label: "appointmentsSet" },
  { id: 11, value: "Appointments Shown", label: "appointmentsShown" },
  { id: 12, value: "Lead Response Time", label: "leadResponseTime" },
];

export const reportNames: Record<number, string> = {
  0: "bdc_campaign",
  1: "power_dialer",
  3: "users",
};
