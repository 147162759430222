import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { filterByUniqueKey } from "../../../utils";
import { sendSMS } from "../../../utils/api/communication.api";
import { uploadSmsImage } from "../../../utils/api/lead.api";
import { useHistoryDispatch } from "../../../utils/context/HistoryContext";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AttachmentBar from "../../AttachmentBar";
import { Input, PhoneInput, Select } from "../../common";
import AttachmentViewer from "../../common/AttachmentViewer";
import Loader from "../../common/Spinner";
import { CloseIcon } from "../../icons/svg";
import ScheduleModal from "./ScheduleModal";
import { SendSmsForm, defaultValues, sendSmsFormValidation } from "./utils";
import { useBreadcrumb } from "../../../utils/context/BreadCrumsContext/BreadcrumbContext";

type SmsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  clickedRow: any;
};

function SmsDrawer({ isOpen, onClose, clickedRow }: SmsDrawerProps) {
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const { setDisableOnErrorRes } = useBreadcrumb();

  const { handleSubmit, control, setValue, trigger, getValues } =
    useForm<SendSmsForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(sendSmsFormValidation) as any,
    });

  const dispatch = useTwilioDispatch();
  const { openSMS, smsParams } = useTwilioState();
  const { devices } = useTwilioState();
  const lineDevices = filterByUniqueKey(
    devices.filter((device) => device.phoneNumber),
    "phoneNumber"
  );

  const user = useUserInfo("user");

  useEffect(() => {
    if (openSMS) {
      setValue("to", smsParams.called);
      setValue("from", smsParams.caller);
    }
  }, [openSMS, setValue, smsParams.called, smsParams.caller]);

  const toast = useToast();
  const historyDispatch = useHistoryDispatch();

  useEffect(() => {
    const defaultLine =
      lineDevices?.find((line) => line.is_default)?.phoneNumber ||
      lineDevices?.[0].phoneNumber;
    setValue("from", defaultLine);
  }, [lineDevices, setValue]);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await sendSMS(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Message Sent Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
      setIsScheduleModalOpen(false);
      historyDispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
      dispatch({ type: "OPEN_SMS", payload: { openSMS: false } });
    },
    onError: (error: any) => {
      if(error?.response?.data?.errors === "The Contact is DNC") {
        setDisableOnErrorRes(true);
      }

      toast({
        description: `Error Sending SMS: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  useEffect(() => {
    if (clickedRow && !openSMS) {
      setValue("to", clickedRow.phone_number);
    }
  }, [clickedRow, openSMS, setValue]);

  const handleFormSubmit = (values: SendSmsForm) => {
    if(values.messageBody.length > 1600){
      return toast({
        description: "Maximum messages limit reached",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    mutation.mutate({
      to: values.to,
      from: values.from,
      contact_id: clickedRow.id,
      content: values.messageBody,
      dealership_id: user.dealership.id,
      user_id: user.id,
      media_urls: attachments.map((attachment: any) => attachment.url),
    });
  };

  const handleDrawerClose = () => {
    dispatch({ type: "OPEN_SMS", payload: { openSMS: false } });
    onClose();
  };

  const handleSchedule = (date: Date) => {
    const values = getValues();
    mutation.mutate({
      to: values.to,
      from: values.from,
      contact_id: clickedRow.id,
      content: values.messageBody,
      dealership_id: user.dealership.id,
      user_id: user.id,
      is_scheduled: true,
      scheduled_at: date.toUTCString(),
      attachment_urls: attachments,
    });
  };

  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await uploadSmsImage({ payload });
        setAttachments((value) => [...value, response.data]);
        return response.data.url;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("dealership_id", user.dealership.id);
      e.target.value = "";

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        attachmentMutation.mutate(formData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const editorRef = useRef();

  const handleSetBody = (text: string, concatText?: string, type = "text") => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;

        // Insert the emoji at the current cursor position
        editor.insertText(cursorPosition, text);

        // Move the cursor to the end of the inserted emoji
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachments((prev: any) => [...prev, text]);
    }
  };

  return (
    <Drawer
      isOpen={isOpen || openSMS}
      onClose={handleDrawerClose}
      placement="bottom"
      id="email-drawer"
    >
      <DrawerContent
        padding="0"
        borderTopRightRadius="0.5rem"
        borderTopLeftRadius="0.5rem"
        sx={{ overflowX: "hidden" }}
        >
        {mutation.isLoading && <Loader />}
        {attachmentMutation.isLoading && <Loader />}
        <DrawerBody padding="0" sx={{ overflowX: "hidden" }}>
          <VStack w="100%" alignItems="flex-start" gap="0">
            <HStack
              justifyContent="space-between"
              w="100%"
              background="var(--secondary-600)"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              padding="0.69rem 1rem"
            >
              <Text color="white" textStyle="h5" fontWeight="600">
                SMS Center
              </Text>
              <HStack>
                <Button
                  variant="none"
                  padding="0.38rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  onClick={handleDrawerClose}
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </HStack>
            </HStack>
            <VStack
              w="100%"
              alignItems="flex-start"
              gap="0"
              className="smsCenterBody"
            >
              <HStack w="100%" padding="0 1rem" gap="0">
                <Text
                  textStyle="h6"
                  fontWeight="600"
                  w="20%"
                  color=" var(--grey-500)"
                >
                  From
                </Text>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Select
                        onChange={(value: any) => setValue("from", value.value)}
                        placeholder="Select Line"
                        options={
                          lineDevices?.map((lineDevice) => ({
                            value: lineDevice.phoneNumber,
                            label: lineDevice.phoneNumber,
                          })) || []
                        }
                        variant="default"
                        w="100%"
                        value={value}
                        error={error?.message}
                        parentStyles={{
                          width: "103%",
                          border: "none",
                          borderLeft: 0,
                          borderRadius: 0,
                          borderBottom: error?.message
                            ? "1px solid var(--grey-300)"
                            : "none",
                          boxShadow: "none",
                          "&:hover": {
                            borderLeft: 0,
                          },
                        }}
                        boxStyles={{ borderLeft: "1px solid var(--grey-300)" }}
                      />
                    );
                  }}
                  name="from"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </HStack>
              <HStack
                w="100%"
                padding="0 1rem"
                gap="0"
                borderTop="1px solid var(--grey-300)"
              >
                <Text
                  textStyle="h6"
                  fontWeight="600"
                  w="20%"
                  color=" var(--grey-500)"
                >
                  To
                </Text>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <PhoneInput
                        styles={{
                          border: "0px",
                          borderRadius: 0,
                          borderBottom: error?.message
                            ? "1px solid var(--grey-300)"
                            : "0px",
                          width: "105%",
                        }}
                        containerStyles={{
                          borderLeft: "1px solid var(--grey-300)",
                          w: "100%",
                        }}
                        label=""
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        isRequired
                      />
                    );
                  }}
                  name="to"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </HStack>

              <AttachmentBar
                type="smsDrawer"
                setBody={(text, concatText, type = "text") => {
                  if (type === "text") {
                    const formattedLink = `${text}`;
                    const messagesText = `${getValues("messageBody")} ${
                      concatText ? `${concatText}: ` : ""
                    }${formattedLink}`;
                    setValue("messageBody", messagesText);
                  } else {
                    setAttachments((prev) => [...prev, text]);
                  }
                }}
                handleTemplate={(value: string, body, attachmentUrls) => {
                  setValue("messageBody", value);
                  setAttachments(attachmentUrls);
                }}
                contactId={clickedRow?.uuid}
              />

              <Controller
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      isTextarea={true}
                      h="25rem"
                      value={value}
                      onChange={onChange}
                      borderRadius="0"
                      _focusVisible={{
                        outline: "none",
                      }}
                      error={error?.message}
                      showCharCount
                      isTranslatable
                    />
                  );
                }}
                name="messageBody"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Box className="attachmentViewerWrapper" w="100%">
                <AttachmentViewer
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </Box>
              <HStack w="100%" padding="1rem" background="var(--grey-200)">
                <Button
                  variant="outline"
                  flex="1"
                  onClick={async () => {
                    const isValid = await trigger();

                    isValid && setIsScheduleModalOpen(true);
                  }}
                >
                  <HStack>
                    <Text>Schedule</Text>
                  </HStack>
                </Button>
                <Button
                  flex="1"
                  onClick={() =>
                    handleSubmit((values) => handleFormSubmit(values))()
                  }
                >
                  Send Now
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>

      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
        handleSchedule={handleSchedule}
      />
    </Drawer>
  );
}

export default SmsDrawer;
