import React, { useEffect, useState } from "react";
import {
  Checkbox,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import SpiralCalendarImg from "../../../assets/images/SpiralCalendar.svg";
import SmallCalendar from "../../../components/SmallCalendar/SmallCalendar";
import { CreateAppointment } from "../../../components/MeetingCalendar/CreateAppointment";
import { AppointmentDetail } from "../../../components/MeetingCalendar/AppointmentDetail";
import { useQuery } from "@tanstack/react-query";
import {
  getMyAppointments,
  getDealershipAppointments,
} from "../../../utils/api/meeting.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { CommonCalendar } from "../../../components/MeetingCalendar/CommonCalendar";
import moment from "moment";
import {
  extractTime,
  calculateStartDate,
  calculateEndDate,
  humanize,
  formatTimeTo12Hour,
} from "../../../utils";
import Loader from "../../../components/common/Spinner";
import { useLocation } from "react-router-dom";

export default function MeetingCalendarPage() {
  const location = useLocation();
  const dealership = useUserInfo("dealership");
  const [checkboxes, setCheckboxes] = useState([
    {
      label: "Appointment Set",
      variant: "whiteCheckPrimary",
      checked: true,
      value: "appointment_set",
    },
    {
      label: "Shown Meetings",
      checked: true,
      variant: "whiteCheckGreen",
      value: "shown_meeting",
    },
    {
      label: "Appointment Missed",
      checked: true,
      variant: "whiteCheckOrange",
      value: "appointment_missed",
    },
    {
      label: "Service Appointment Set",
      checked: true,
      variant: "whiteCheckBabyBlue",
      value: "service_appointment_set",
    },
    {
      label: "Service Appointment Shown",
      checked: true,
      variant: "whiteCheckBabygreen",
      value: "service_appointment_shown",
    },
    {
      label: "Service Appointment Missed",
      checked: true,
      variant: "whiteCheckLightorange",
      value: "service_appointment_missed",
    },
    {
      label: "Scheduled Reminder",
      checked: true,
      variant: "whiteCheckYellow",
      value: "scheduled_reminder",
    },
    {
      label: "Completed Reminder",
      checked: true,
      variant: "whiteCheckBabyBlue",
      value: "completed_reminder",
    },
    {
      label: "Missed Reminders",
      checked: true,
      variant: "whiteCheckGray",
      value: "missed_reminder",
    },
  ]);
  const handleCheckboxChange = (index: number) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = !newCheckboxes[index].checked;
    setCheckboxes(newCheckboxes);
  };
  const state = location.state || {};
  const { personal = null, reminder_id = null } = state;
  const [myCalendar, setMyCalendar] = useState(
    personal !== null ? personal : false
  );
  const [selectedID, setSelectedID] = useState();
  const [contactID, setContactID] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const [eventsData, setEventsData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [slotData, setSlotData] = useState<any>(null);
  const [participant, setParticipant] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    moment.utc().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc().endOf("month").add(1, "day").format("YYYY-MM-DD")
  );
  const [view, setView] = useState("month");
  const onDateRangeChange = (startDate: any, endDate: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  useEffect(() => {
    if (reminder_id) {
      setSelectedID(reminder_id);
      onOpenDetail();
    }
  }, [reminder_id]);
  const viewSetter = (data: string) => {
    setView(data);
  };
  const userFilter = (id: any) => {
    setParticipant(id?.value);
  };
  const { isFetching, refetch } = useQuery({
    queryKey: [
      "Appointments",
      dealership?.id,
      checkboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value),
      startDate,
      endDate,
      participant,
      myCalendar,
    ],
    queryFn: myCalendar ? getMyAppointments : getDealershipAppointments,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setEventsData(
        data?.data?.events.map((appointment: any) => ({
          id: appointment.id,
          title: (
            <VStack
              background={colorSetter(appointment?.status)}
              gap="0"
              padding="0.38rem 0.38rem 0.12rem"
              borderRadius="0.5rem"
              alignItems="flex-start"
              h="100%"
            >
              <Text
                textStyle="captionSmall"
                whiteSpace="normal"
                fontWeight="500"
                color={textColorSetter(appointment?.status)}
              >
                {humanize(appointment?.contact?.name)+" - "}
                <span style={{whiteSpace: "nowrap"}}>{formatTimeTo12Hour(appointment?.start_time)}</span>
              </Text>
              {/* <Text whiteSpace="normal" textStyle="captionXS" fontWeight="500">
                {appointment.title}
              </Text> */}
            </VStack>
          ),
          start: new Date(appointment.start_time),
          end: new Date(appointment.end_time),
          contact: appointment?.contact,
        }))
      );
    },
  });
  const colorSetter = (val: any) => {
    if (val === "appointment_set") {
      return "var(--primary-600)";
    }
    if (val === "appointment_missed") {
      return "var(--orange-800)";
    }
    if (val === "shown_meeting") {
      return "var(--secondary-500)";
    }
    if (val === "completed_reminder") {
      return "var(--primary-200)";
    }
    if (val === "missed_reminder") {
      return "var(--bluegray-600)";
    }
    if (val === "scheduled_reminder") {
      return "var(--yellow-default)";
    }

    if (val === "service_appointment_set") {
      return "var(--primary-200)";
    }
    if (val === "service_appointment_shown") {
      return "var(--secondary-200)";
    }
    if (val === "service_appointment_missed") {
      return "var(--orange-300)";
    }
  };

  const textColorSetter = (val: any) => {
    if (val === "scheduled_reminder") {
      return "var(--grey-900)";
    }
    if (val === "completed_reminder") {
      return "var(--grey-900)";
    }
    if (val === "missed_reminder") {
      return "var(--grey-900)";
    }
    if (val === "appointment_missed") {
      return "white";
    }
    if (val === "service_appointment_missed") {
      return "var(--grey-900)";
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date); // Update selected date when it changes
    const startDate = calculateStartDate(date, view);
    const endDate = calculateEndDate(date, view);
    onDateRangeChange(startDate, endDate);
  };
  const onSelectSlot = (event: any) => {
    if (event) {
      let { start, end } = event;
      let startTime = start?.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      if (startTime === "24:00") {
        startTime = "00:00";
      }
      let endTime = end?.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      const year = start?.getFullYear();
      const month = String(start?.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns 0-indexed month
      const day = String(start?.getDate()).padStart(2, "0");
      const date = `${year}-${month}-${day}`;

      setSlotData({
        start_time: startTime,
        endTime,
        date,
        type: { value: "reminder" },
      });
    } else {
      setSlotData({ type: { value: "reminder" } });
    }
    onOpen();
  };
  const onSelectEvent = (event: any) => {
    setSelectedID(event.id);
    setContactID(event?.contact?.id);
    onOpenDetail();
  };
  const editMeeting = (data: any) => {
    const modifiedData = {
      ...data,
      date: data?.start_time?.substring(0, 10),
      start_time: extractTime(data?.start_time),
      type: { value: data?.event_type },
      userid: data?.participants?.map((user: any) => ({
        value: user.id,
        label: user.full_name,
      })),
    };
    setSlotData(modifiedData);
    setEditMode(true);
    onOpen();
  };
  const handleClose = () => {
    onClose();
    setEditMode(false);
    onCloseDetail();
    setContactID("");
  };
  return (
    <VStack
      padding="1.5rem"
      w="100%"
      alignItems="flex-start"
      borderRadius="0.5rem"
    >
      <Tabs variant="table" bg="white" w="100%" index={myCalendar ? 0 : 1}>
        <TabList>
          <Tab w="17rem" onClick={() => setMyCalendar(true)}>
            My Calendar
          </Tab>
          <Tab w="17rem" onClick={() => setMyCalendar(false)}>
            Store Calendar
          </Tab>
        </TabList>
        <TabPanels w="100%">
          <TabPanel w="100%">
            <HStack
              w="100%"
              alignItems="stretch"
              gap="0"
              sx={{
                ".rbc-calendar .rbc-time-header-content .rbc-time-header-cell .rbc-header .rbc-button-link span:first-letter":
                  {
                    color: "red",
                  },
              }}
            >
              <VStack w="21rem" h="100%" alignItems="flex-start">
                <SmallCalendar
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <VStack
                  alignItems="flex-start"
                  w="100%"
                  padding="0 0.75rem"
                  gap="0.75rem"
                >
                  <HStack
                    gap="0.5rem"
                    mt="1.5rem"
                    mb="0.25rem"
                    padding="0.75rem 0"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    <Image src={SpiralCalendarImg} alt="Spiral Calendar" />
                    <Text textStyle="h6" fontWeight="600">
                      My Calendars
                    </Text>
                  </HStack>
                  {checkboxes.map((checkbox, index) => (
                    <Checkbox
                      key={index}
                      size="lg"
                      variant={checkbox.variant}
                      defaultChecked={checkbox.checked}
                      onChange={() => handleCheckboxChange(index)}
                    >
                      {checkbox.label}
                    </Checkbox>
                  ))}
                </VStack>
              </VStack>
              <CommonCalendar
                eventsData={eventsData}
                onSelectSlot={onSelectSlot}
                selectedDate={selectedDate}
                onSelectEvent={onSelectEvent}
                handleDateChange={handleDateChange}
                onDateRangeChange={onDateRangeChange}
                viewSetter={viewSetter}
                userFilter={userFilter}
                selectedUser={participant}
                myCalendar={myCalendar}
                view={view}
              />
            </HStack>
          </TabPanel>
          <TabPanel>
            <HStack
              w="100%"
              alignItems="stretch"
              gap="0"
              sx={{
                ".rbc-calendar .rbc-time-header-content .rbc-time-header-cell .rbc-header .rbc-button-link span:first-letter":
                  {
                    color: "red",
                  },
              }}
            >
              <VStack w="21rem" h="100%" alignItems="flex-start">
                <SmallCalendar
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <VStack
                  alignItems="flex-start"
                  w="100%"
                  padding="0 0.75rem"
                  gap="0.75rem"
                >
                  <HStack
                    gap="0.5rem"
                    mt="1.5rem"
                    mb="0.25rem"
                    padding="0.75rem 0"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    <Image src={SpiralCalendarImg} alt="Spiral Calendar" />
                    <Text textStyle="h6" fontWeight="600">
                      My Calendars
                    </Text>
                  </HStack>
                  {checkboxes.map((checkbox, index) => (
                    <Checkbox
                      key={index}
                      size="lg"
                      variant={checkbox.variant}
                      defaultChecked={checkbox.checked}
                      onChange={() => handleCheckboxChange(index)}
                    >
                      {checkbox.label}
                    </Checkbox>
                  ))}
                </VStack>
              </VStack>
              <CommonCalendar
                eventsData={eventsData}
                onSelectSlot={onSelectSlot}
                selectedDate={selectedDate}
                onSelectEvent={onSelectEvent}
                handleDateChange={handleDateChange}
                onDateRangeChange={onDateRangeChange}
                viewSetter={viewSetter}
                userFilter={userFilter}
                selectedUser={participant}
                myCalendar={myCalendar}
                view={view}
              />
            </HStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isOpen && (
        <CreateAppointment
          isOpen={isOpen}
          onClose={handleClose}
          slotData={slotData}
          apiCall={refetch}
          myCalendar={myCalendar}
          editMode={editMode}
          leadReminder={slotData?.event_type === "reminder" && contactID}
          contactid={contactID}
        />
      )}
      {isOpenDetail && (
        <AppointmentDetail
          isOpen={isOpenDetail}
          onClose={() => {
            onCloseDetail();
            setContactID("");
          }}
          myCalendar={myCalendar}
          selectedID={selectedID}
          contactid={contactID}
          editMeeting={editMeeting}
          apiCall={refetch}
        />
      )}
      {isFetching && <Loader />}
    </VStack>
  );
}
