import { Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMutation } from "@tanstack/react-query";
import { markEmailAsRead } from "../../../utils/api/header-notifications.api";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import EmailCard from "./EmailCard";

interface EmailProps {
  onOpen: (val: string, count: number) => void;
  onClose: () => void;
  count: number;
  unreadCount: number;
}

const Emails = ({ onOpen, onClose, count, unreadCount }: EmailProps) => {
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const mutation = useMutation(markEmailAsRead);
  const toast = useToast();
  const { refetchNotifications } = useNotificationContext();
  const handleMarkAllAsRead = () => {
    mutation.mutate(
      {
        dId: dealerId?.id,
        payload: "read_all=true",
      },
      {
        onSuccess: (data: any) => {
          onClose();
          refetchNotifications(); // This will trigger the refetch of the parent's query
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack w="100%" alignItems="flex-start" gap="0">
      <HStack w="100%" justifyContent="space-between" padding="1rem">
        <Text textStyle="h5" fontWeight="500">
          All Emails ({unreadCount})
        </Text>
        <HStack alignItems="center">
          {isImpersonating && (
            <Text
              textStyle="h6"
              color="var(--primary-600)"
              fontWeight="500"
              cursor="pointer"
              onClick={() => handleMarkAllAsRead()}
            >
              Mark all as read
            </Text>
          )}
          <Button
            variant="none"
            onClick={() => onOpen("Email", count)}
            display="flex"
          >
            <FullscreenIcon />
          </Button>
        </HStack>
      </HStack>
      <EmailCard page={1} perPage={5} onClose={onClose} />
    </VStack>
  );
};

export default Emails;
