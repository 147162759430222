"use client";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import Analytics from "../../../components/Reports/Analytics/Analytics";
import AverageLeadResponse from "../../../components/Reports/AverageLeadResponse/AverageLeadResponse";
import CommunicationReport from "../../../components/Reports/CommunicationReport/CommunicationReport";
import FloorTrafficAnalysis from "../../../components/Reports/FloorTrafficAnalysis/FloorTrafficAnalysis";
import LeadProviderReport from "../../../components/Reports/LeadProviderReport/LeadProviderReport";
import Leaderboard from "../../../components/Reports/Leaderboard/Leaderboard";
import SoldReport from "../../../components/Reports/SoldReport/SoldReport";

export default function ReportsPage() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const TabsData = [
    // {
    //   id: 1,
    //   title: "Floor Traffic Analysis",
    //   content: <FloorTrafficAnalysis />,
    // },
    { id: 2, title: "Communication Report", content: <CommunicationReport /> },
    // { id: 3, title: "Lead Provider Report", content: <LeadProviderReport /> },
    // { id: 4, title: "Sold Report", content: <SoldReport /> },
    // {
    //   id: 5,
    //   title: "Average Lead Response",
    //   content: <AverageLeadResponse />,
    // },
    // {
    //   id: 6,
    //   title: "Leader Board",
    //   content: <Leaderboard />,
    // },
    // {
    //   id: 7,
    //   title: "Credit & Inventory URL Stats",
    //   content: <CreditAndInventoryURLStats />,
    // },
    // { id: 8, title: "Analytics", content: <Analytics /> },
  ];

  return (
    <>
      <Box
        background="white"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
        // height="100%"
      >
        <Tabs
          variant="vertical"
          display="flex"
          height="100%"
          onChange={(index: any) => setSelectedIndex(index)}
          isLazy
        >
          <TabList>
            <Text
              textStyle="caption"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
            >
              All Reports
            </Text>
            {TabsData.map((item, index) => (
              <Tab
                key={item?.id}
                borderBottom={
                  index === TabsData?.length - 1
                    ? "unset"
                    : "1px solid var(--grey-300)"
                }
              >
                {item?.title}
              </Tab>
            ))}
          </TabList>

          <TabPanels height="calc(100vh - 82px)" overflow="auto">
            {TabsData.map((item, index) => (
              <TabPanel key={item.id} padding="1rem">
                {selectedIndex === index && item.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
