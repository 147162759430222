import { Spinner, Text, VStack } from "@chakra-ui/react";
import moment from "moment";

export type LeadStatCardProps = {
  icon: React.ElementType;
  count: number;
  label: string;
  statKey: string;
  bgColor: string;
  dateRange?: string;
  isClickable: boolean;
  isLoading: boolean;
  onClick: (key: string) => void;
};

const LeadStatCard: React.FC<LeadStatCardProps> = ({
  icon: Icon,
  count,
  label,
  statKey,
  bgColor,
  dateRange,
  isClickable,
  isLoading,
  onClick,
}) => {
  return (
    <VStack
      padding="2rem 1.5rem"
      position="relative"
      flex="1"
      bg={bgColor}
      borderRadius="1rem"
      cursor={isClickable ? "pointer" : "default"}
      height="100%"
      minH="250px"
      pointerEvents={isClickable ? "auto" : "none"}
      onClick={() => isClickable && onClick(statKey)}
      justifyContent="center"
    >
      <Icon boxSize="40px" />

      {isLoading ? (
        <Spinner color="white" size="lg" />
      ) : (
        <Text textStyle="h1" fontWeight="700" color="white">
          {count}
        </Text>
      )}

      <Text textStyle="h4" fontWeight="700" color="white" textAlign="center">
        {label}
      </Text>
      <Text textStyle="h6" fontWeight="500" color="white">
        {dateRange || moment().format("MM/DD/yy")}
      </Text>
    </VStack>
  );
};

export default LeadStatCard;
