import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from "@chakra-ui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AsyncSelect from "react-select/async";
import {
  CustomRadio,
  Drawer,
  Input,
  Select,
  Spinner,
} from "../../../components/common";
import {
  BigCheckIcon,
  CloseIcon,
  GreenDownloadIcon,
  UploadFillIcon,
} from "../../../components/icons/svg";
import { CustomSelect } from "../../../theme/components";
import { fetchTags } from "../../../utils/api/global-config.api";
import {
  downloadSample,
  importContacts,
} from "../../../utils/api/import-contacts.api";
import {
  fetchDealershipUserById,
  loadDPUsersApi,
} from "../../../utils/api/users.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DebouncedAsyncSelect from "../../../components/common/DebouncedAsyncSelect";

export default function ImportContacts(props: any) {
  const { onClose } = props;
  const dealerId = useUserInfo("dealership");
  const { id: dId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);

  //.......................................................
  // step 1
  const [dnc, setDnc] = useState<boolean>(false);

  // step 2
  const [fileName, setFileName] = useState<string>("");

  // step 3
  const [contactType, setContactType] = useState("default");
  const [salesPerson, setSalesPerson] = useState<SelectOptionProps | null>(
    null
  );
  const [bdcAgent, setBDCAgent] = useState<SelectOptionProps | null>(null);
  const [manager, setManager] = useState<SelectOptionProps | null>(null);
  const [tags, setTags] = useState<SelectOptionProps[] | null>(null);
  //.......................................................

  const validFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];
  const maxFileSize = 50 * 1024 * 1024;
  const [file, setFile] = useState(null);
  const toast = useToast();
  const mutationImportContacts = useMutation(importContacts);

  const uploadFileValidation = (file: any) => {
    if (file) {
      if (validFileTypes.includes(file.type) && file.size < maxFileSize) {
        setFile(file);
      } else {
        toast({
          title: "Invalid File",
          description: "Please upload a valid XLSX or CSV file less than 50MB.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };
  const { data, isLoading } = useQuery({
    queryKey: ["fetchTagsForImportContacts", dId, dealerId],
    queryFn: () => fetchTags({}, dId ?? dealerId?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (currentStep === 0) {
      setNextEnabled(dnc);
    }
  }, [currentStep, dnc]);

  useEffect(() => {
    if (currentStep === 1) {
      if (file && fileName) {
        setNextEnabled(true);
      } else {
        setNextEnabled(false);
      }
    }
  }, [currentStep, file, fileName]);

  useEffect(() => {
    if (currentStep === 3) {
      mutationImportContacts.mutate(
        {
          dId: dId ?? dealerId?.id,
          payload: {
            file: file,
            name: fileName,
            contact: {
              contact_type: contactType,
              sales_person_id: salesPerson?.value || "",
              bdc_agent_id: bdcAgent?.value || "",
              manager_id: manager?.value || "",
              tags: tags?.map((item: any) => item.value) || [],
            },
          },
        },
        {
          onSuccess: (data) => {
            setIsSuccess(true);
            toast({
              description: "File processed successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
          onError: (error: any) => {
            setIsSuccess(false);
            toast({
              description: `Error processing file: ${
                error?.response?.data?.errors ?? "Something went wrong"
              }`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  }, [currentStep]);

  const loadActiveDPUsers = async (inputValue: string, role: string) => {
    try {
      const response = await (dId
        ? fetchDealershipUserById({
            id: dId,
            role_titles: role,
            status: "active",
            searchValue: inputValue,
            page: 1,
            per_page: 10,
          })
        : loadDPUsersApi({
            dealership_id: dealerId?.id,
            role_titles: role,
            status: "active",
            q: inputValue,
          }));
      const options = response?.users?.map((user: any) => {
        return {
          value: user.id,
          label: user.full_name,
        };
      });
      return options;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };
  const downloadSampleFile = async () => {
    try {
      setIsFileDownloading(true);
      const response = await downloadSample(dId ?? dealerId?.id);
      setIsFileDownloading(false);
      const blob = new Blob([response?.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sample File.csv";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading", error);
    }
  };

  const importSteps = [
    {
      step: 0,
      content: (
        <VStack w="100%" alignItems="flex-start">
          <VStack
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            background="var(--secondary-50)"
          >
            <Checkbox
              size="lg"
              variant="whiteCheck"
              display="flex"
              alignItems="flex-start !important"
              onChange={(e) => setDnc(e.target.checked)}
              fontSize="0.875rem"
              fontWeight="500"
              color="var(--grey-800)"
              isChecked={dnc}
            >
              <Box
                as="span"
                fontSize="1rem"
                fontWeight="700"
                color="var(--grey-800)"
              >
                DNC Agreement -
              </Box>{" "}
              You verify this list does not contain any phone numbers found in
              the United States DNC (Do not Call) List or your own DNC records
            </Checkbox>
            <Text textStyle="h5" fontWeight="500">
              Organizations that lead phone numbers owners have requested DNC
              status may run the risk of legal action
            </Text>
            <Text textStyle="h6" fontWeight="500">
              To help avoid legal action, scrub all lead s lists before you
              import them using a DNC Checking Service. SimpSocial can assist
              you with this.
            </Text>
          </VStack>
          <VStack padding="1rem" w="100%" alignItems="flex-start">
            <Text textStyle="h4" fontWeight="500">
              You must agree to the DNC Agreement above the Importing
            </Text>
            <Text textStyle="h5" fontWeight="400">
              Want to test, but don’t have a clean list?
            </Text>
            <Text textStyle="h5" fontWeight="400">
              Agree to the terms above, download and then upload this example
              list to test import process
            </Text>
            <Button
              variant="outline"
              gap="0.25rem"
              color="var(--secondary-600)"
              onClick={() => downloadSampleFile()}
            >
              <GreenDownloadIcon />
              <Text>Download Contacts Template</Text>
            </Button>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 1,
      content: (
        <VStack w="100%" alignItems="center" gap="2rem">
          <VStack w="80%">
            <Input
              color="var(--grey-900)"
              isRequired={true}
              label="Batch File Name"
              placeholder="Enter Batch File Name"
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
            ></Input>
            <VStack pt="1rem">
              <Text textStyle="h6" fontWeight="600">
                📢 Upload Restrictions ⚠️
              </Text>
              <UnorderedList textStyle="h6">
                <ListItem>
                  The contact list cannot exceed 75,000 contacts per upload.
                </ListItem>
                <ListItem>
                  Accepted file formats: CSV or Excel files (.csv, .xls, .xlsx).
                </ListItem>
                <ListItem>
                  Ensure your file meets the required format and is error-free
                  to avoid upload issues.
                </ListItem>
              </UnorderedList>
            </VStack>
          </VStack>

          <VStack
            position="relative"
            width="100%"
            gap="1rem"
            textAlign="center"
            w="22rem"
            h="16rem"
            justifyContent="center"
            alignItems="center"
            border="1px dashed var(--secondary-600)"
            borderRadius="0.5rem"
            background="var(--grey-50)"
            mx="auto"
            onDrop={(event: any) => {
              event.preventDefault();
              if (!file) {
                const droppedFile = event.dataTransfer.files[0];
                uploadFileValidation(droppedFile);
              }
              // clear the file so if we upload the same file it appears instead of going blank
              event.target.value = "";
            }}
            onDragOver={(event) => event.preventDefault()}
          >
            <UploadFillIcon />

            <Text textStyle="h5" color="var(--grey-900)" fontWeight="500">
              Drop your Excel or CSV file here or
            </Text>
            <Input
              id="fileInput"
              type="file"
              onChange={(event: ChangeEvent) => {
                const file = event.target.files[0];
                uploadFileValidation(file);
                event.target.value = "";
              }}
              style={{ display: "none" }}
            />
            <Button
              variant="outline"
              color="var(--secondary-600)"
              borderColor="var(--secondary-600)"
              isDisabled={file ? true : false}
              onClick={() => {
                if (document?.getElementById)
                  document?.getElementById("fileInput")?.click();
              }}
            >
              Upload From Computer
            </Button>
            <HStack>
              {file && (
                <>
                  <Text textStyle="h5">{file["name"]}</Text>
                  <Button variant="none" onClick={() => setFile(null)}>
                    <CloseIcon />
                  </Button>
                </>
              )}
            </HStack>
          </VStack>
          <VStack gap=".5rem">
            <Text textStyle="h5">
              To get started with a file template, click link below
            </Text>
            <Button
              variant="outline"
              gap="0.25rem"
              color="var(--secondary-600)"
              onClick={() => downloadSampleFile()}
              mb="1rem"
            >
              <GreenDownloadIcon />
              <Text>Download Contacts Template</Text>
            </Button>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 2,
      content: (
        <VStack w="100%" alignItems="flex-start" padding="0 1.5rem" gap="1rem">
          <VStack
            padding="1rem"
            border="1px solid var(--grey-300)"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            w="100%"
          >
            <CustomRadio
              options={[
                { label: "Enter into New Lead Pool", value: "default" },
                { label: "Priority#1", value: "priority_1" },
                { label: "Priority#2", value: "priority_2" },
                { label: "Priority#3", value: "priority_3" },
                { label: "Leave Out of Priority", value: "no_priority" },
              ]}
              direction="column"
              label={
                <Text textStyle="h4" fontWeight="600">
                  Contact Type
                  <Box as="span" color="var(--red-600)">
                    *
                  </Box>
                </Text>
              }
              value={contactType}
              setValue={(value: string) => setContactType(value)}
            />
          </VStack>
          <VStack w="100%" gap="1rem" alignItems="flex-start" mt="1rem">
            <VStack
              padding="1rem"
              border="1px solid var(--grey-300)"
              background="var(--grey-50)"
              borderRadius="0.5rem"
              alignItems="flex-start"
              w="100%"
            >
              <Text textStyle="h4" fontWeight="600">
                Assign Contacts to
              </Text>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  Sales Person
                </Text>
                <DebouncedAsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "Sales Person")
                  }
                  defaultOptions
                  onChange={(value: any) => setSalesPerson(value)}
                  placeholder="Type to search..."
                  value={salesPerson}
                />
              </Box>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  BDC Agent
                </Text>
                <DebouncedAsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "BDC Agent")
                  }
                  defaultOptions
                  onChange={(value: any) => setBDCAgent(value)}
                  placeholder="Type to search..."
                  value={bdcAgent}
                />
              </Box>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  Manager
                </Text>
                <DebouncedAsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "Manager/Admin")
                  }
                  defaultOptions
                  onChange={(value: any) => setManager(value)}
                  placeholder="Type to search..."
                  value={manager}
                />
              </Box>
            </VStack>
            <VStack
              padding="1rem"
              border="1px solid var(--grey-300)"
              background="var(--grey-50)"
              borderRadius="0.5rem"
              alignItems="flex-start"
              w="100%"
            >
              <Text textStyle="h4" fontWeight="600">
                Tags
              </Text>
              <Select
                label="Select tags that you want to assign to these contacts"
                onChange={(value: any) => setTags(value)}
                value={tags}
                placeholder="Select Tags"
                options={
                  data?.tags?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                  })) || []
                }
                w="100%"
                variant="default"
                isMulti={true}
              />
            </VStack>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 3,
      content: (
        <VStack w="100%" alignItems="center" gap="1rem" h="100%" mt="7rem">
          {isSuccess && (
            <>
              <Text textStyle="h3" fontWeight="600">
                Your job is in queue.
              </Text>
              <Text textStyle="h3" fontWeight="600">
                You'll be notified shortly!
              </Text>
              <VStack gap="0.5rem">
                <BigCheckIcon />
              </VStack>
            </>
          )}
          {!isSuccess && (
            <>
              <Text textStyle="h3" fontWeight="600">
                Error while Importing Contacts!
              </Text>
              <VStack gap="0.5rem">
                <CancelIcon
                  style={{
                    width: "8rem",
                    height: "8rem",
                    color: "var(--red-600)",
                  }}
                />
              </VStack>
            </>
          )}
        </VStack>
      ),
    },
  ];

  return (
    <Drawer isOpen={true} onClose={onClose} title="Import Wizard">
      <HStack w="100%" gap="0" padding="1.5rem 4rem">
        {importSteps.map((item) => (
          <HStack
            key={item.step}
            gap="0"
            flex={importSteps.length - 1 !== item.step ? "1" : "0"}
          >
            <Text
              borderRadius="50%"
              minW="2.375rem"
              h="2.375rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={currentStep >= item.step ? "white" : "var(--grey-500)"}
              textAlign="center"
              border="1px solid var(--grey-500)"
              background={
                currentStep >= item.step
                  ? "var(--secondary-600)"
                  : "transparent"
              }
            >
              {item.step + 1}
            </Text>
            {importSteps.length - 1 !== item.step && (
              <Box
                h="1px"
                w="100%"
                background={
                  currentStep > item.step
                    ? "var(--secondary-600)"
                    : "var(--grey-300)"
                }
              ></Box>
            )}
          </HStack>
        ))}
      </HStack>
      {importSteps[currentStep].content}
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        {currentStep > 0 && currentStep < importSteps.length - 1 && (
          <Button
            variant="outline"
            w="100%"
            size="xl"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            <ChevronLeftIcon />
            Back
          </Button>
        )}
        {currentStep < importSteps.length - 1 ? (
          <Button
            w="100%"
            size="xl"
            isDisabled={!nextEnabled}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            {currentStep < importSteps.length - 2 ? "Next" : "Done"}
            <ChevronRightIcon />
          </Button>
        ) : (
          <Button w="100%" size="xl" onClick={onClose}>
            Done
          </Button>
        )}
      </DrawerFooter>
      {(isFileDownloading ||
        isLoading ||
        mutationImportContacts?.isLoading) && <Spinner></Spinner>}
    </Drawer>
  );
}
