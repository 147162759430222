import { Box, Button, FormLabel, Radio, Text } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { leadDistributionOptions } from "./constants";
import { LeadCenterSettingsForm, loadLeadSource, loadUsers } from "./utils";
import DebouncedAsyncSelect from "../../common/DebouncedAsyncSelect";

type LeadDistributionProps = {
  control: Control<LeadCenterSettingsForm>;
  setValue: UseFormSetValue<LeadCenterSettingsForm>;
};

function LeadDistribution({ control, setValue }: LeadDistributionProps) {
  const { id } = useParams();

  const assign_specific_users = useWatch({
    control,
    name: "leads_distribution.assign_specific_user",
  });

  const checkLeadSourceExistence = (
    indexToExclude: number,
    valueToCheck: any
  ) => {
    // Combine select_lead_source arrays except the one at indexToExclude
    let combinedSelectLeadSource: any = [];

    assign_specific_users?.forEach((item, index) => {
      if (index !== indexToExclude) {
        combinedSelectLeadSource = combinedSelectLeadSource.concat(
          item.select_lead_source
        );
      }
    });

    // Check if the value exists in the combined list
    const exists = combinedSelectLeadSource.some(
      (lead: any) =>
        lead.label === valueToCheck.label && lead.value === valueToCheck.value
    );

    return !exists;
  };

  return (
    <Box
      padding="1rem"
      background="var(--grey-50)"
      borderRadius="0.5rem"
      border="1px solid var(--grey-300)"
      w="100%"
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Text textStyle="h4" fontWeight="500" mb="1rem">
          How Leads are distributed among the staff?
        </Text>
        <Button
          onClick={() =>
            setValue("leads_distribution", {
              option: "",
              assign_specific_user: [
                {
                  assign_leads: [],
                  select_lead_source: [],
                },
              ],
            })
          }
        >
          Clear
        </Button>
      </Box>
      {leadDistributionOptions.map((distributionOption) => {
        return (
          <Box mb="1.5rem" display="grid">
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Radio
                    key={distributionOption.value}
                    value={distributionOption.value}
                    isChecked={distributionOption.value === value}
                    onChange={() => {
                      setValue(
                        "leads_distribution.option",
                        distributionOption.value
                      );
                    }}
                  >
                    {distributionOption.label}
                  </Radio>
                );
              }}
              name="leads_distribution.option"
              control={control}
            />
          </Box>
        );
      })}

      <Box display={"flex"} justifyContent={"space-between"}>
        <Text textStyle="h4" fontWeight="500" mb="1rem">
          Lead Sources Exception
        </Text>
      </Box>

      {assign_specific_users?.map((_, index) => {
        return (
          <Box
            padding="1rem"
            background="white"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            w="100%"
            mt="1rem"
          >
            <Box mb="1rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  console.log("value", value);
                  return (
                    <Box mb="1rem">
                      <FormLabel
                        mb="0.31rem"
                        color="var(--grey-900)"
                        fontSize="0.875rem"
                        fontWeight="600"
                        lineHeight="1rem"
                      >
                        Select Lead Providers
                      </FormLabel>
                      <DebouncedAsyncSelect
                        loadOptions={(searchStr) =>
                          loadLeadSource(searchStr, id)
                        }
                        filterOption={(option: any) =>
                          checkLeadSourceExistence(index, option)
                        }
                        defaultOptions
                        value={value}
                        onChange={(value: any) => {
                          setValue(
                            `leads_distribution.assign_specific_user.${index}.select_lead_source`,
                            value
                          );
                        }}
                        isMulti={true}
                        placeholder="Select"
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            maxWidth: "100%",
                          }),
                        }}
                      />

                      {error?.message && (
                        <span style={{ color: "red" }}>{error?.message}</span>
                      )}
                    </Box>
                  );
                }}
                name={`leads_distribution.assign_specific_user.${index}.select_lead_source`}
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box mb="1rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Box mb="1rem">
                      <FormLabel
                        mb="0.31rem"
                        color="var(--grey-900)"
                        fontSize="0.875rem"
                        fontWeight="600"
                        lineHeight="1rem"
                      >
                        Assign User
                      </FormLabel>
                      <DebouncedAsyncSelect
                        loadOptions={(searchStr) => loadUsers(searchStr, id,'Sales Person')}
                        defaultOptions
                        value={value}
                        onChange={(value: any) => {
                          setValue(
                            `leads_distribution.assign_specific_user.${index}.assign_leads`,
                            value
                          );
                        }}
                        isMulti={true}
                        placeholder="Select"
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            maxWidth: "100%",
                          }),
                        }}
                      />

                      {error?.message && (
                        <span style={{ color: "red" }}>{error?.message}</span>
                      )}
                    </Box>
                  );
                }}
                name={`leads_distribution.assign_specific_user.${index}.assign_leads`}
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>

            <Box
              display="flex"
              alignItems="flex-end"
              gap="5px"
              justifyContent="flex-end"
            >
              {index === assign_specific_users?.length - 1 &&
                assign_specific_users?.length < 8 && (
                  <Button
                    onClick={() =>
                      setValue("leads_distribution.assign_specific_user", [
                        ...(assign_specific_users || []),
                        {
                          assign_leads: [],
                          select_lead_source: [],
                        },
                      ])
                    }
                  >
                    Add
                  </Button>
                )}

              {index !== 0 && (
                <Button
                  background="var(--red-400)"
                  onClick={() =>
                    setValue(
                      "leads_distribution.assign_specific_user",
                      assign_specific_users?.filter(
                        (_, filterIndex) => filterIndex !== index
                      )
                    )
                  }
                >
                  Remove
                </Button>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default LeadDistribution;
