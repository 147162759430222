import { HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { fetchLeads } from "../../../../../utils/api/contact-lead";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import InfiniteScrollComponent from "../../../../common/InfiniteScrollComponent";
import Loader from "../../../../common/Spinner";
import NoItem from "../History/NoItem";
import LeadDetailPage from "./LeadDetailPage";
import SingleLeadCard from "./SingleLeadCard";

const Leads = ({ clickedRow }: any) => {
  const [page, setPage] = useState(1);
  const dId = useUserInfo("dealership")?.id;
  const [count, setCount] = useState(0);
  const [leads, setLeads] = useState<any[]>([]);

  const [clickedRecord, setClickedRecord] = useState();

  const { isFetching } = useQuery({
    queryKey: ["fetchLeads", page, clickedRow],
    queryFn: () => fetchLeads(dId, clickedRow, { page, per_page: 10 }),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setLeads((prev) => [...prev, ...data?.data?.leads]);
      setCount(data?.data?.leads_count || 0);
    },
  });

  useEffect(() => {
    setPage(1);
    setLeads([]);
  }, [clickedRow]);

  if (clickedRecord) {
    return (
      <LeadDetailPage
        clickedRecord={clickedRecord}
        setClickedRecord={setClickedRecord}
        id={clickedRow}
      ></LeadDetailPage>
    );
  }

  return (
    <>
      <VStack
        h="100%"
        background="var(--grey-50)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-end"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Leads
            </Text>
            <Text textStyle="h4" fontWeight="500" color="var(--grey-500)">
              ({count})
            </Text>
          </HStack>
        </HStack>
        {leads.length > 0 ? (
          <InfiniteScrollComponent
            data={leads}
            fetchMore={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={leads.length < count}
            renderItem={(item) => (
              <SingleLeadCard
                item={item}
                setClickedRecord={setClickedRecord}
                details={item}
              />
            )}
            scrollableTarget="scrollableDivLeads"
          />
        ) : (
          <NoItem />
        )}
      </VStack>
      {isFetching && <Loader />}
    </>
  );
};

export default Leads;
