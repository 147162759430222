import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import Car from "../../../assets/images/car.svg";
import { Spinner } from "../../../components/common";
import { EmptyIcon } from "../../../components/icons/svg";
import { getSeparatorInUrlString } from "../../../utils";
import { fetchGarage } from "../../../utils/api/guest-mode";

const MyGarage = () => {
  const { id, dId } = useParams();
  const [page, setPage] = useState(1);
  const [list, setList] = useState<any>([]);
  const [count, setCount] = useState(0);

  const { data, isFetching } = useQuery({
    queryKey: ["fetchGarage", { page, per_page: 18, contact_uuid: id }, dId],
    queryFn: fetchGarage,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess(data) {
      setCount(data?.data?.vehicles_count);
      setList([...list, ...data?.data?.vehicles]);
    },
  });

  const getImage = (urlString: string) => {
    if (!urlString) {
      return Car;
    }
    const separator = getSeparatorInUrlString(urlString);
    return separator ? urlString.split(separator)?.[0] : urlString;
  };

  return (
    <VStack
      h="100%"
      //   background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="center"
      margin="0 auto"
      w="100%"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Vehicles
            </Text>
            <Text textStyle="h4" fontWeight="500" color="var(--grey-500)">
              ({count})
            </Text>
          </HStack>
        </HStack>
        <VStack gap="1rem" alignItems="flex-start" w="100%" mt="1rem">
          {list ? (
            list?.length > 0 && (
              <div
                id="scrollableDivMyGarage"
                style={{
                  height: "calc(115vh - 415px)",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <InfiniteScroll
                  dataLength={list?.length}
                  next={() => {
                    setPage((prev) => prev + 1);
                  }}
                  hasMore={list?.length < count}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDivMyGarage"
                >
                  <HStack w="100%" gap="1rem" flexWrap="wrap">
                    {list?.map((item: any) => (
                      <HStack
                        key={item.id}
                        w={{ md: "calc(33% - 0.5rem)", base: "100%" }}
                        padding={{ md: "1rem", base: "0.5rem" }}
                        borderRadius="0.5rem"
                        border="1px solid var(--grey-300)"
                        alignItems={{ md: "flex-start", base: "center" }}
                        gap={{ md: "3rem", base: "0.5rem" }}
                        position="relative"
                      >
                        <VStack
                          gap="0.5rem"
                          // mb={{ md: "1rem", base: "0rem" }}
                          // w={"100%"}
                        >
                          <Image
                            src={getImage(
                              item?.inventory?.uploaded_images?.trim() ||
                                item?.inventory?.photo_url_list?.trim() ||
                                ""
                            )}
                            width={{ md: 200, base: "100%" }}
                            height={150}
                            objectFit="cover"
                            alt="car Image"
                            style={{
                              borderRadius: "0.5rem",
                            }}
                          />
                        </VStack>
                        <VStack
                          alignItems="flex-start"
                          gap="0.5rem"
                          // w="65%"
                          // w="100%"
                        >
                          <VStack
                            alignItems="flex-start"
                            w="100%"
                            gap="0.25rem"
                            pb="0.5rem"
                            //   borderBottom="1px solid var(--grey-300)"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <HStack paddingRight="1rem">
                                {item?.preferred && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    background="var(--flame-100)"
                                    color="var(--flame-600)"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Preferred
                                  </Text>
                                )}
                                {item?.inventory?.status === "available" && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    color="#12c607"
                                    background="#d8f3d8"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Available
                                  </Text>
                                )}
                                {item?.inventory?.status === "unavailable" && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    background="var(--flame-100)"
                                    color="var(--flame-600)"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Not Available
                                  </Text>
                                )}
                              </HStack>
                            </Box>

                            {!item?.inventory && (
                              <Text
                                textStyle={{ md: "h4", base: "bodySmall" }}
                                fontWeight="500"
                              >
                                {item.year} {item.make} - {item.model}
                              </Text>
                            )}
                            {item?.inventory && (
                              <Text textStyle="h4" fontWeight="500">
                                {item.inventory.year} {item.inventory.make} -{" "}
                                {item.inventory.model}
                              </Text>
                            )}
                            <HStack
                              alignItems="flex-end"
                              justifyContent="space-between"
                              w="100%"
                            >
                              <VStack alignItems="flex-start" gap="0">
                                <Text
                                  textStyle="captionSmall"
                                  fontWeight="500"
                                  color="var(--grey-500)"
                                >
                                  Exterior Color:{" "}
                                  {item?.inventory
                                    ? item?.inventory?.color
                                    : item.exterior_color}
                                </Text>
                                <Text
                                  textStyle="captionSmall"
                                  fontWeight="500"
                                  color="var(--grey-500)"
                                >
                                  Interior Color:{" "}
                                  {item?.inventory
                                    ? item?.inventory?.interior_color
                                    : item.interior_color}
                                </Text>
                                <Text
                                  textStyle="captionSmall"
                                  fontWeight="500"
                                  color="var(--grey-500)"
                                >
                                  Mileage:{" "}
                                  {item?.inventory
                                    ? item?.inventory?.odometer
                                    : item.mileage}
                                </Text>
                                {item?.inventory && (
                                  <>
                                    <Text
                                      textStyle="captionSmall"
                                      fontWeight="500"
                                      color="var(--grey-500)"
                                    >
                                      VIN: {item?.inventory?.vin}
                                    </Text>
                                    <Text
                                      textStyle="captionSmall"
                                      fontWeight="500"
                                      color="var(--grey-500)"
                                    >
                                      Stock #: {item?.inventory?.stock_number}
                                    </Text>
                                  </>
                                )}
                              </VStack>
                            </HStack>
                          </VStack>
                        </VStack>
                      </HStack>
                    ))}
                  </HStack>
                </InfiniteScroll>
              </div>
            )
          ) : (
            <VStack
              w="100%"
              justifyContent="center"
              alignItems="center"
              h="100%"
            >
              <EmptyIcon />
              <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
                No Vehicle
              </Text>
            </VStack>
          )}
        </VStack>
      </VStack>
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default MyGarage;
