import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import AsyncSelect from "react-select/async";
import { loadLeadSourceApi } from "../../../../../utils/api/lead.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { EmptyIcon } from "../../../../icons/svg";
import Loader from "../../../../common/Spinner";
import DebouncedAsyncSelect from "../../../../common/DebouncedAsyncSelect";

// Data interfaces
interface DataPoint {
  period?: string;
  message_count?: number;
  avgResponseTime?: number;
  engagementCount?: number;
  hour?: string;
  messagesSent?: number;
  repliesReceived?: number;
  lead_source?: string; // New field for lead source
}

// Messages Sent per Lead Source Component
interface MessagesChartProps {
  data: DataPoint[];
  setSelectedLeadSource: (value: any) => void;
  selectedLeadSource: any;
  isLoading: boolean;
}

const chartContainerStyle: React.CSSProperties = {
  padding: "20px",
  margin: "20px 0",
  border: "1px solid #ddd",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  backgroundColor: "#fff",
  width: "100%",
};

const MessagesChart: React.FC<MessagesChartProps> = ({
  data,
  selectedLeadSource,
  setSelectedLeadSource,
  isLoading,
}) => {
  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>({});
  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);

  const user = useUserInfo("user");

  useEffect(() => {
    const seriesData = prepareChartData(data, "message_count");

    setChartOptions({
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: seriesData.categories,
      },
      title: {
        text: "Messages Sent per Lead Source",
      },
      yaxis: {
        title: {
          text: "Number of Messages",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    });

    setChartSeries([
      {
        name: "Messages",
        data: seriesData.values,
      },
    ]);
  }, [data]);

  const prepareChartData = (data: DataPoint[], key: keyof DataPoint) => {
    const categories: string[] = [];
    const values: number[] = [];
    data?.forEach((item) => {
      if (item.lead_source) {
        categories.push(item.lead_source);
        if (item[key] !== undefined) {
          values.push(item[key] as number);
        }
      }
    });
    return { categories, values };
  };
  const formatSelectedValueLabel = (value: any) => {
    const maxLength = 7;
    const truncatedLabel =
      value.label.length > maxLength
        ? value.label.substring(0, maxLength) + "..."
        : value.label;
    return truncatedLabel;
  };

  const customSingleValue = ({ data }: any) => (
    <div>{formatSelectedValueLabel(data)}</div>
  );

  const customMultiValueLabel = ({ data }: any) => (
    <div>{formatSelectedValueLabel(data)}</div>
  );
  const loadLeadSources = async (inputValue: string) => {
    try {
      const response = await loadLeadSourceApi({
        searchValue: inputValue,
        dealership_id: user?.dealership?.id,
      });

      const options = response?.lead_sources?.map((leadSource: any) => ({
        value: leadSource.id.toString(),
        label: leadSource.name,
      }));

      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };

  return (
    <div style={chartContainerStyle}>
      {isLoading && <Loader />}
      <HStack
        gap="1rem"
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Text textStyle="h6" fontWeight="700">See how Sarah AI Worked With Specific Lead Source</Text>
        <VStack minHeight="38px">
          <DebouncedAsyncSelect
            loadOptions={loadLeadSources}
            defaultOptions
            value={selectedLeadSource}
            onChange={(value: any) => {
              setSelectedLeadSource(value);
            }}
            placeholder="Select Lead Source"
            styles={{
              control: (provided: any) => ({
                ...provided,
                width: "400px",
              }),
            }}
            components={{
              SingleValue: customSingleValue,
              MultiValueLabel: customMultiValueLabel,
            }}
            isMulti
          />
        </VStack>
      </HStack>
      {selectedLeadSource?.length ? (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
        />
      ) : (
        <>
          <VStack
            w="100%"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <EmptyIcon />
            <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
              Please select a lead source.
            </Text>
          </VStack>
        </>
      )}
    </div>
  );
};

export default MessagesChart;
